import { usePositionTokenURI } from './usePositionTokenURI'

export function useGetCustomeImage(item: any) {
  if (!item) {
    return []
  }
  let array = []
  const result1 = usePositionTokenURI(item[0]?.tokenId)
  if (result1) {
    array.push(result1)
  }
  const result2 = usePositionTokenURI(item[1]?.tokenId)
  if (result2) {
    array.push(result2)
  }
  const result3 = usePositionTokenURI(item[2]?.tokenId)
  if (result3) {
    array.push(result3)
  }
  const result4 = usePositionTokenURI(item[3]?.tokenId)
  if (result4) {
    array.push(result4)
  }
  const result5 = usePositionTokenURI(item[4]?.tokenId)
  if (result5) {
    array.push(result5)
  }
  const result6 = usePositionTokenURI(item[5]?.tokenId)
  if (result6) {
    array.push(result6)
  }
  const result7 = usePositionTokenURI(item[6]?.tokenId)
  if (result7) {
    array.push(result7)
  }
  const result8 = usePositionTokenURI(item[7]?.tokenId)
  if (result8) {
    array.push(result8)
  }
  const result9 = usePositionTokenURI(item[8]?.tokenId)
  if (result9) {
    array.push(result9)
  }
  const result10 = usePositionTokenURI(item[9]?.tokenId)
  if (result10) {
    array.push(result10)
  }
  const result11 = usePositionTokenURI(item[10]?.tokenId)
  if (result11) {
    array.push(result11)
  }
  const result12 = usePositionTokenURI(item[11]?.tokenId)
  if (result12) {
    array.push(result12)
  }
  const result13 = usePositionTokenURI(item[12]?.tokenId)
  if (result13) {
    array.push(result13)
  }
  const result14 = usePositionTokenURI(item[13]?.tokenId)
  if (result14) {
    array.push(result14)
  }
  const result15 = usePositionTokenURI(item[14]?.tokenId)
  if (result15) {
    array.push(result15)
  }
  const result16 = usePositionTokenURI(item[15]?.tokenId)
  if (result16) {
    array.push(result16)
  }
  const result17 = usePositionTokenURI(item[16]?.tokenId)
  if (result17) {
    array.push(result17)
  }
  const result18 = usePositionTokenURI(item[17]?.tokenId)
  if (result18) {
    array.push(result18)
  }
  const result19 = usePositionTokenURI(item[18]?.tokenId)
  if (result19) {
    array.push(result19)
  }
  const result20 = usePositionTokenURI(item[19]?.tokenId)
  if (result20) {
    array.push(result20)
  }

  return array
}
