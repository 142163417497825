import Modal from '../Modal'
import { Trans } from '@lingui/macro'
import { Text } from 'rebass'
import './modal.css'
import styled, { useTheme } from 'styled-components/macro'
import { CloseIcon, CustomLightSpinner } from '../../theme'
import { AutoColumn, ColumnCenter } from '../Column'
import { ButtonLight, ButtonPrimary, NotchedButtonFillPrimary, NotchedButtonFill } from '../Button'
import { CheckCircle, AlertCircle, AlertTriangle, ChevronsRight } from 'react-feather'
import AnimatedConfirmation from '../TransactionConfirmationModal/AnimatedConfirmation'
import { usePositionTokenURI } from '../../hooks/usePositionTokenURI'
import { useGetCustomeImage } from '../../hooks/useGetCustomeImage'
import { ethers } from 'ethers'
import React, { useContext, useEffect, useRef, useState } from 'react'
import CheckButtonComponent from 'components/Button/CheckButton'
import { Contract } from '@ethersproject/contracts'
import { useWeb3React } from '@web3-react/core'
import { useTokenContract, useContract, useCustomeContract } from 'hooks/useContract'
import v2Staker_abi from '../../abis/alpha/V2Staker.json'
import v2PoolFactory_abi from '../../abis/alpha/V2PoolFactory.json'
import ERC_20 from '../../abis/erc20.json'
import emptyImage from '../../assets/images/alpha/message-circle-question.svg'
import QuestionHelper from 'components/QuestionHelper/index'

// image
const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'

import { SupportedChainId } from '../../constants/chains'
import Badge, { BadgeVariant } from '../Badge'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
interface DepositModalInterface {
  isOpen: boolean
  onDismiss: () => void
  getUserStakeInfo: () => void
  trigger: boolean
  tokenInfo: any
  poolInfo: any
  userStakeTokenBalance: any
}
type userInfo = {
  amount: string
  depositId: string
  lockUntil: string
  owner: string
  virtualAmount: string
  increateTimeAndAmountAllow: boolean
}

const Wrapper = styled.div`
  background-color: #1c1b20;
  outline: none;
  width: 100%;
  padding: 10px 20px;
  height: fit-content;
  margin-bottom: 20px;
`
const WrapperTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: #efefe4;
  }
`

const BadgeText = styled.div`
  font-weight: 500;
  font-size: 12px;
`

export default function DepositModal({
  isOpen,
  onDismiss,
  tokenInfo,
  poolInfo,
  getUserStakeInfo,
  userStakeTokenBalance,
  trigger,
}: DepositModalInterface) {
  const [ethPrice, setEthPrice] = useState(3000)
  const { account, chainId } = useWeb3React()
  const [customeChainId, setCustomeChainId] = useState(SupportedChainId.BLAST)
  useEffect(() => {
    if (chainId) {
      setCustomeChainId(chainId)
    } else {
      setCustomeChainId(SupportedChainId.BLAST)
    }
  }, [account, chainId])
  // contract
  const STAKING_ADDRESS = poolInfo?._poolAddress
  const STAKE_TOKEN_ADDRESS = poolInfo?._stakedToken

  const v2staker = useCustomeContract(STAKING_ADDRESS, v2Staker_abi, false)
  const v2stakerWithSign = useCustomeContract(STAKING_ADDRESS, v2Staker_abi, true)
  const tokenStakeContract = useCustomeContract(STAKE_TOKEN_ADDRESS, ERC_20, true)
  // selected tab
  const [selectedTab, setSelectedTab] = useState(1)
  // state
  const [loadingApproveContract, setLoadingApproveContract] = useState(false)
  const [isApproveContract, setIsApproveContract] = useState(false)
  const [loadingDeposit, setLoadingDeposit] = useState(false)
  const [loadingUserDeposit, setLoadingUserDeposit] = useState(false)
  const [inputAmount, setInputAmount] = useState<string>()
  const [increaseInputAmount, setIncreaseInputAmount] = useState<string>()
  const [errorMessInput, setErrorMessInput] = useState<string>(' ')
  const [errorMessInputIncreaseAmount, setErrorMessInputIncreaseAmount] = useState<string>(' ')
  const [inputlockedTime, setInputLockedTime] = useState(poolInfo._minLockTime)
  const [inCreaseInputlockedTime, setInCreaseInputlockedTime] = useState(poolInfo._minLockTime)
  const [userDepositInfo, setUserDepositInfo] = useState<userInfo[] | undefined>(undefined)
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [checkedIdsIncreaseTime, setCheckedIdsIncreaseTime] = useState<string[]>([])
  const [loadingIncreaseAmount, setLoadingIncreaseAmount] = useState(false)
  const [loadingIncreaseTime, setLoadingIncreaseTime] = useState(false)

  // function
  const ApproveStakeContract = async () => {
    setLoadingApproveContract(true)
    try {
      const setApproveContract = await tokenStakeContract?.approve(STAKING_ADDRESS, ethers.constants.MaxUint256)
      await setApproveContract.wait()

      setLoadingApproveContract(false)
      return true
    } catch (error) {
      console.log(error)
      setLoadingApproveContract(false)
      return false
    }
  }
  const handleCheckApproveStakeContract = async () => {
    try {
      const allowance = await tokenStakeContract?.allowance(account, STAKING_ADDRESS)
      const formmatedAllowance = ethers.utils.formatUnits(allowance, 18)

      if (Number(allowance) > 0) {
        setIsApproveContract(true)
      } else {
        setIsApproveContract(false)
      }
    } catch (error) {
      console.log('error', error)
    }

  }
  const handleApproveStakeContract = async () => {
    await ApproveStakeContract()
    handleCheckApproveStakeContract()
  }
  const maxToken = userStakeTokenBalance ? Number(userStakeTokenBalance).toFixed(4).toString() : '0'
  useEffect(() => {
    if (account !== null && account !== undefined) {
      handleCheckApproveStakeContract()
    }
  }, [account, chainId])

  // deposit function
  const deposit = async () => {
    setLoadingDeposit(true)
    let lockTimeManual = inputlockedTime.toString()
    const gasLimit = 500000
    const amount = inputAmount ? ethers.utils.parseUnits(inputAmount, 18) : '0'
    try {
      const tx = await v2stakerWithSign?.createDeposit(amount, lockTimeManual, { gasLimit })
      await tx.wait()
      if (tx) {
        console.log('tx', tx)
        setLoadingDeposit(false)
        getUserStakeInfo()
        getUserDeposits()
      }

      setLoadingDeposit(false)
    } catch (error) {
      console.log(error)
      setLoadingDeposit(false)
    }
  }
  const handleDeposit = () => {
    const validate = validateInput(inputAmount, maxToken)
    if (validate !== 'valid') {
      setErrorMessInput('Invalid input.')
      return
    }
    setErrorMessInput(' ')
    deposit()
  }
  // increase function
  const increaseAmount = async () => {
    setLoadingIncreaseAmount(true)
    const amount = increaseInputAmount ? ethers.utils.parseUnits(increaseInputAmount, 18) : '0'
    const depositId = checkedIds[0]?.toString()
    try {
      const gasLimit = 210000
      const tx = await v2stakerWithSign?.addToDeposit(amount, depositId, { gasLimit })
      await tx.wait()
      if (tx) {
        console.log('tx', tx)
        setLoadingIncreaseAmount(false)
        getUserStakeInfo()
        getUserDeposits()
      }

      setLoadingIncreaseAmount(false)
    } catch (error) {
      console.log(error)
      setLoadingIncreaseAmount(false)
    }
  }
  const handleIncreaseAmount = () => {
    const validate = validateInput(increaseInputAmount, maxToken)
    if (validate !== 'valid') {
      setErrorMessInputIncreaseAmount('Invalid input.')
      return
    }
    setErrorMessInputIncreaseAmount(' ')
    increaseAmount()
  }
  // increase time
  const handleIncreaseTime = async () => {
    setLoadingIncreaseTime(true)
    const _extendDuration = Number(inCreaseInputlockedTime)
    const _depositId = checkedIdsIncreaseTime[0]?.toString()
    try {
      const gasLimit = 210000
      const tx = await v2stakerWithSign?.extendDeposit(_depositId, _extendDuration, { gasLimit })
      await tx.wait()
      if (tx) {
        console.log('tx', tx)
        setLoadingIncreaseTime(false)
        getUserStakeInfo()
        getUserDeposits()
      }

      setLoadingIncreaseTime(false)
    } catch (error) {
      console.log(error)
      setLoadingIncreaseTime(false)
    }
  }
  // fnc
  const validateInput = (value: any, maxToken: any) => {
    const num = parseFloat(value)
    if (isNaN(num) || num <= 0) {
      return 'error'
    }
    if (num > parseFloat(maxToken)) {
      return 'error'
    }
    return 'valid'
  }

  function addSecondsToCurrentDate(seconds: string) {
    const currentDate = new Date()
    currentDate.setSeconds(currentDate.getSeconds() + Number(seconds))
    const formattedDate = currentDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hourCycle: 'h23',
    })
    return formattedDate
  }
  function convertUnixTimestampToDate(unixTimestamp: any) {
    const timestampInMilliseconds = unixTimestamp * 1000
    const date = new Date(timestampInMilliseconds)
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hourCycle: 'h23',
    })

    return formattedDate
  }

  // get getUserDeposits function
  const getUserDeposits = async () => {
    setLoadingUserDeposit(true)
    try {
      const infor = await v2stakerWithSign?.getUserDeposits(account)
      let userPosition = []
      const currentDateTimestamp = Math.round(new Date().getTime() / 1000)

      for (let i = 0; i < infor?.length; i++) {
        let item = infor[i]
        const increateTimeAndAmountAllow =
          Number(item?.lockUntil.toString()) < Number(currentDateTimestamp) + Number(poolInfo?._minLockTime)
            ? true
            : false

        let Array = {
          amount: ethers.utils.formatUnits(item?.amount, 18),
          depositId: item?.depositId.toString(),
          lockUntil: item?.lockUntil.toString(),
          owner: item?.owner.toString(),
          virtualAmount: ethers.utils.formatUnits(item?.virtualAmount, 18),
          increateTimeAndAmountAllow: increateTimeAndAmountAllow,
          boosting: Number(
            (Number(ethers.utils.formatUnits(item?.virtualAmount, 18)) /
              Number(ethers.utils.formatUnits(item?.amount, 18))) *
            100
          ).toFixed(2),
        }
        userPosition.push(Array)
      }
      setUserDepositInfo(userPosition)
      setLoadingUserDeposit(false)
    } catch (error) {
      console.log(error)
      setLoadingUserDeposit(false)
    }
  }
  const handleCheckChange = (formattedTokenId: string, isChecked: boolean) => {
    if (isChecked) {
      setCheckedIds([formattedTokenId])
    } else {
      setCheckedIds([])
    }
  }
  const handleCheckChangeIncreaseTime = (formattedTokenId: string, isChecked: boolean) => {
    if (isChecked) {
      setCheckedIdsIncreaseTime([formattedTokenId])
    } else {
      setCheckedIdsIncreaseTime([])
    }
  }
  let disableConfirmButton = checkedIds.length > 0 && increaseInputAmount ? false : true
  let disableConfirmButtonIncreaseTime = checkedIdsIncreaseTime.length > 0 ? false : true

  useEffect(() => {
    if (account !== null && account !== undefined) {
      getUserDeposits()
      getUserStakeInfo()
    }
  }, [account, chainId])
  useEffect(() => {
    if (trigger) {
      getUserStakeInfo()
      getUserDeposits()
    }
  }, [account, chainId, trigger])
  console.log('userDepositInfo', userDepositInfo)

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <WrapperTop>
          <p>
            Deposit {tokenInfo[poolInfo.poolId]?.currencyA?.symbol}/{tokenInfo[poolInfo.poolId]?.currencyB?.symbol} to earn xMONO
          </p>
          <CloseIcon onClick={onDismiss} />
        </WrapperTop>
        <div className="event-bot-switch-left">
          <p className={` ${selectedTab === 1 ? 'event-tab-selected' : ''}`} onClick={() => setSelectedTab(1)}>
            Stake
          </p>
          <p className={` ${selectedTab === 2 ? 'event-tab-selected' : ''}`} onClick={() => setSelectedTab(2)}>
            Increase Amount
          </p>

          {
            poolInfo._minLockTime > 1 &&
            <p className={` ${selectedTab === 3 ? 'event-tab-selected' : ''}`} onClick={() => setSelectedTab(3)}>
              Increase Time
            </p>
          }


        </div>
        {selectedTab === 1 && (
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <div className="deposit-modal-div">
                <p className="deposit-modal-div-balance">
                  Balance: <span style={{}}>{maxToken}</span> LP
                </p>
                <div className="deposit-modal-input">
                  <input
                    type="number"
                    value={inputAmount}
                    onChange={(e) => setInputAmount(e.target.value)}
                    placeholder="0.0"
                  ></input>
                  <div className="deposit-modal-maxbutton">
                    <button
                      onClick={() => {
                        setInputAmount(maxToken)
                      }}
                    >
                      MAX
                    </button>
                  </div>
                </div>
                <div className="error-message-container">
                  <span>{errorMessInput}</span>
                </div>
              </div>
              {
                poolInfo._minLockTime > 1 && <>
                  <div className="modal-deposit-selected-time-container" >
                    <div className="modal-deposit-selected-time-title">
                      <p style={{ color: 'rgba(239, 239, 228, 0.5)' }}>
                        Lock until:{' '}
                        <span style={{ color: '#efefe4' }}>
                          {/* {addSecondsToCurrentDate(inputlockedTime).toLocaleDateString()} */}
                          {Number(poolInfo._minLockTime) < 1 ? '-' : addSecondsToCurrentDate(inputlockedTime)}{' '}
                        </span>
                      </p>
                    </div>

                    <div className="modal-deposit-selected-time">
                      <NotchedButtonFill
                        onClick={() => setInputLockedTime('604800')}
                        style={{ margin: '4px 0 0 0' }}
                        padding={'10px 5px'}
                        height={'32px'}
                        disabled={Number(poolInfo._minLockTime) < 1}
                      >
                        <Text fontWeight={500} fontSize={18}>
                          <Trans>7 days</Trans>
                        </Text>
                      </NotchedButtonFill>
                      <NotchedButtonFill
                        onClick={() => setInputLockedTime('1209600')}
                        style={{ margin: '4px 0 0 0' }}
                        padding={'10px 5px'}
                        height={'32px'}
                        disabled={Number(poolInfo._minLockTime) < 1}
                      >
                        <Text fontWeight={500} fontSize={18}>
                          <Trans>14 days</Trans>
                        </Text>
                      </NotchedButtonFill>
                    </div>
                    <div className="modal-deposit-selected-time">
                      <NotchedButtonFill
                        onClick={() => setInputLockedTime('2592000')}
                        style={{ margin: '4px 0 0 0' }}
                        padding={'10px 5px'}
                        height={'32px'}
                        disabled={Number(poolInfo._minLockTime) < 1}
                      >
                        <Text fontWeight={500} fontSize={18}>
                          <Trans>1 month</Trans>
                        </Text>
                      </NotchedButtonFill>
                      <NotchedButtonFill
                        onClick={() => setInputLockedTime('7776000')}
                        style={{ margin: '4px 0 0 0' }}
                        padding={'10px 5px'}
                        height={'32px'}
                        disabled={Number(poolInfo._minLockTime) < 1}
                      >
                        <Text fontWeight={500} fontSize={18}>
                          <Trans>3 months</Trans>
                        </Text>
                      </NotchedButtonFill>
                    </div>
                  </div>
                </>
              }
            </div>
            {isApproveContract ? (
              <NotchedButtonFill onClick={handleDeposit} style={{ margin: '4px 0 0 0' }}>
                <Text fontWeight={500} fontSize={20}>
                  <Trans>{loadingDeposit ? 'Depositing...' : 'Deposit'}</Trans>
                </Text>
              </NotchedButtonFill>
            ) : (
              <NotchedButtonFill onClick={handleApproveStakeContract} style={{ margin: '4px 0 0 0' }}>
                <Text fontWeight={500} fontSize={20}>
                  <Trans>{loadingApproveContract ? 'Approving...' : 'Approve'}</Trans>
                </Text>
              </NotchedButtonFill>
            )}
          </div>
        )}
        {selectedTab == 2 && (
          <div>
            <div>
              <p>Select your stake positions</p>
              <div className="stake-position-container">
                {userDepositInfo && userDepositInfo?.length > 0 ? (
                  userDepositInfo?.map((i: any, index: any) => (
                    <div className="deposit-card-containers" key={index}>
                      <div className="deposit-card-container-title">
                        <div>
                          <p
                            className="deposit-card-container-title-p"
                            style={{ color: 'rgba(239, 239, 228, 0.5)', fontSize: '12px', minHeight: '18px' }}
                          >
                            Staked from{' '}
                            <span className="deposit-card-container-title-span" style={{ color: '#efefe4' }}>
                              {Number(i?.amount).toFixed(2)}
                            </span>{' '}
                            {checkedIds.includes(i.depositId) && (
                              <>
                                <ChevronsRight color="#62E6D4" width={'18px'} />{' '}
                                <span className="deposit-card-container-title-span">
                                  {Number(
                                    Number(i?.amount) + Number(increaseInputAmount ? increaseInputAmount : 0)
                                  ).toFixed(2)}
                                </span>
                              </>
                            )}
                          </p>
                          <p
                            style={{
                              color: 'rgba(239, 239, 228, 0.5)',
                              fontSize: '12px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                            }}
                          >
                            Boosting Multiplier:{' '}
                            <span style={{ color: '#efefe4', fontSize: '12px' }}>{i?.boosting}% </span>
                            <QuestionHelper
                              size={12}
                              text={
                                <Trans>
                                  Increase your boosting multiplier <br /> by either increasing the stake amount <br />{' '}
                                  or extending the lock time.
                                </Trans>
                              }
                            />
                          </p>
                          {/* <p style={{ color: 'rgba(239, 239, 228, 0.5)', fontSize: '12px' }}>
                            Estimate Reward:{' '}
                            <span style={{ color: '#efefe4', fontSize: '12px' }}>
                              {Number(i?.virtualAmount).toFixed(2)}{' '}
                            </span>
                          </p> */}
                          {poolInfo && Number(poolInfo._minLockTime) < 1 ? (
                            <></>
                          ) : (
                            <p style={{ color: 'rgba(239, 239, 228, 0.5)', fontSize: '12px' }}>
                              Lock until:{' '}
                              <span style={{ color: '#efefe4', fontSize: '12px' }}>
                                {convertUnixTimestampToDate(i?.lockUntil)}{' '}
                              </span>
                            </p>
                          )}
                        </div>

                        <CheckButtonComponent
                          onCheckChange={(isChecked) => handleCheckChange(i.depositId, isChecked)}
                          isChecked={checkedIds.includes(i.depositId)}
                          formattedTokenId={i?.depositId}
                          disabled={i?.increateTimeAndAmountAllow}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="deposit-card-container-img">
                    <img src={emptyImage} alt="img" />
                    <p>You don&apos;t have any compatible position</p>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <div className="deposit-modal-div">
                <p className="deposit-modal-div-balance">
                  Balance: <span style={{ fontSize: '14px' }}>{maxToken}</span> LP
                </p>
                <div className="deposit-modal-input">
                  <input
                    type="number"
                    value={increaseInputAmount}
                    onChange={(e) => setIncreaseInputAmount(e.target.value)}
                    placeholder="0.0"
                  ></input>
                  <div className="deposit-modal-maxbutton">
                    <button
                      onClick={() => {
                        setIncreaseInputAmount(maxToken)
                      }}
                    >
                      MAX
                    </button>
                  </div>
                </div>
                <div className="error-message-container">
                  <span>{errorMessInputIncreaseAmount}</span>
                </div>
              </div>
              <NotchedButtonFill
                onClick={handleIncreaseAmount}
                style={{ margin: '4px 0 0 0' }}
                disabled={disableConfirmButton}
              >
                <Text fontWeight={500} fontSize={20}>
                  <Trans>{loadingIncreaseAmount ? 'Loading...' : 'Confirm'}</Trans>
                </Text>
              </NotchedButtonFill>
            </div>
          </div>
        )}
        {selectedTab == 3 && (
          <div>
            <div>
              <p>Select your stake positions</p>
              <div className="stake-position-container">
                {userDepositInfo && userDepositInfo?.length > 0 ? (
                  userDepositInfo?.map((i: any, index: any) => (
                    <div className="deposit-card-containers" key={index}>
                      <div className="deposit-card-container-title">
                        <div>
                          <p
                            className="deposit-card-container-title-p"
                            style={{ color: 'rgba(239, 239, 228, 0.5)', fontSize: '12px', minHeight: '18px' }}
                          >
                            Staked:{' '}
                            <span className="deposit-card-container-title-span" style={{ color: '#efefe4' }}>
                              {Number(i?.amount).toFixed(2)}
                            </span>{' '}
                          </p>
                          <p
                            style={{
                              color: 'rgba(239, 239, 228, 0.5)',
                              fontSize: '12px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                            }}
                          >
                            Boosting Multiplier:{' '}
                            <span style={{ color: '#efefe4', fontSize: '12px' }}>{i?.boosting}% </span>
                            <QuestionHelper
                              size={12}
                              text={
                                <Trans>
                                  Increase your boosting multiplier <br /> by either increasing the stake amount <br />{' '}
                                  or extending the lock time.
                                </Trans>
                              }
                            />
                          </p>
                          <p
                            className="deposit-card-container-title-p"
                            style={{ color: 'rgba(239, 239, 228, 0.5)', fontSize: '12px', minHeight: '22px' }}
                          >
                            Lock until:{' '}
                            <span style={{ color: '#efefe4', fontSize: '12px' }}>
                              {convertUnixTimestampToDate(i?.lockUntil)}{' '}
                            </span>
                            {checkedIdsIncreaseTime.includes(i.depositId) && (
                              <>
                                <ChevronsRight color="#62E6D4" width={'18px'} />{' '}
                                <span style={{ color: '#efefe4', fontSize: '12px' }}>
                                  {convertUnixTimestampToDate(Number(i?.lockUntil) + Number(inCreaseInputlockedTime))}{' '}
                                </span>
                              </>
                            )}
                          </p>
                        </div>

                        <CheckButtonComponent
                          onCheckChange={(isChecked) => handleCheckChangeIncreaseTime(i.depositId, isChecked)}
                          isChecked={checkedIdsIncreaseTime.includes(i.depositId)}
                          formattedTokenId={i?.depositId}
                          disabled={i?.increateTimeAndAmountAllow}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="deposit-card-container-img">
                    <img src={emptyImage} alt="img" />
                    <p>You don&apos;t have any compatible position</p>
                  </div>
                )}
              </div>
              {
                poolInfo._minLockTime >= 1 &&
                <div>
                  <p>Select Increase Time </p>
                  <div className="modal-deposit-selected-time">
                    <NotchedButtonFill
                      onClick={() => setInCreaseInputlockedTime('604800')}
                      style={{ margin: '4px 0 0 0' }}
                      padding={'10px 5px'}
                      height={'32px'}
                      disabled={Number(poolInfo._minLockTime) < 1}
                    >
                      <Text fontWeight={500} fontSize={18}>
                        <Trans>7 days</Trans>
                      </Text>
                    </NotchedButtonFill>
                    <NotchedButtonFill
                      onClick={() => setInCreaseInputlockedTime('1209600')}
                      style={{ margin: '4px 0 0 0' }}
                      padding={'10px 5px'}
                      height={'32px'}
                      disabled={Number(poolInfo._minLockTime) < 1}
                    >
                      <Text fontWeight={500} fontSize={18}>
                        <Trans>14 days</Trans>
                      </Text>
                    </NotchedButtonFill>
                  </div>
                  <div className="modal-deposit-selected-time" style={{ marginTop: '5px' }}>
                    <NotchedButtonFill
                      onClick={() => setInCreaseInputlockedTime('2592000')}
                      style={{ margin: '4px 0 0 0' }}
                      padding={'10px 5px'}
                      height={'32px'}
                      disabled={Number(poolInfo._minLockTime) < 1}
                    >
                      <Text fontWeight={500} fontSize={18}>
                        <Trans>1 month</Trans>
                      </Text>
                    </NotchedButtonFill>
                    <NotchedButtonFill
                      onClick={() => setInCreaseInputlockedTime('7776000')}
                      style={{ margin: '4px 0 0 0' }}
                      padding={'10px 5px'}
                      height={'32px'}
                      disabled={Number(poolInfo._minLockTime) < 1}
                    >
                      <Text fontWeight={500} fontSize={18}>
                        <Trans>3 months</Trans>
                      </Text>
                    </NotchedButtonFill>
                  </div>
                </div>
              }

            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
                marginTop: '10px',
              }}
            >
              <NotchedButtonFill
                onClick={handleIncreaseTime}
                style={{ margin: '4px 0 0 0' }}
                disabled={disableConfirmButtonIncreaseTime}
              >
                <Text fontWeight={500} fontSize={20}>
                  <Trans>{loadingIncreaseTime ? 'Loading...' : 'Confirm'}</Trans>
                </Text>
              </NotchedButtonFill>
            </div>
          </div>
        )}
        <NotchedButtonFillPrimary onClick={onDismiss} style={{ margin: '14px 0 0 0' }}>
          <Text fontWeight={500} fontSize={20}>
            <Trans>Close</Trans>
          </Text>
        </NotchedButtonFillPrimary>
      </Wrapper>
    </Modal>
  )
}
