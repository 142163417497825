import styled from "styled-components"
import TradingContestTable from "./TradingContestTable";
import { BREAKPOINTS } from "theme";
import { useEffect, useState } from "react";
import { shortenAddress } from "utils";
import { formatXpNumber } from "pages/Xp/sections/XpTitle";

const TradingContestLeaderboardWrapper = styled.div`
    position: relative;
    z-index: 2;
    max-width: 1224px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    display: grid;
    gap: 50px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        max-width: 90%;
        margin-top: 56px;
        gap: 24px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        max-width: 100%;
    }
`

const LinearText = styled.span`
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const Text = styled.span<{
    color?: string;
    fontSize?: string;
}>`
    color: ${({ color }) => color ?? 'rgba(239, 239, 228, 0.50)'};
    font-family: Urbanist;
    font-size: ${({ fontSize }) => fontSize ?? '16px'};;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 24px */
`

const Img = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const Title = styled.h1`
    color: #EFEFE4;
    font-family: Righteous;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 60px */
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        justify-content: center;
        font-size: 32px;
        line-height: 40px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        padding: 0 10px;
    }
`;

const LeaderboardTableBox = styled.div`
    display: flex;
    gap: 48px;
    margin-top: 40px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        gap: 24px;
    }
    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        display: grid;
        margin-top: 0;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        gap: 12px;
    }
   
`
const TopLeaderboardBox = styled.div`
    width: 288px;
    height: 648.044px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 12px;
    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        height: fit-content;
        display: grid;
        padding: 0;
        gap: 24px;
        grid-template-columns:  repeat(auto-fill, calc((100% - 52px)/3));
        width: 100%;
        img{ 
            display: none;
        }
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        gap: 12px;
        grid-template-columns:  repeat(auto-fill, calc((100% - 28px)/3));
    }
`

const TopLeaderboardItemBox = styled.div`
    height: 190px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), linear-gradient(137deg, rgba(10, 9, 15, 0.00) 35.64%, rgba(10, 9, 15, 0.47) 84.72%, rgba(10, 9, 15, 0.00) 134.39%);
    clip-path: polygon(12px 0, 100% 0, 100% calc(100% - 12px), calc(100% - 12px) 100%, 0 100%, 0 12px);
    padding: 11px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        height: fit-content;
    }
`
const TopLeaderboardItemRank = styled.div`
    width: 100%;
    display: grid;
    justify-items: center;
    padding: 15px 60px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), linear-gradient(113deg, rgba(104, 255, 248, 0.00) 6.1%, rgba(104, 255, 248, 0.15) 42.28%, rgba(104, 255, 155, 0.15) 60.74%, rgba(104, 255, 155, 0.00) 94.71%);
    clip-path: polygon(12px 0, 100% 0, 100% calc(100% - 12px), calc(100% - 12px) 100%, 0 100%, 0 12px);
    .rank_no{
        text-align: center;
        font-family: Righteous;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 35px */
        @media screen and (max-width: ${BREAKPOINTS.sm}px){
            font-size: 22px;
        }
        @media screen and (max-width: 450px){
            font-size: 16px;
        }
    }
    .address{
        @media screen and (max-width: 450px){
            font-size: 12px !important;
        }
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        padding: 15px 35px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        padding: 8px 16px;
    }
    /* @media screen and (max-width: 450px){
        padding: 8px 16px;
    } */
`

const RewardBox = styled.div`
    display: grid;
    gap: 8px;
    margin-top: 22px;
`
const RewardBoxLabel = styled.span`
    color: rgba(239, 239, 228, 0.50);
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        font-size: 12px;
    }
`

const RewardBoxContent = styled.span`
    color: #EFEFE4;
    text-align: right;
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 30px */
    text-align: left;
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        font-size: 14px;
    }
`
export default function TradingContestLeaderboard({ data }: any) {
    const [topUsers, setTopUser] = useState<any[]>([])

    const [lastCharCnt, setLastCharCnt] = useState<number>(4);
    useEffect(() => {
        if (data && data?.list?.length > 0)
            setTopUser(data.list.filter((i: { rank: number; }) => i.rank <= 3))
    }, [data])
    useEffect(() => {
        if (window.screen.width < 600)
            setLastCharCnt(2)
    }, [])
    return <TradingContestLeaderboardWrapper>

        <Title>
            <LinearText>Leader</LinearText>board
        </Title>

        <LeaderboardTableBox>
            <TopLeaderboardBox>
                <Img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/top-leaderboard-border.png" />
                {
                    topUsers.length == 0 && [1, 2, 3].map((item, index) => <TopLeaderboardItemBox key={index}>
                        <TopLeaderboardItemRank>
                            <span className="rank_no"><LinearText>No.{item}</LinearText></span>
                            <Text className="address" style={{ fontSize: '18px' }}>~</Text>
                        </TopLeaderboardItemRank>

                        <RewardBox>
                            <RewardBoxLabel>
                                Trading Point
                            </RewardBoxLabel>
                            <RewardBoxContent>
                                ~
                            </RewardBoxContent>
                        </RewardBox>
                    </TopLeaderboardItemBox>)
                }
                {
                    topUsers.map((item, index) => <TopLeaderboardItemBox key={index}>
                        <TopLeaderboardItemRank>
                            <span className="rank_no"><LinearText>No.{item.rank}</LinearText></span>
                            <Text className="address" style={{ fontSize: '18px' }}>{shortenAddress(item.address, lastCharCnt)}</Text>
                        </TopLeaderboardItemRank>

                        <RewardBox>
                            <RewardBoxLabel>
                                Trading Point
                            </RewardBoxLabel>
                            <RewardBoxContent>
                                {formatXpNumber(item?.tradingPoint)}
                            </RewardBoxContent>
                        </RewardBox>
                    </TopLeaderboardItemBox>)
                }
            </TopLeaderboardBox>

            <TradingContestTable dataList={data} />

        </LeaderboardTableBox>

    </TradingContestLeaderboardWrapper>
}