import styled from 'styled-components'
import { ChestPageLinearText, ChestType } from './ChestPageSection1'
import borderBox from '../../../assets/images/monochest/3.svg'
import BuyChestModal from './BuyChestModal'
import { useEffect, useState } from 'react'
import { useToggleWalletModal } from 'state/application/hooks'
import { CustomLightSpinner } from '../../../theme'
import { useCustomeContract } from 'hooks/useContract'
import { MONO_CHEST_NFT_ADDRESSES_MAP } from 'constants/addresses'
import monoNft_abi from '../../../abis/monochest/mononft.json'
import { notification, Popover } from 'antd'
import { formatXpNumber } from 'pages/Xp/sections/XpTitle'
import { SupportedChainId } from 'constants/chains'
import { getContract } from 'utils'
import { RPC_URLS } from 'constants/networks'
import { ethers } from 'ethers'

const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'

const ChestBoxWrapper = styled.div`
  width: 472px;
  height: 561px;
  /* width: 100%; */
  /* height: 100%; */
  position: relative;
  padding: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 45px;

  @media screen and (max-width: 520px) {
    width: 95%;
    height: 400px;
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
`
const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  image-rendering: auto;
`

const Top = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 520px) {
    max-width: 85%;
    margin: auto;
  }
  @media screen and (max-width: 420px) {
    max-width: 100%;
  }
`

const ChestLevelBox = styled.div`
  display: flex;
  width: 184.5px;
  height: 8px;
  gap: 2px;
`

const ChestLevelItem = styled.div`
  height: 100%;
  width: 100%;
  /* clip-path: polygon(2px 0, 100% 0, 100% 100%, 0 100%, 0 2px); */
  /* clip-path: polygon(100% 0, 100% 60%, 90% 100%, 0 100%, 0 0); */
`

const Bottom = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 520px) {
    max-width: 85%;
    margin: auto;
  }
  @media screen and (max-width: 420px) {
    max-width: 100%;
  }
`
const ChestPrice = styled.span`
  color: #fff;
  font-family: 'Roboto Mono';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 16px */
  text-transform: uppercase;
`

const BuyNowBtn = styled.div`
  text-align: right;
  font-family: Righteous;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    scale: 1.05;
  }
`
const ChestImg = styled.img`
  position: relative;
  z-index: 1;
  width: 395.467px;
  height: 250px;
  margin: auto;
  scale: 1.5;
  @media screen and (max-width: 1080px) {
    scale: 1.4;
  }
  @media screen and (max-width: 520px) {
    scale: 1;
    width: 300px;
    height: 190px;
  }
  @media screen and (max-width: 420px) {
    scale: 1;
    width: 250px;
    height: 160px;
  }
`

const HoverBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #efefe400;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TreePopupContent = styled.div`
  background: black;
  min-width: 100px;
  height: fit-content;
  display: inline-flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-center;
  justify-items: center;
  gap: 0px;
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.2);
  p {
    max-width: 157px;
    color: #efefe4;
    font-family: Urbanist !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 17.5px */
    margin: 0;
    text-align: center;
  }
  img.earned_img{
    width: 16px;
    height: 16px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
  }
`

const RwTreePopupContent = styled(TreePopupContent)`
  position: relative;
  padding: 12px;

  .rw_info_list{
    position: relative;
    z-index: 2;
    display: grid;
    gap: 5px;
  }
  p{
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 12.35px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12.35px */
    text-transform: uppercase;
    width: 100%;

    position: relative;
    margin-bottom: 8px;
    max-width: unset;
  }
`

const RewawardInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 159.643px;
  height: 42.709px;
  border-radius: 3.087px;
  border: 0.386px solid #68FFEF;
  background: rgba(0, 0, 0, 0.50);
  padding: 7.5px 15.5px;

  .box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
  }
  .label{
    color: #FFF;
    font-family: Righteous;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; 
  }
  .content{
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  img{
    width: 38px;
    height: 38px;
  }
`

const getGradient = (index: number, isActive = true) => {
  if (!isActive) return '#2D2C30'
  else return 'linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)'
}
export default function ChestBox({
  data,
  musdPrice,
  userChestOwnByType,
  account,
  getContractInfo,
  loadingGetContractInfo,
  loaddingApproveNftContract,
  checkApproveNftContract,
  handleApproveNftContract,
  loadTotalUserNftBalance
}: {
  data: ChestType
  musdPrice: number
  userChestOwnByType: any
  account: any
  getContractInfo: () => void
  loadingGetContractInfo: any
  loaddingApproveNftContract: any
  checkApproveNftContract: any
  handleApproveNftContract: any,
  loadTotalUserNftBalance: any
}) {
  const [isOpenBuyChest, setIsOpenBuyChest] = useState(false)
  const toggleWalletModal = useToggleWalletModal()
  const [isScreenLt360, setIsScreenLt360] = useState(false)

  const [userNftBalance, setUserNftBalance] = useState<number>(0)
  const monoNftContract = getContract(MONO_CHEST_NFT_ADDRESSES_MAP[SupportedChainId.BLAST], monoNft_abi, new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.BLAST][3]))

  const loadNftKeyBalance = async () => {
    if (!account) return
    const userNftBalance = await monoNftContract?.balanceOf(account, data?.nftId)
    const formattedUserBalance = parseInt(userNftBalance._hex, 16)
    setUserNftBalance(formattedUserBalance)
    loadTotalUserNftBalance()
  }

  useEffect(() => {
    setIsScreenLt360(window.screen.width <= 360)
    loadNftKeyBalance()
  }, [account])

  const level = data.level // rare level of the chest
  return (
    <ChestBoxWrapper>
      <BuyChestModal
        key={data.nftId}
        isOpen={isOpenBuyChest}
        onDismiss={() => setIsOpenBuyChest(false)}
        data={data}
        musdPrice={musdPrice}
        getContractInfo={getContractInfo}
        loaddingApproveNftContract={loaddingApproveNftContract}
        checkApproveNftContract={checkApproveNftContract}
        handleApproveNftContract={handleApproveNftContract}
        loadNftKeyBalance={loadNftKeyBalance}
        userNftBalance={userNftBalance}
      />

      <Img src={borderBox} />
      {/* <HoverBox>
        <button>open box</button>
      </HoverBox> */}
      <Top>
        <div style={{ display: 'grid', gap: '17px' }}>
          <ChestLevelBox>
            <ChestLevelItem
              style={{
                background: getGradient(1, 1 <= level),
                clipPath: 'polygon(3px 0, 100% 0, 100% 100%, 0 100%, 0 3px)',
              }}
            />
            <ChestLevelItem style={{ background: getGradient(2, 2 <= level) }} />
            <ChestLevelItem style={{ background: getGradient(3, 3 <= level) }} />
            <ChestLevelItem style={{ background: getGradient(4, 4 <= level) }} />
            <ChestLevelItem
              style={{
                background: getGradient(5, 5 <= level),
                clipPath: 'polygon(100% 0, 100% 70%, 93% 100%, 0 100%, 0 0)',
              }}
            />
          </ChestLevelBox>

          <span
            style={{
              color: 'white',
              fontSize: isScreenLt360 ? 12 : 16,
              fontFamily: 'Roboto Mono',
              fontWeight: '500',
              textTransform: 'uppercase',
              lineHeight: '16px',
              wordWrap: 'break-word',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            {data.chestName}
            {
              data?.earnedReward && <Popover
                overlayInnerStyle={{ padding: 0, borderRadius: 0 }}
                arrow={true}
                color="black"
                placement="top"
                content={<TreePopupContent>
                  <p style={{ opacity: 0.4, marginBottom: '10px', fontSize: '14px' }}>Total Earned: <br /></p>
                  <p style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    {formatXpNumber(data?.earnedReward?.xmono ?? 0)} <img className='earned_img' src="https://raw.githubusercontent.com/Monoswap-Labs/monoswap-token-list/main/img/blast_sepolia/assets/0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055/logo.png" /> / {formatXpNumber(data?.earnedReward?.usd ?? 0)} <img className='earned_img' src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musd.svg" /> / {data?.earnedReward?.monopass ?? 0} <img className='earned_img' src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/monopass.png" /></p>
                </TreePopupContent>}
              >
                <img
                  style={{ width: '28px', height: '28px', cursor: 'pointer' }}
                  src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/more-info-icon.png"
                  alt="monoswap"
                />
              </Popover>
            }

            <Popover
              overlayInnerStyle={{ padding: 0, borderRadius: 0 }}
              arrow={true}
              color="black"
              placement="top"
              content={<RwTreePopupContent >
                <Img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/rw_popup_bg.png" />
                <p>{data.chestName}</p>

                <div className='rw_info_list'>
                  <RewawardInfoItem>
                    <div className='box'>
                      <span className='label'>MonoPot Tickets</span>
                      <span className='content'>{data.rwInfo.ticket}</span>
                    </div>

                    <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/rw_icon/ticket.png" alt="monoswap" />
                  </RewawardInfoItem>

                  <RewawardInfoItem>
                    <div className='box'>
                      <span className='label'>MonoPass</span>
                      <span className='content'>{data.rwInfo.monopass}</span>
                    </div>

                    <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/rw_icon/monopass.png" alt="monoswap" />
                  </RewawardInfoItem>

                  <RewawardInfoItem>
                    <div className='box'>
                      <span className='label'>xMONO</span>
                      <span className='content'>{data.rwInfo.xmono}</span>
                    </div>

                    <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/rw_icon/xmono.png" alt="monoswap" />
                  </RewawardInfoItem>

                  <RewawardInfoItem>
                    <div className='box'>
                      <span className='label'>MUSD</span>
                      <span className='content'>{data.rwInfo.musd}</span>
                    </div>

                    <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/rw_icon/musd.png" alt="monoswap" />
                  </RewawardInfoItem>
                </div>
              </RwTreePopupContent>}
            >
              <img
                style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/current-price-img.png"
                alt="monoswap"
              />
            </Popover>
          </span>

        </div>
        <div style={{ display: 'grid', gap: '6px' }}>
          <span
            style={{
              textAlign: 'right',
              color: 'rgba(255, 255, 255, 0.50)',
              fontSize: isScreenLt360 ? 10 : 12,
              fontFamily: 'Roboto Mono',
              fontWeight: '500',
              textTransform: 'uppercase',
              lineHeight: '12px',
              wordWrap: 'break-word',
            }}
          >
            {userNftBalance > 0 ? "You've been Dropped" : ''}
          </span>
          <span
            style={{
              textAlign: 'right',
              color: 'white',
              fontSize: isScreenLt360 ? 12 : 16,
              fontFamily: 'Roboto Mono',
              fontWeight: '700',
              textTransform: 'uppercase',
              lineHeight: '16px',
              wordWrap: 'break-word',
            }}
          >
            {
              userNftBalance > 0 && <>
                {loadingGetContractInfo ? (
                  <CustomLightSpinner src={Circle} alt="loader" size={isScreenLt360 ? "10px" : "14px"} />
                ) : userNftBalance === 0 ? 0 : userNftBalance}{' '}
                Chest
              </>
            }

          </span>
        </div>
      </Top>
      <ChestImg src={`https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/chest${level}.gif`} />
      <Bottom>
        <ChestPrice>
          <span style={{ fontWeight: 500 }}> {data.usdbPrice} USDB </span>/ 1 CHEST
        </ChestPrice>
        {account ? (
          userNftBalance > 0 && !checkApproveNftContract ?
            <BuyNowBtn onClick={() => { if (loaddingApproveNftContract) return; handleApproveNftContract() }}>
              <ChestPageLinearText>{loaddingApproveNftContract ? 'Loading...' : 'Approve NFT key'}</ChestPageLinearText>
            </BuyNowBtn>
            :
            <BuyNowBtn onClick={() => {
              // setIsOpenBuyChest(true)
              notification.warning({
                message: 'Chest purchases are temporarily unavailable'
              })
            }}>
              <ChestPageLinearText>{userNftBalance === 0 ? 'BUY NOW' : 'OPEN NOW'}</ChestPageLinearText>
            </BuyNowBtn>
        ) : (
          <BuyNowBtn onClick={toggleWalletModal}>
            <ChestPageLinearText>CONNECT WALLET</ChestPageLinearText>
          </BuyNowBtn>
        )}
      </Bottom>
    </ChestBoxWrapper>
  )
}
export const numberWithCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
