import { useWeb3React } from "@web3-react/core"
import { MONO_PASS_SALE_ADDRESSES_MAP, MONO_PASS_ADDRESSES_MAP, MONO_PASS_STAKE_ADDRESSES_MAP } from "constants/addresses"
import { SupportedChainId } from "constants/chains"
import { RPC_URLS } from "constants/networks"
import { ethers } from "ethers"
import { getContract } from "utils"
import { useCustomeContract } from "./useContract"
import MONO_PASS_SALE_ABI from '../abis/monopass/MonoPassSale.json'
import MONO_PASS_ABI from '../abis/monopass/Monopass.json'
import MONO_PASS_STAKE_ABI from '../abis/monopass/MonopassStake.json'
import { useEffect, useState } from "react"
import { notification } from "antd"
import checkUnsupportChainId from "utils/checkUnsupportChainId"


export type DepositedMonopassType = {
    id: number
    depositedTime: number
}
export default function useMonoPass() {
    const PROVIDER = new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.BLAST][1])
    const PROVIDER1 = new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.BLAST][1])
    const { account, chainId } = useWeb3React()
    const [epochEndTime, setEpochEndTime] = useState<number>(1719061200)
    const [startWhitelistTime, setStartWhitelistTime] = useState<number>(1718888400)
    const [startPublicTime, setStartPublicTime] = useState<number>(1718974800)
    const [mintPrice, setMintPrice] = useState<number>(0.001)

    const [totalHolders, setTotalHolders] = useState<number>(0)
    const [totalStake, setTotalStake] = useState<number>(0)
    const [totalSupply, setTotalSupply] = useState<number>(0)
    const [totalSold, setTotalSold] = useState<number>(0)
    const [totalMinted, setTotalMinted] = useState<number>(0)

    // for stake
    // const stakeInfo
    const [stakeInfo, setStakeInfo] = useState<{
        totalStaked: number
        lastReward: number
        endTime: number
        startTime: number
        weeklyChest: number[]
        rewardPerSec: number
        lockTime: number
    }>({
        totalStaked: 0,
        lastReward: 0,
        startTime: 0,
        endTime: 0,
        weeklyChest: [7, 7, 7, 7, 7],
        rewardPerSec: 0,
        lockTime: 0
    })

    const [userStakeInfo, setUserStakeInfo] = useState<{
        totalStaked: DepositedMonopassType[]
        monopassCnt: DepositedMonopassType[]
        pendingReward: number
        totalEarned: number
        isMinted: boolean
        isWhitelistMinted: boolean
    }>({
        totalStaked: [],
        monopassCnt: [],
        pendingReward: 0,
        totalEarned: 0,
        isMinted: false,
        isWhitelistMinted: false
    })
    const [hasApprovedMonopass, setHasApprovedMonopass] = useState<boolean>(false)
    const [isApprovingMonopass, setIsApprovingMonopass] = useState<boolean>(false)

    const [isStaking, setIsStaking] = useState<{
        id: number
        status: boolean
    }>({
        id: 0,
        status: false
    })

    const monoPassSaleContract = getContract(MONO_PASS_SALE_ADDRESSES_MAP[SupportedChainId.BLAST], MONO_PASS_SALE_ABI, PROVIDER)
    const monoPassSaleContract1 = getContract(MONO_PASS_SALE_ADDRESSES_MAP[SupportedChainId.BLAST], MONO_PASS_SALE_ABI, PROVIDER1)
    const monoPassSaleContractWithSign = useCustomeContract(MONO_PASS_SALE_ADDRESSES_MAP[SupportedChainId.BLAST], MONO_PASS_SALE_ABI, true)

    const monoPassContract = getContract(MONO_PASS_ADDRESSES_MAP[SupportedChainId.BLAST], MONO_PASS_ABI, PROVIDER)
    const monoPassContractWithSign = useCustomeContract(MONO_PASS_ADDRESSES_MAP[SupportedChainId.BLAST], MONO_PASS_ABI, true)

    const monoPassStakeContract = getContract(MONO_PASS_STAKE_ADDRESSES_MAP[SupportedChainId.BLAST], MONO_PASS_STAKE_ABI, PROVIDER)
    const monoPassStakeContract1 = getContract(MONO_PASS_STAKE_ADDRESSES_MAP[SupportedChainId.BLAST], MONO_PASS_STAKE_ABI, PROVIDER1)
    const monoPassStakeContractWithSign = useCustomeContract(MONO_PASS_STAKE_ADDRESSES_MAP[SupportedChainId.BLAST], MONO_PASS_STAKE_ABI, true)

    const loadEpochEndTime = async () => {
        try {
            const endTimeRes = await monoPassSaleContract1?.endTimestamp()
            setEpochEndTime(endTimeRes.toNumber())
        }
        catch (err) { }
    }

    const loadStartTime = async () => {
        try {
            const startWhitelistTimeRes = await monoPassSaleContract1?.startWhitelistTimestamp()
            setStartWhitelistTime(startWhitelistTimeRes.toNumber())

            const startPublicTimeRes = await monoPassSaleContract1?.startPublicTimestamp()
            setStartPublicTime(startPublicTimeRes.toNumber())
        }
        catch (err) { }
    }

    const loadMintPrice = async () => {
        try {
            const mintPriceRes = await monoPassSaleContract1?.price()
            setMintPrice(Number(ethers.utils.formatEther(mintPriceRes)))
        }
        catch (err) { }
    }

    const loadInfo = async () => {
        try {
            const totalHolderRes = await monoPassContract?.getHoldersLength()
            setTotalHolders(totalHolderRes.toNumber())

            const totalSupplyRes = await monoPassSaleContract?.totalSupply()
            setTotalSupply(totalSupplyRes.toNumber())
            const totalSoldRes = await monoPassSaleContract?.totalSold()
            setTotalSold(totalSoldRes.toNumber())

            if (totalSoldRes.toNumber() >= 200)
                setTotalMinted(totalSoldRes.toNumber())
            else setTotalMinted(totalSupplyRes.toNumber() - totalSoldRes.toNumber())
            
        }
        catch (err) { }
    }

    const checkApproveMonopass = async () => {
        try {
            const isApproved = await monoPassContractWithSign?.isApprovedForAll(account, MONO_PASS_STAKE_ADDRESSES_MAP[SupportedChainId.BLAST])
            if (isApproved)
                setHasApprovedMonopass(true)
            else
                setHasApprovedMonopass(false)
        } catch (error) {
        }
    }

    const approveMonopass = async () => {
        checkUnsupportChainId(chainId)
        if (isApprovingMonopass) return
        try {
            setIsApprovingMonopass(true)
            const tx = await monoPassContractWithSign?.setApprovalForAll(MONO_PASS_STAKE_ADDRESSES_MAP[SupportedChainId.BLAST], true)
            await tx.wait()
            setHasApprovedMonopass(true)
        }
        catch (err) {
            notification.error({
                message: 'Approve failed'
            })
        }
        finally {
            setIsApprovingMonopass(false)
        }
    }
    // stake
    const loadStakeInfo = async () => {
        try {
            const endTimeRes = await monoPassStakeContract1?.endTime()
            const startTimeRes = await monoPassStakeContract?.startTime()
            const totalStakedRes = await monoPassStakeContract?.totalStaked()
            const rewardPerSecondRes = await monoPassStakeContract?.rewardPerSecond()
            const lockTimeRes = await monoPassStakeContract?.lockTime()

            setStakeInfo(prev => ({
                ...prev,
                startTime: startTimeRes.toNumber(),
                endTime: endTimeRes.toNumber(),
                totalStaked: totalStakedRes.toNumber(),
                rewardPerSec: Number(ethers.utils.formatUnits(rewardPerSecondRes, 18)),
                lockTime: lockTimeRes.toNumber(),
            }))
        }
        catch (err) { }
    }
    const loadUserStakeInfo = async () => {
        try {
            const myMonopassCntRes = await monoPassContract?.getHolder(account)
            const myStakeMonopassRes = await monoPassStakeContract?.getPassOwned(account)
            const claimedRes = await monoPassStakeContract?.claimed(account)
            const isMintedRes = await monoPassSaleContract?.isMinted(account)
            const isWhitelistMintedRes = await monoPassSaleContract?.isWhitelistMinted(account)

            let myStakedMonopass: DepositedMonopassType[] = [];
            if (myStakeMonopassRes[0].length > 0) {
                myStakedMonopass = myStakeMonopassRes[0].map((id: { toNumber: () => any }, index: string | number) => ({
                    id: id.toNumber(),
                    depositedTime: myStakeMonopassRes[1][index].toNumber()
                }))
            }

            let myMonopass: DepositedMonopassType[] = [];
            if (myMonopassCntRes[1].length > 0)
                myMonopass = myMonopassCntRes[1].map((i: { toNumber: () => any }) => ({
                    id: i.toNumber(),
                    depositedTime: 0
                }))

            const pendingRewardRes = await monoPassStakeContract?.pendingReward(account)
            setUserStakeInfo({
                totalStaked: myStakedMonopass,
                monopassCnt: myMonopass,
                pendingReward: Number(ethers.utils.formatUnits(pendingRewardRes, 18)),
                totalEarned: Number(ethers.utils.formatUnits(claimedRes, 18)),
                isMinted: isMintedRes,
                isWhitelistMinted: isWhitelistMintedRes
            })

        }
        catch (err) {
            console.log('loadUserStakeInfo failed', err);
        }
    }

    const stakeMonoPass = async (item: DepositedMonopassType, okCallback = () => { }) => {
        checkUnsupportChainId(chainId)
        if (isStaking.status) return
        try {
            setIsStaking({
                id: item.id,
                status: true
            })
            const gasEstimate = await monoPassStakeContractWithSign?.estimateGas.deposit(item.id)
            const gasLimitCalc = Math.floor(gasEstimate ? gasEstimate.toNumber() * 1.5 : 200000)
            const tx = await monoPassStakeContractWithSign?.deposit(item.id, { gasLimit: gasLimitCalc })
            await tx.wait()

            loadUserStakeInfo()
            loadStakeInfo()
            okCallback()

            notification.success({
                message: 'Stake successfull'
            })
        }
        catch (err) {
            notification.error({
                message: 'Stake failed'
            })
        }
        finally {
            setIsStaking({
                id: 0,
                status: false
            })
        }
    }

    useEffect(() => {
        loadEpochEndTime()
        loadStartTime()
        loadMintPrice()
        loadInfo()
        loadStakeInfo()
    }, [])

    useEffect(() => {
        if (account) {
            loadUserStakeInfo()
            checkApproveMonopass()
        }
    }, [account])
    return {
        monoPassSaleContract,
        monoPassSaleContractWithSign,
        monoPassContract,
        monoPassContractWithSign,
        monoPassStakeContract,
        monoPassStakeContractWithSign,

        epochEndTime,
        startWhitelistTime,
        startPublicTime,

        totalHolders,
        totalStake,
        totalSupply,
        totalSold,
        totalMinted,
        mintPrice,

        stakeInfo,
        userStakeInfo,
        isApprovingMonopass,
        hasApprovedMonopass,
        isStaking,
        stakeMonoPass,
        loadStakeInfo,
        loadUserStakeInfo,
        approveMonopass
    }
}