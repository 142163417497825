import { MONO_ADDRESSES_MAP, MONO_DIVIDEND_ADDRESSES_MAP, XMONO_ADDRESSES_MAP } from "constants/addresses";
import { useCustomeContract } from "hooks/useContract";
import { useEffect, useMemo, useState } from "react";
import ERC_20 from '../abis/erc20.json'
import DIVIDEND_ABI from '../abis/dividend/dividend.json'
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { getContract } from "utils";
import { RPC_URLS } from "constants/networks";
import { SupportedChainId } from "constants/chains";
import useTokenPrice from "./useTokenPrice";


export type DividendUserInfo = {
    amount: number;
    rewardDebt: number
}

export default function useDividendContext() {
    const PROVIDER = new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.BLAST][0])
    const { account, chainId } = useWeb3React()
    const {
        price: xMonoPriceRes
    } = useTokenPrice(MONO_ADDRESSES_MAP[SupportedChainId.BLAST])

    const [totalStakedXmono, setTotalStakedXmono] = useState<number>(0);
    const [userXmonoBalance, setUserXmonoBalance] = useState<number>(0);
    const [pendingReward, setPendingReward] = useState<number>(0);
    const [totalReward, setTotalReward] = useState<number>(0);
    const [epochTime, setEpochTime] = useState<number>(0);

    const [currentEpoch, setCurrentEpoch] = useState<{
        id: number;
        totalRw: number;
        rwPerSeccond: number
    }>({
        id: -1,
        totalRw: 0,
        rwPerSeccond: 0
    })
    const [totalEpoch, setTotalEpoch] = useState<number>(0)

    const [dividendUserInfo, setDividendUserInfo] = useState<DividendUserInfo>({
        amount: 0,
        rewardDebt: 0
    })

    const xMonoPrice = useMemo(() => xMonoPriceRes == 0 ? 2.1 : xMonoPriceRes, [xMonoPriceRes])

    const dividendContract = getContract(MONO_DIVIDEND_ADDRESSES_MAP[SupportedChainId.BLAST], DIVIDEND_ABI, PROVIDER)
    const dividendContractWithSign = useCustomeContract(MONO_DIVIDEND_ADDRESSES_MAP[SupportedChainId.BLAST], DIVIDEND_ABI, true)

    const xMonoContract = getContract(XMONO_ADDRESSES_MAP[SupportedChainId.BLAST], ERC_20, PROVIDER)
    const xMonoContractWithSign = useCustomeContract(XMONO_ADDRESSES_MAP[SupportedChainId.BLAST], ERC_20, true)


    async function loadUserBalance() {
        if (!account) return;
        const stakeTokenBalance = await xMonoContract?.balanceOf(account)
        const formattedstakeTokenBalance = Number(
            Number(ethers.utils.formatUnits(stakeTokenBalance, 18)) - Number(0.001)
        );
        setUserXmonoBalance(formattedstakeTokenBalance);
    }

    async function loadTotalStakedXmono() {
        const stakeTokenBalance = await xMonoContract?.balanceOf(MONO_DIVIDEND_ADDRESSES_MAP[SupportedChainId.BLAST])
        const formattedstakeTokenBalance = Number(
            Number(ethers.utils.formatUnits(stakeTokenBalance, 18)) - Number(0.001)
        );
        setTotalStakedXmono(formattedstakeTokenBalance);
    }

    const getUserDeposits = async () => {
        if (!account) return
        dividendContract?.userInfo(account)
            .then((data: any) => {
                setDividendUserInfo({
                    amount: Number(ethers.utils.formatUnits(String(data.amount), 18)),
                    rewardDebt: Number(ethers.utils.formatUnits(String(data.rewardDebt), 18)),
                })
            })
    }
    const loadEpochInfo = async () => {
        const epochIdRes = await dividendContract.getEpoch(Math.floor(Date.now() / 1000))

        const formattedEpochId = epochIdRes.toNumber()
        setTotalEpoch(formattedEpochId + 1)

        const currentEpochInfoRes = await dividendContract.epochs(formattedEpochId)
        const currentEpochRes = {
            id: formattedEpochId,
            totalRw: Number(ethers.utils.formatUnits(currentEpochInfoRes.totalAmount, 18)),
            rwPerSeccond: Number(ethers.utils.formatUnits(currentEpochInfoRes.rewardPerSecond, 18))
        }
        setCurrentEpoch(currentEpochRes)

        const epochTimeRes = await dividendContract.getNextEpochTime()
        setEpochTime(epochTimeRes.toNumber())

    }

    const loadTotalEpochRw = async () => {
        if (totalEpoch > 0) {
            let totalRw = 0
            for (let index = 0; index < totalEpoch; index++) {
                const epochInfoRes = await dividendContract.epochs(index)
                totalRw += Number(ethers.utils.formatUnits(epochInfoRes.totalAmount, 18));
            }
            setTotalReward(totalRw)
        }
    }
    useEffect(() => {
        loadTotalEpochRw()
    }, [totalEpoch])

    const loadPendingReward = async () => {
        if (!account) return
        try {
            const pendingRewardRes = await dividendContract?.pendingReward(account)
            setPendingReward(Number(ethers.utils.formatUnits(pendingRewardRes, 18)))
        }
        catch (err) {
            console.log('loadPendingReward failed', err);
        }
    }

    const loadStakeData = () => {
        loadPendingReward()
        loadUserBalance()
        getUserDeposits()
        loadTotalStakedXmono()
        loadEpochInfo()
    }

    useEffect(() => {
        loadStakeData()
    }, [account, chainId])

    useEffect(() => {
    }, [])
    return {
        epochTime,
        totalReward,
        pendingReward,
        currentEpoch,
        totalStakedXmono,
        xMonoPrice,
        userXmonoBalance,
        dividendUserInfo,
        loadStakeData,
        loadPendingReward,
        getUserDeposits,

        dividendContract,
        dividendContractWithSign,
        xMonoContract,
        xMonoContractWithSign
    }
}