import { useEffect, useState } from 'react'
import './musd.css'
import { useToggleWalletModal } from 'state/application/hooks'
import { useWeb3React } from '@web3-react/core'
import { NotchedButtonFill } from 'components/Button'
import { CustomLightSpinner } from '../../theme'
import { MouseoverTooltip } from 'components/Tooltip'
import { PlusSquare } from 'react-feather'
import axios from 'axios'
import { getExplorerLink, ExplorerDataType } from 'utils/getExplorerLink'
import { ExternalLink } from '../../theme'

// import
import { useCustomeContract } from 'hooks/useContract'
import {
  BLAST_MUSD_ADDRESSES,
  BLAST_USDB_ADDRESSES,
} from '../../constants/addresses'
import musd_abi from '../../abis/musd/musd.json'
import usdb_abi from '../../abis/musd/usdb.json'
import { ethers } from 'ethers'
import MintMusdModal from 'components/Musd/MintMusdModal'
import RedeemMusdModal from 'components/Musd/RedeemMusdModal'
import { getContract } from 'utils'
import { SupportedChainId } from 'constants/chains'
import { RPC_URLS } from 'constants/networks'
import { MusdPriceChart } from './MusdPriceChart'
import { BASE_API } from 'pages/Xp'
import { formatXpNumber } from 'pages/Xp/sections/XpTitle'
import CollateralChart from './CollateralChart'
import { Pagination } from 'antd'
import { PrevNextRender } from 'pages/Xp/sections/ClaimedXpHistory'
import checkUnsupportChainId from 'utils/checkUnsupportChainId'

const topImage = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/topImage.svg'
const musdlogo = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musdlogo.svg'
const increase = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/increase.png'
const musdIcon = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musd.svg'
const usdbIcon = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/usdb.svg'
const circular = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/circular.svg'
const colartetal =
  'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/colateral.svg'
const outlink = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/outlink.svg'
const nativeyeild =
  'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/nativeyeild.svg'
const nodepeg = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/nodepeg.svg'
const pricegrow =
  'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/pricegrow.svg'
const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'

interface HandleAddCustomTokenParams {
  symbol: string
  contractAddress: string
  decimals: number
  tokenLogoURL: string
}
interface HandleAddCustomTokenFunc {
  (params: HandleAddCustomTokenParams): Promise<void>
}

type MusdHistoryEnumType = 'MINT' | 'REDEEM' | 'ADD_COL' | 'ALL'
type MusdHistoryType = {
  musdType: MusdHistoryEnumType
  amount: number
  createdAt: number
  hash: string
}
type MusdHistoryPageType = {
  data: MusdHistoryType[]
  totalElement: number
}

export type MusdStatType = {
  price: number
  supply: number
  collateral: number
  createdAt: number
  timestamp: number
}

export type TimePeriodType = 'HOUR' | 'DAY' | 'WEEK' | '1MONTH' | 'MONTH'

const MusdPage = () => {
  const toggleWalletModal = useToggleWalletModal()
  const { account, chainId } = useWeb3React()
  const [customeChainId, setCustomeChainId] = useState(81457)

  useEffect(() => {
    if (chainId) {
      setCustomeChainId(chainId)
    } else {
      setCustomeChainId(81457)
    }
  }, [account, chainId])
  const TOKEN_ARRAY = {
    USDB: {
      symbol: 'USDB',
      contractAddress: BLAST_USDB_ADDRESSES,
      decimals: 18,
      tokenLogoURL:
        'https://raw.githubusercontent.com/Monoswap-Labs/monoswap-token-list/main/img/blast_sepolia/assets/0x4200000000000000000000000000000000000022/logo.png',
    },
    MUSD: {
      symbol: 'MUSD',
      contractAddress: BLAST_MUSD_ADDRESSES,
      decimals: 18,
      tokenLogoURL:
        'https://raw.githubusercontent.com/Monoswap-Labs/monoswap-token-list/main/img/blast_sepolia/assets/0x54D12b155dA569aaEa910A778Eb3EC9cd2B26230/logo.png',
    },
  }
  const [currentTab, setCurrentTab] = useState(1)
  const [loadingMintMusd, setLoadingMintMusd] = useState(false)
  const [loadingRedeemUsdb, setLoadingRedeemUsdb] = useState(false)
  const [loadingBalance, setLoadingBalance] = useState(false)
  const [depositeUsdbAmount, setDepositeUsdbAmount] = useState('')
  const [depositeMusdAmount, setDepositeMusdAmount] = useState('')
  const [userMusdBalance, setUserMusdBalance] = useState('')
  const [userMusdMaxBalance, setUserMusdMaxBalance] = useState('')
  const [userUsdbBalance, setUserUsdbBalance] = useState('')
  const [userUsdbMaxBalance, setUserUsdbMaxBalance] = useState('')
  const [usdbValidateMess, setUsdbValidateMess] = useState('')
  const [musdValidateMess, setMusdValidateMess] = useState('')
  const [usdbAllowance, setUsdbAllowance] = useState<number>(0)
  // usdb approve
  const [loadingApproveUsdbContract, setLoadingApproveUsdbContract] = useState(false)
  const [checkApproveUsdbContract, setCheckApproveUsdbContract] = useState(false)

  // modal
  const [isPenModalMint, setIsPenModalMint] = useState(false)
  const [errorMintMusd, setErrorMintMusd] = useState(false)
  const [errorMintMusdMess, setErrorMintMusdMess] = useState('')
  const [isOPenModalRedeem, setIsOPenModalRedeem] = useState(false)
  const [errorRedeemUsdb, setErrorRedeemUsdb] = useState(false)
  const [errorRedeemUsdbMess, setErrorRedeemUsdbMess] = useState('')
  // contract info
  const [circulating, setCiculating] = useState('')
  const [collateral, setColateral] = useState('')
  const [musdPrice, setMusdPrice] = useState('')
  //
  const [loadingHistory, setLoadingHistory] = useState(false)

  const validateInput = (value: any, useBalance: any) => {
    const num = Number(value)
    if (isNaN(num) || num < 10) {
      return 'minimum'
    }
    if (num > Number(useBalance)) {
      return 'error'
    }
    if (currentTab == 1)
      if (num > usdbAllowance) return 'allowance'
    return 'valid'
  }

  // const musdContract = useCustomeContract(BLAST_MUSD_ADDRESSES, musd_abi, false)
  const musdContractWithSign = useCustomeContract(BLAST_MUSD_ADDRESSES, musd_abi, true)
  const usdbContractWithSign = useCustomeContract(BLAST_USDB_ADDRESSES, usdb_abi, true)
  const getUserBalance = async () => {
    setLoadingBalance(true)
    try {
      const userMusd = await musdContractWithSign?.balanceOf(account)
      const userUsdb = await usdbContractWithSign?.balanceOf(account)

      // musd
      const formmattedMusdUserBalance = String(formatXpNumber(ethers.utils.formatUnits(userMusd, 18))).replace(',', '')
      const maxMusdToken = formmattedMusdUserBalance.toString()
      setUserMusdBalance(formmattedMusdUserBalance)
      setUserMusdMaxBalance(maxMusdToken)
      // usdb
      const formmattedUsdbUserBalance = String(formatXpNumber(ethers.utils.formatUnits(userUsdb, 18))).replace(',', '')
      const maxUsdbToken = Number(formmattedUsdbUserBalance).toString()
      setUserUsdbBalance(formmattedUsdbUserBalance)
      setUserUsdbMaxBalance(maxUsdbToken)
      setLoadingBalance(false)
    } catch (error) {
      console.log('error', error)
      setLoadingBalance(false)
    }
  }

  const musdContract = getContract(
    BLAST_MUSD_ADDRESSES,
    musd_abi,
    new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.BLAST][0])
  )

  const getContractInfo = async () => {
    setLoadingBalance(true)
    try {
      // supply
      const circulatingSupply = await musdContract?.totalSupply()
      const musdPrice = await musdContract?.getPrice()

      if (circulatingSupply && musdPrice) {
        const formattedSupply = Number(ethers.utils.formatUnits(circulatingSupply, 'ether')).toFixed(2).toString()
        setCiculating(formattedSupply)

        const formattedmusdPrice = Number(ethers.utils.formatUnits(musdPrice, 8)).toFixed(5).toString()
        setMusdPrice(formattedmusdPrice)
        const colab = (Number(formattedSupply) * Number(formattedmusdPrice)).toFixed(2).toString()
        setColateral(colab)
        setLoadingBalance(false)
      }
      setLoadingBalance(false)
    } catch (error) {
      setLoadingBalance(false)
    }
  }

  // mint musd
  const depositMusd = async () => {
    setLoadingMintMusd(true)
    setIsPenModalMint(true)
    const formattedDepositAmount = ethers.utils.parseUnits(depositeUsdbAmount, 'ether').toString()

    try {
      if (account) {
        // Estimate the gas limit
        const gasLimit = await musdContractWithSign?.estimateGas.deposit(formattedDepositAmount)
        const increasedGasLimit = Math.floor(gasLimit ? gasLimit.toNumber() * 1.5 : 250000)

        const tx = await musdContractWithSign?.deposit(formattedDepositAmount, { gasLimit: increasedGasLimit })
        await tx.wait()
        setLoadingMintMusd(false)
        setDepositeUsdbAmount('')
        getUserBalance()
        handleCheckApproveUsdbContract()
      }
    } catch (err) {
      setLoadingMintMusd(false)
      setErrorMintMusd(true)
      if (err?.code == 'UNPREDICTABLE_GAS_LIMIT' || (err?.data?.message && String(err?.data?.message).indexOf('insufficient funds')))
        setErrorMintMusdMess("Insufficient funds for gas")
      else
        setErrorMintMusdMess('Mint failed')
      console.log('error', err)
    }
  }

  const handleValidateDeposit = () => {
    if (depositeUsdbAmount == '') {
      setUsdbValidateMess('')
      return
    }
    const validate = validateInput(depositeUsdbAmount, userUsdbMaxBalance)
    if (validate === 'minimum') {
      setUsdbValidateMess('Minimum amount 10 USDB.')
      return
    }
    if (validate == 'allowance') {
      setUsdbValidateMess(`Click Approve to increase your spending limit.`)
      return
    }
    if (validate !== 'valid') {
      setUsdbValidateMess(`Input amount must less than ${formatXpNumber(userUsdbMaxBalance)} USDB.`)
      return
    }

    setUsdbValidateMess('')
  }

  const handleDeposite = () => {
    checkUnsupportChainId(chainId)
    depositMusd()
    setErrorMintMusd(false)
  }
  // approve spend usdb to musd contract
  const approveUsdbContract = async () => {
    checkUnsupportChainId(chainId)
    setLoadingApproveUsdbContract(true)
    try {
      const setApproveContract = await usdbContractWithSign?.approve(BLAST_MUSD_ADDRESSES, ethers.constants.MaxUint256)
      await setApproveContract.wait()
      setLoadingApproveUsdbContract(false)
      return true
    } catch (error) {
      console.log(error)
      setLoadingApproveUsdbContract(false)
      return false
    }
  }

  const handleCheckApproveUsdbContract = async () => {
    try {
      const allowance = await usdbContractWithSign?.allowance(account, BLAST_MUSD_ADDRESSES)
      const formmatedAllowance = Number(ethers.utils.formatUnits(allowance, 18))

      setUsdbAllowance(formmatedAllowance)
      if (formmatedAllowance > 0 && formmatedAllowance >= 10 && formmatedAllowance >= Number(depositeUsdbAmount ?? '0')) {
        setCheckApproveUsdbContract(true)
        setUsdbValidateMess('')
      } else {
        setCheckApproveUsdbContract(false)
      }
    } catch (error) {
      console.log('error', error)
      setCheckApproveUsdbContract(false)
    }
  }

  const handleApproveUsdbContract = async () => {
    checkUnsupportChainId(chainId)
    await approveUsdbContract()
    handleCheckApproveUsdbContract()
  }

  // redeem
  const redeemUsdb = async () => {
    setLoadingRedeemUsdb(true)
    setIsOPenModalRedeem(true)
    const formattedWithdrawAmount = ethers.utils.parseUnits(depositeMusdAmount, 'ether').toString()

    try {
      if (account) {
        const gasLimit = await musdContractWithSign?.estimateGas.withdraw(formattedWithdrawAmount)
        const increasedGasLimit = Math.floor(gasLimit ? gasLimit.toNumber() * 1.5 : 250000)
        const tx = await musdContractWithSign?.withdraw(formattedWithdrawAmount, { gasLimit: increasedGasLimit })
        await tx.wait()
        setDepositeMusdAmount('')
        getUserBalance()
        setLoadingRedeemUsdb(false)
      }
    } catch (err) {
      setErrorRedeemUsdb(true)
      if (err?.code == 'UNPREDICTABLE_GAS_LIMIT' || (err?.data?.message && String(err?.data?.message).indexOf('insufficient funds')))
        setErrorMintMusdMess("Insufficient funds for gas")
      else
        setErrorRedeemUsdbMess('Redeem error')
      console.log('error', err)
      setLoadingRedeemUsdb(false)
    }
  }

  const handleValidateRedeem = () => {
    if (depositeMusdAmount == '') { setMusdValidateMess(''); return; }
    const validate = validateInput(depositeMusdAmount, userMusdMaxBalance)
    if (validate === 'minimum') {
      setMusdValidateMess('Minimum amount 10 MUSD.')
      return
    }
    if (validate !== 'valid') {
      setMusdValidateMess(`Input amount must less than ${formatXpNumber(userMusdMaxBalance)} MUSD.`)
      return
    }
    setMusdValidateMess('')
  }

  const handleRedeem = () => {
    checkUnsupportChainId(chainId)
    redeemUsdb()
    setErrorRedeemUsdb(false)
  }

  useEffect(() => {
    getContractInfo()
    if (account) {
      getUserBalance()
    }
  }, [account, chainId])

  useEffect(() => {
    if (account !== null && account !== undefined) {
      handleCheckApproveUsdbContract()
    }
  }, [account, chainId])

  useEffect(() => {
    if (Number(depositeUsdbAmount) > usdbAllowance || usdbAllowance < 10) setCheckApproveUsdbContract(false)
    else setCheckApproveUsdbContract(true)
    handleValidateDeposit()
  }, [depositeUsdbAmount, usdbAllowance])

  useEffect(() => {
    setUsdbValidateMess('')
    setMusdValidateMess('')
  }, [currentTab])

  useEffect(() => {
    handleValidateRedeem()
  }, [depositeMusdAmount])

  const LiveClock = () => {
    const [currentTime, setCurrentTime] = useState(new Date())

    useEffect(() => {
      const timer = setInterval(() => {
        setCurrentTime(new Date())
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }, [])

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const day = days[currentTime.getDay()]
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0')
    const year = currentTime.getFullYear()
    const hours = currentTime.getHours()
    const minutes = currentTime.getMinutes().toString().padStart(2, '0')
    const seconds = currentTime.getSeconds().toString().padStart(2, '0')
    const ampm = hours >= 12 ? 'PM' : 'AM'
    const formattedHours = hours % 12 || 12

    return <>{`${day}, ${month}/${currentTime.getDate()}/${year}, ${formattedHours}:${minutes}:${seconds} ${ampm}`}</>
  }
  // chart and history
  const [musdStats, setMusdStats] = useState<MusdStatType[]>([])
  const [supplyMusdStats, setSupplyMusdStats] = useState<MusdStatType[]>([])
  const [timePeriod, setTimePeriod] = useState<TimePeriodType>('HOUR')
  const [supplyTimePeriod, setSuppplyTimePeriod] = useState<TimePeriodType>('HOUR')

  let isLoadingMusdStat = false
  const getMusdStats = (period: TimePeriodType) => {
    if (isLoadingMusdStat) return
    isLoadingMusdStat = true
    setTimePeriod(period)

    axios
      .get(`${BASE_API}/monoswap/api/v1/musd/get-stats-timeperiod?timeperiod=${period}`)
      .then(({ data }: { data: MusdStatType[] }) => {
        setMusdStats(data)
      })
      .finally(() => (isLoadingMusdStat = false))
  }

  let isLoadingSupplyMusdStat = false
  const getSupplyMusdStats = (period: TimePeriodType) => {
    if (isLoadingSupplyMusdStat) return
    isLoadingSupplyMusdStat = true
    setSuppplyTimePeriod(period)

    axios
      .get(`${BASE_API}/monoswap/api/v1/musd/get-stats-timeperiod?timeperiod=${period}`)
      .then(({ data }: { data: MusdStatType[] }) => {
        setSupplyMusdStats(data)
      })
      .finally(() => (isLoadingSupplyMusdStat = false))
  }
  // add custome token
  const handleAddCustomToken: HandleAddCustomTokenFunc = async ({
    contractAddress,
    symbol,
    decimals,
    tokenLogoURL,
  }) => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: contractAddress,
              symbol: symbol,
              decimals: decimals,
              image: tokenLogoURL,
            },
          },
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  function formatDate(dateString: any) {
    const date = new Date(dateString)
    const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
    return formattedDate
  }

  const getMusdHistoryIcon = (type: MusdHistoryEnumType) => {
    switch (type) {
      case 'MINT':
        return 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/musd/mint.png'
      case 'REDEEM':
        return 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/musd/redeem.png'
      case 'ADD_COL':
        return 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/musd/add.png'
      default:
        return ''
    }
  }
  const getMusdHistoryTypeName = (type: MusdHistoryEnumType) => {
    switch (type) {
      case 'MINT':
        return 'Mint MUSD'
      case 'REDEEM':
        return 'Burn MUSD'
      case 'ADD_COL':
        return 'Add Collateral'
      default:
        return ''
    }
  }
  async function getHistory(page: number, type: MusdHistoryEnumType) {
    if (loadingHistory) return
    setCurrentPage(page)
    setHistoryFilterType(type)
    setLoadingHistory(true)
    axios
      .get(`${BASE_API}/monoswap/api/v1/musd/get-history?page=${page - 1}&type=${type}`)
      .then(({ data }: { data: MusdHistoryPageType }) => {
        setPaginatedData(data.data)
        setTotalElement(data.totalElement)
        setTotalPages(Math.ceil(data.totalElement / 5))
      })
      .finally(() => setLoadingHistory(false))
  }
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalElement, setTotalElement] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [historyFilterType, setHistoryFilterType] = useState<MusdHistoryEnumType>('ALL')
  const [paginatedData, setPaginatedData] = useState<MusdHistoryType[]>([])

  const onChangePage = (page: number) => {
    getHistory(page, historyFilterType)
  }

  function truncateString(str: any, length: any) {
    if (str.length > length) {
      return str.slice(0, length - 4) + '...' + str.slice(-4)
    } else {
      return str
    }
  }

  useEffect(() => {
    getHistory(1, 'ALL')
    getMusdStats('HOUR')
    getSupplyMusdStats('HOUR')
  }, [])
  return (
    <>
      <MintMusdModal
        isOpen={isPenModalMint}
        loading={loadingMintMusd}
        onDismiss={() => setIsPenModalMint(false)}
        errorMintMusd={errorMintMusd}
        errMess={errorMintMusdMess}
        usdbValue={depositeUsdbAmount}
      />
      <RedeemMusdModal
        isOpen={isOPenModalRedeem}
        loading={loadingRedeemUsdb}
        onDismiss={() => setIsOPenModalRedeem(false)}
        errorMintMusd={errorRedeemUsdb}
        errMess={errorRedeemUsdbMess}
        usdbValue={depositeMusdAmount}
      />
      <div className="musd-container">
        <div className="swap-top-container-musd">
          <div className="swap-top-left-musd">
            <div className="swap-top-div-musd" style={{ justifyContent: 'flex-start' }}>
              <p>
                Earn 25% real yield + Blast points <br /> with <span className="musd-container-title">MUSD</span>{' '}
              </p>
              <p className="swap-top-div-p">More than just a stablecoin</p>
            </div>
          </div>
          <div className="swap-top-container-img">
            <img src={topImage} alt="swap image" />
          </div>
        </div>
      </div>

      {/* chart + swap */}
      <div className="chart-musd-bg">
        <div className="musd-chart-container">
          <div className="musd-chart">
            <div className="musd-chart-top">
              <div className="musd-chart-top-left">
                <div className="musd-chart-top-img">
                  <img src={musdlogo} alt="pair logo" width={'100%'} />
                </div>
                <div className="musd-chart-top-pair">
                  <p>
                    1 MUSD = {loadingBalance ? <CustomLightSpinner src={Circle} alt="loader" size="22px" /> : musdPrice}{' '}
                    USDB
                  </p>
                </div>
              </div>

              <div className="musd-chart-top-increase">
                <img src={increase} alt="increase" />
                <p style={{ margin: '0px', fontSize: '14px' }}>
                  {loadingBalance ? (
                    <CustomLightSpinner src={Circle} alt="loader" size="16px" />
                  ) : (
                    <>
                      ${Number(Number(musdPrice) - 1).toFixed(5)} ({Number(100 * (Number(musdPrice) - 1)).toFixed(2)}%)
                    </>
                  )}
                </p>
              </div>
            </div>
            <div className="musd-chart-mid">
              <div className="musd-chart-mid-time">
                {/* <p>Thu, 02/14/2024, 10:15:00 PM</p> */}
                <p>
                  <LiveClock />
                </p>
              </div>
              <div className="musd-chart-mid-select">
                <p className={timePeriod == 'HOUR' ? 'actived' : ''} onClick={() => getMusdStats('HOUR')}>
                  1D
                </p>
                <p className={timePeriod == 'WEEK' ? 'actived' : ''} onClick={() => getMusdStats('WEEK')}>
                  1W
                </p>
                <p className={timePeriod == '1MONTH' ? 'actived' : ''} onClick={() => getMusdStats('1MONTH')}>
                  1M
                </p>
                <p className={timePeriod == 'MONTH' ? 'actived' : ''} onClick={() => getMusdStats('MONTH')}>
                  3M
                </p>
              </div>
            </div>
            <div className="musd-chart-chart" style={{ height: '420px' }}>
              <MusdPriceChart musdStats={musdStats} timePeriod={timePeriod} currentPrice={Number(musdPrice ?? 0)} />
            </div>
          </div>
          <div className="musd-swap">
            <div className="musd-swap-tab">
              <div
                className={`musd-swap-tab-p ${currentTab === 1 ? 'musd-tab-selected' : ''}`}
                onClick={() => setCurrentTab(1)}
              >
                <p>Mint</p>
              </div>
              <div
                className={`musd-swap-tab-p ${currentTab === 2 ? 'musd-tab-selected' : ''}`}
                onClick={() => setCurrentTab(2)}
              >
                <p>Redeem</p>
              </div>
            </div>
            <div className="musd-swap-bot-container">
              <div className="musd-swap-input">
                {currentTab === 1 && (
                  <div>
                    <div className="musd-swap-input-title-container">
                      <p className="musd-swap-input-title">Get MUSD</p>
                      <div className="musd-swap-input-title-add">
                        <div onClick={() => handleAddCustomToken(TOKEN_ARRAY['MUSD'])} style={{ cursor: 'pointer' }}>
                          <MouseoverTooltip text={<span>Add MUSD to your wallet </span>} placement="bottom">
                            <PlusSquare size="24" color="#68FFFF" />
                          </MouseoverTooltip>
                        </div>
                      </div>
                    </div>

                    <div className="musd-swap-input-usdb">
                      <div className="musd-swap-input-usdb-text">
                        <img src={usdbIcon} alt="" />
                        <p>USDB</p>
                      </div>
                      <input
                        type="number"
                        placeholder="0.00"
                        value={depositeUsdbAmount}
                        onChange={(e) => setDepositeUsdbAmount(e.target.value)}
                      />
                    </div>
                    <div className="musd-swap-input-balance">
                      <div style={{ color: '#ff1e56' }}>{usdbValidateMess && usdbValidateMess}</div>

                      <div className="musd-swap-input-balance-left">
                        <div
                          className="musd-max-button"
                          onClick={() => {
                            setDepositeUsdbAmount(userUsdbMaxBalance)
                          }}
                        >
                          <p>MAX</p>
                        </div>
                        <div className="musd-input-balance">
                          <p style={{ color: '#efefe4' }}>
                            {loadingBalance ? (
                              <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                            ) : formatXpNumber(userUsdbBalance)}{' '}
                            USDB
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="musd-swap-input-usdb">
                      <div className="musd-swap-input-usdb-text">
                        <img src={musdIcon} alt="musd" />
                        <p>MUSD</p>
                      </div>
                      <input
                        type="text"
                        placeholder="0.00"
                        style={{ color: 'rgba(239,239,228,0.5)' }}
                        disabled
                        value={musdPrice ? Number(Number(depositeUsdbAmount) / Number(musdPrice)).toFixed(2) : '0.00'}
                      />
                    </div>
                    <div className="musd-swap-input-balance">
                      <div className="musd-input-limit">
                        {/* <p>Mint limit: </p> <span>10.000 MUSD per address</span> */}
                      </div>
                      <div className="musd-input-balance">
                        <p>
                          Balance:{' '}
                          <span>
                            {loadingBalance ? (
                              <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                            ) : formatXpNumber(userMusdBalance)}{' '}
                            MUSD
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {currentTab === 2 && (
                  <div>
                    <div className="musd-swap-input-title-container">
                      <p className="musd-swap-input-title">Redeem USDB</p>
                      <div className="musd-swap-input-title-add">
                        <div onClick={() => handleAddCustomToken(TOKEN_ARRAY['USDB'])} style={{ cursor: 'pointer' }}>
                          <MouseoverTooltip text={<span>Add USDB to your wallet </span>} placement="bottom">
                            <PlusSquare size="24" color="#68FFFF" />
                          </MouseoverTooltip>
                        </div>
                      </div>
                    </div>
                    <div className="musd-swap-input-usdb">
                      <div className="musd-swap-input-usdb-text">
                        <img src={musdIcon} alt="musd" />
                        <p>MUSD</p>
                      </div>
                      <input
                        type="text"
                        placeholder="0.00"
                        value={depositeMusdAmount}
                        onChange={(e) => setDepositeMusdAmount(e.target.value)}
                      />
                    </div>
                    <div className="musd-swap-input-balance">
                      <div style={{ color: '#ff1e56' }}>{musdValidateMess && musdValidateMess}</div>

                      <div className="musd-swap-input-balance-left">
                        <div
                          className="musd-max-button"
                          onClick={() => {
                            setDepositeMusdAmount(userMusdMaxBalance)
                          }}
                        >
                          <p>MAX</p>
                        </div>
                        <div className="musd-input-balance">
                          <p>
                            Balance:{' '}
                            <span>
                              {loadingBalance ? (
                                <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                              ) : formatXpNumber(userMusdBalance)}{' '}
                              MUSD
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="musd-swap-input-usdb">
                      <div className="musd-swap-input-usdb-text">
                        <img src={usdbIcon} alt="" />
                        <p>USDB</p>
                      </div>
                      <input
                        type="text"
                        placeholder="0.00"
                        style={{ color: 'rgba(239,239,228,0.5)' }}
                        disabled
                        value={musdPrice ? Number(Number(depositeMusdAmount) * Number(musdPrice)).toFixed(2) : '0.00'}
                      />
                    </div>
                    <div className="musd-swap-input-balance">
                      <div className="musd-input-limit"></div>
                      <div className="musd-input-balance">
                        <p style={{ color: '#efefe4' }}>
                          {loadingBalance ? (
                            <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                          ) : formatXpNumber(userUsdbBalance)}{' '}
                          USDB
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="musd-swap-loadbar">
                <MintingCapUserBar value={24} total={56} isFull={false} />
                <MintingCapTotal value={15} total={100} isFull={false} />
              </div> */}
              <div className="musd-connectwallet">
                {!account ? (
                  <NotchedButtonFill onClick={toggleWalletModal} width={'100%'}>
                    CONNECT WALLET
                  </NotchedButtonFill>
                ) : (
                  <>
                    {currentTab === 1 &&
                      (checkApproveUsdbContract ? (
                        <NotchedButtonFill onClick={handleDeposite} width={'100%'} disabled={depositeUsdbAmount == '' || usdbValidateMess != '' || Number(depositeUsdbAmount) > usdbAllowance}>
                          {loadingMintMusd ? 'Minting...' : 'Mint'}
                        </NotchedButtonFill>
                      ) : (
                        <NotchedButtonFill onClick={handleApproveUsdbContract} width={'100%'}>
                          {loadingApproveUsdbContract ? 'Approving...' : 'Approve'}
                        </NotchedButtonFill>
                      ))}
                    {currentTab === 2 && (
                      <NotchedButtonFill onClick={handleRedeem} width={'100%'} disabled={musdValidateMess != ''}>
                        {loadingRedeemUsdb ? 'Loading...' : 'Redeem'}
                      </NotchedButtonFill>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* supply */}
      <div className="musd-supply-container">
        <div className="musd-supply">
          <div className="musd-supply-top">
            <div className="musd-supply-top-card-container">
              <div className="musd-supply-top-card" style={{ color: '#68FFFF' }}>
                <div className="musd-supply-top-card-small">
                  <img src={circular} alt="img" />
                  CIRCULATING SUPPLY
                </div>
                <p style={{ color: '#efefe4' }}>
                  {loadingBalance ? (
                    <CustomLightSpinner src={Circle} alt="loader" size="22px" />
                  ) : Number(circulating) < 1000 ? (
                    circulating
                  ) : (
                    numberWithCommas(circulating)
                  )}{' '}
                  MUSD
                </p>
              </div>
              <div className="musd-supply-top-card" style={{ color: '#68FF9B' }}>
                <div className="musd-supply-top-card-small">
                  <img src={colartetal} alt="img" />
                  COLLATERAL
                </div>
                <p style={{ color: '#efefe4' }}>
                  {loadingBalance ? (
                    <CustomLightSpinner src={Circle} alt="loader" size="22px" />
                  ) : Number(collateral) < 1000 ? (
                    collateral
                  ) : (
                    numberWithCommas(collateral)
                  )}{' '}
                  USDB
                </p>
              </div>
            </div>
            <div className="musd-chart-mid-select">
              <p className={supplyTimePeriod == 'HOUR' ? 'actived' : ''} onClick={() => getSupplyMusdStats('HOUR')}>
                1D
              </p>
              <p className={supplyTimePeriod == 'WEEK' ? 'actived' : ''} onClick={() => getSupplyMusdStats('WEEK')}>
                1W
              </p>
              <p className={supplyTimePeriod == '1MONTH' ? 'actived' : ''} onClick={() => getSupplyMusdStats('1MONTH')}>
                1M
              </p>
              <p className={supplyTimePeriod == 'MONTH' ? 'actived' : ''} onClick={() => getSupplyMusdStats('MONTH')}>
                3M
              </p>
            </div>
          </div>
          <div className="musd-supply-chart">
            <CollateralChart musdStats={supplyMusdStats} timePeriod={supplyTimePeriod} />
          </div>
          <div style={{ display: 'grid', justifyContent: 'end', marginTop: '10px' }}>
            <div className="musd-outlink">
              <p style={{ color: '#efefe4', margin: 0, fontSize: '15px' }}>Visit the Treasury</p>
              <ExternalLink
                href="https://blastscan.io/txs?a=0x837fE561e9C5DFa73F607fDa679295DBC2Be5E40"
                target="_blank"
              >
                <img src={outlink} alt="img" />
              </ExternalLink>
            </div>

            <div className="musd-outlink">
              <p style={{ color: '#efefe4', margin: 0, fontSize: '15px' }}>MUSD Contract</p>
              <ExternalLink
                href="https://blastscan.io/token/0x837fE561e9C5DFa73F607fDa679295DBC2Be5E40"
                target="_blank"
              >
                <img src={outlink} alt="img" />
              </ExternalLink>
            </div>
          </div>
        </div>
      </div>
      {/* History */}
      <div className="musd-history-container">
        <div className="musd-history">
          <div className="musd-history-top">
            <p className="musd-history-top-title">History</p>
            <div
              className="musd-history-top-filter"
              style={{ justifyContent: 'flex-end', alignItems: 'flex-start', gap: 24, display: 'inline-flex' }}
            >
              <span
                style={{ color: historyFilterType == 'ALL' ? '#FFF' : 'rgba(239, 239, 228, 0.50)' }}
                onClick={() => getHistory(1, 'ALL')}
              >
                ALL
              </span>
              <span
                style={{ color: historyFilterType == 'REDEEM' ? '#FFF' : 'rgba(239, 239, 228, 0.50)' }}
                onClick={() => getHistory(1, 'REDEEM')}
              >
                REDEEM
              </span>
              <span
                style={{ color: historyFilterType == 'MINT' ? '#FFF' : 'rgba(239, 239, 228, 0.50)' }}
                onClick={() => getHistory(1, 'MINT')}
              >
                MINT
              </span>
              <span
                style={{ color: historyFilterType == 'ADD_COL' ? '#FFF' : 'rgba(239, 239, 228, 0.50)' }}
                onClick={() => getHistory(1, 'ADD_COL')}
              >
                ADD COLLATERAL
              </span>
            </div>
          </div>
          <div className="musd-history-detail">
            {loadingHistory ? (
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <CustomLightSpinner src={Circle} alt="loader" size="90px" />
              </div>
            ) : paginatedData.length > 0 ? (
              paginatedData?.map((item, index) => (
                <div className="musd-history-item" key={index + item?.createdAt}>
                  <div className="musd-history-item-title">
                    <img style={{ width: '24px', height: '24px' }} src={getMusdHistoryIcon(item.musdType)} alt="add" />
                    <p>{getMusdHistoryTypeName(item.musdType)}</p>
                  </div>
                  <p
                    className={`history-musd-text-color ${item.musdType === 'REDEEM' ? 'history-musd-text-color-withdraw' : ''
                      }`}
                  >
                    <>
                      {(item.musdType == 'ADD_COL' || item.musdType == 'MINT') && <>+</>}
                      {item.musdType == 'REDEEM' && <>-</>}
                      {formatXpNumber(Math.abs(item.amount), 3)}
                      {item.musdType == 'ADD_COL' ? ' USDB' : ' MUSD'}
                    </>
                  </p>
                  <p className="musd-history-item-created">{item.createdAt}</p>
                  <ExternalLink
                    href={getExplorerLink(
                      chainId ? SupportedChainId.BLAST : SupportedChainId.BLAST,
                      item.hash,
                      ExplorerDataType.TRANSACTION
                    )}
                    target="_blank"
                  >
                    <img src={outlink} alt="img" />
                  </ExternalLink>
                </div>
              ))
            ) : (
              ''
            )}
          </div>
          <div className="musd-history-paginate my-claimed-xp">
            {paginatedData?.length > 0 && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                  current={currentPage}
                  total={totalElement}
                  pageSize={5}
                  itemRender={PrevNextRender}
                  showSizeChanger={false}
                  onChange={onChangePage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* uncover */}
      <div className="uncover-bg">
        <div className="musd-uncover-container">
          <div className="musd-uncover-title">
            <p>
              Uncover the <span>MUSD</span> Magic
            </p>
          </div>
          {/* https://docs.monoswap.io/protocol/musd */}

          <ExternalLink href={'https://docs.monoswap.io/protocol/musd'} target="_blank">
            <p className="musd-uncover-subtitle"> SEE DOCUMENT </p>
          </ExternalLink>

          <div className="musd-uncover-card-container">
            <div className="musd-uncover-card">
              <div className="musd-uncover-card-title">
                <p>Price Grows Permanently</p>
              </div>
              <div className="musd-uncover-card-subtitle">
                <p>Next time you get MUSD, its price will definitely be higher</p>
              </div>
              <div className="musd-uncover-card-img-container">
                <div className="musd-uncover-card-img">
                  <img src={pricegrow} alt="img" width={'100%'} />
                </div>
              </div>
            </div>
            <div className="musd-uncover-card">
              <div className="musd-uncover-card-title">
                <p>Native Yield at Its Finest</p>
              </div>
              <div className="musd-uncover-card-subtitle">
                <p>MUSD always rises, thanks to the Native Yield from Blast and protocol fees from MonoSwap</p>
              </div>
              <div className="musd-uncover-card-img-container">
                <div className="musd-uncover-card-img">
                  <img src={nativeyeild} alt="img" width={'100%'} />
                </div>
              </div>
            </div>
            <div className="musd-uncover-card">
              <div className="musd-uncover-card-title">
                <p>No DE-PEG Policy</p>
              </div>
              <div className="musd-uncover-card-subtitle">
                <p>Our MUSD carousel is over-collateralized, fully backed by the auto-rebasing USDB</p>
              </div>
              <div className="musd-uncover-card-img-container">
                <div className="musd-uncover-card-img">
                  <img src={nodepeg} alt="img" width={'100%'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MusdPage

const MintingCapUserBar = ({ value, total, isFull }: any) => { }
export const numberWithCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
