import '../../Xp/sections/leaderboard.css'

import { useWeb3React } from '@web3-react/core';
import { Tooltip } from 'antd';
import { formatXpNumber } from 'pages/Xp/sections/XpTitle';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { BREAKPOINTS } from 'theme';
import { shortenAddress } from 'utils';


const TradingContestTableWrapper = styled.div`
    width: 100%;
    .rank{
        width: 100px !important;
        @media screen and (max-width: ${BREAKPOINTS.lg}px) {
            width: 80px !important;
        }
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            width: 40px !important;
            padding-left: 5px !important;
        }
    }

    .table_rw_label{
        min-width: 160px;
        @media screen and (max-width: ${BREAKPOINTS.md}px) {
            min-width: 100px !important;
        }
        @media screen and (max-width: ${BREAKPOINTS.xs}px) {
            min-width: 80px !important;
        }
    }
    
`
const TheadLabel = styled.p`
  color: #EFEFE4;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 17.5px */
  text-transform: uppercase;
  padding: 18px 0;
  margin: 0;
  text-align: left;
  span {
    color: #EFEFE4;
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 17.5px */
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 10px;
    line-height: 125%;
  }
`;

const Text = styled.p`
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 10px;
    line-height: 125%;
  }
`;

const RankText = styled.div`
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 22.5px */
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 10px;
    line-height: 125%;
  }
`;

const RewardColBox = styled.div`
  display: flex;
  gap: 8px;
  justify-content: start;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: grid;
    padding: 8px 0;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    max-width: 120px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 1px;
        height: 1px;
    }
  }
`;
const RewardColBoxRow = styled.div`
  display: flex;
  gap: 8px;
  justify-content: start;
`;
const RewardColBoxItem = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 10px;
    line-height: 125%;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    min-width: 45px;
  }
`;

const RewardColSeperator = styled.span<{
    hiddenOnMobile?: boolean
}>`
  background: #ffffff57;
  width: 2px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: ${({ hiddenOnMobile }) => hiddenOnMobile ? 'none' : ''};
  }
`;

const RankTd = styled.td`
  /* margin-left: 20px; */
  min-width: 38px;
  
  @media screen and (max-width: ${BREAKPOINTS.xs}px) {
    width: 48px;
  }
`

export default function TradingContestTable({ dataList }: any) {
    const { account } = useWeb3React();

    const [isScreenLt600, setIsScreenLt600] = useState<boolean>(false)
    const [lastCharCnt, setLastCharCnt] = useState<number>(4);

    const truncateAddress = (address?: string, lastCharCnt = 4) => {
        try {
            return shortenAddress(address ?? '', lastCharCnt)
        }
        catch (err) {
            return 'undefined';
        }
    }

    useEffect(() => {
        if (window.screen.width < 600)
            setLastCharCnt(2);
        setIsScreenLt600(window.screen.width <= 600)

    }, [])
    return (
        <TradingContestTableWrapper className="my-claimed-xp">
            <div className="paginate-table" style={{ fontFamily: 'Urbanist', overflow: 'auto', maxHeight: '648px', marginTop: '6px' }}>
                <table style={{ position: 'relative', width: '100%' }}>
                    <thead>
                        <tr >
                            <th >
                                <TheadLabel className='rank' style={{ paddingLeft: isScreenLt600 ? '10px' : '20px' }}>
                                    RANK
                                </TheadLabel>
                            </th>
                            <th>
                                <TheadLabel>
                                    TRADER
                                </TheadLabel>
                            </th>
                            <th>
                                <TheadLabel>
                                    VOLUME
                                </TheadLabel>
                            </th>
                            <th >
                                <TheadLabel>
                                    TRADING POINT
                                </TheadLabel>
                            </th>
                            <th>
                                <TheadLabel className='table_rw_label'>
                                    {
                                        account ? 'NEXT REWARDS' : 'NEXT REWARDS'
                                    }
                                </TheadLabel>
                            </th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: 'auto' }}>
                        {
                            dataList &&
                            <tr style={{ background: '#80808029', borderBottom: 'unset' }}>
                                <RankTd >
                                    <Text style={{ paddingLeft: isScreenLt600 ? '10px' : '20px' }}>
                                        #{dataList.myTop?.rank > 0 ? dataList.myTop?.rank : '~'}
                                    </Text>
                                </RankTd>

                                <td >
                                    <Text>  {account && truncateAddress(account, lastCharCnt)} (You)</Text>
                                </td>
                                <td>
                                    <Text> ${formatXpNumber(dataList?.myTop?.volume)}</Text>
                                </td>
                                <td>
                                    <Text> {formatXpNumber(dataList?.myTop?.tradingPoint)}</Text>
                                </td>
                                <td className='table_rw_label'>
                                    <RewardColBox >
                                        <RewardColBoxRow>
                                            <RewardColBoxItem>
                                                <span>{formatXpNumber(dataList?.myTop?.reward?.musd)}</span>
                                                <Tooltip title="MUSD">
                                                    <img style={{ cursor: 'pointer', width: '18px', height: '18px' }} alt="monoswap" src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musd.svg' />
                                                </Tooltip>
                                            </RewardColBoxItem>
                                            <RewardColSeperator />
                                            <RewardColBoxItem>
                                                <span>{formatXpNumber(dataList?.myTop?.reward?.point ?? 0)}</span>
                                                <Tooltip title="Blast Point">
                                                    <img style={{ cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)' }} alt="monoswap" src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chain-logo/blast.png' />
                                                </Tooltip>
                                            </RewardColBoxItem>
                                            <RewardColSeperator hiddenOnMobile={true} />
                                        </RewardColBoxRow>

                                        <RewardColBoxRow>
                                            <RewardColBoxItem>
                                                <span>{formatXpNumber(dataList?.myTop?.reward?.goldPoint ?? 0)}</span>
                                                <Tooltip title="Blast Gold">
                                                    <img style={{ cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)' }} alt="monoswap" src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/blast-gold-icon.png' />
                                                </Tooltip>
                                            </RewardColBoxItem>
                                        </RewardColBoxRow>
                                    </RewardColBox>
                                </td>
                            </tr>
                        }

                        {(dataList?.list ?? []).filter((i: { address: string | undefined; }) => i.address !== account?.toLowerCase()).map((row: any, index: number) => (
                            <tr key={index}>
                                <td >
                                    <Text className='rank' style={{ paddingLeft: isScreenLt600 ? '10px' : '20px' }}>
                                        <RankText style={{ fontWeight: row?.rank <= 3 ? 700 : 500 }}>
                                            #{row?.rank}
                                        </RankText>
                                    </Text>
                                </td>
                                <td>
                                    <Text style={{ fontWeight: row?.rank <= 3 ? 700 : 500 }}> {truncateAddress(row?.address, lastCharCnt)}</Text>
                                </td>
                                <td>
                                    <Text style={{ fontWeight: row?.rank <= 3 ? 700 : 500 }}> ${formatXpNumber(row?.volume)}</Text>
                                </td>
                                <td>
                                    <Text style={{ fontWeight: row?.rank <= 3 ? 700 : 500 }}> {formatXpNumber(row?.tradingPoint)}</Text>
                                </td>
                                <td style={{ width: isScreenLt600 ? '120px' : '203px' }}>
                                    <RewardColBox >
                                        {
                                            <RewardColBoxRow>
                                                <RewardColBoxItem style={{ fontWeight: row?.rank <= 3 ? 700 : 500 }}>
                                                    <span>{formatXpNumber(row?.reward?.musd ?? 0)}</span>
                                                    <Tooltip title="MUSD">
                                                        <img style={{ cursor: 'pointer', width: '18px', height: '18px' }} alt="monoswap" src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musd.svg' />
                                                    </Tooltip>
                                                </RewardColBoxItem>
                                                <RewardColSeperator />
                                                <RewardColBoxItem style={{ fontWeight: row?.rank <= 3 ? 700 : 500 }}>
                                                    <span>{formatXpNumber(Number(row?.reward?.point ?? 0).toFixed(2))}</span>
                                                    <Tooltip title="Blast Point">
                                                        <img style={{ cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)' }} alt="monoswap" src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chain-logo/blast.png' />
                                                    </Tooltip>
                                                </RewardColBoxItem>
                                                <RewardColSeperator hiddenOnMobile={true} />
                                            </RewardColBoxRow>
                                        }
                                        <RewardColBoxRow>
                                            <RewardColBoxItem style={{ fontWeight: row?.rank <= 3 ? 700 : 500 }}>
                                                <span>{formatXpNumber(Number(row?.reward?.goldPoint ?? 0).toFixed(2))}</span>
                                                <Tooltip title="Blast Gold">
                                                    <img style={{ cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)' }} alt="monoswap" src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/blast-gold-icon.png' />
                                                </Tooltip>
                                            </RewardColBoxItem>
                                        </RewardColBoxRow>
                                    </RewardColBox>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </TradingContestTableWrapper>

    )
}