import styled from "styled-components"
import { BREAKPOINTS, ExternalLink } from 'theme'
import {
  ANDY_BLAST,
  BAJA_BLAST,
  ezETH_BLAST,
  JUICE_BLAST,
  KALAX_BLAST,
  MUSD_BLAST,
  OLE_BLAST,
  ORBIT_BLAST,
  PAC_BLAST,
  USDB_BLAST,
  YIELD_BLAST,
  ZKDX_BLAST
} from '../../../constants/tokens'


const EligibleTradeSectionWrapper = styled.div`
    position: relative;
    z-index: 2;
    max-width: 1224px;
    margin: auto;
    margin-top: 163px;
    display: grid;
    gap: 50px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        max-width: 90%;
        margin-top: 56px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        gap: 20px;
    }
`

const Img = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const LinearText = styled.span`
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const Text = styled.span<{
    color?: string;
    fontSize?: string;
}>`
    color: ${({ color }) => color ?? 'rgba(239, 239, 228, 0.50)'};
    font-family: Urbanist;
    font-size: ${({ fontSize }) => fontSize ?? '16px'};;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 20px */
`

const InfoBox = styled.div`
    width: 100%;
    display: grid;
    justify-content: start;
    gap: 22px;
`;

const Title = styled.h1`
    color: #EFEFE4;
    font-family: Righteous;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 60px */
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        justify-content: center;
        font-size: 32px;
        line-height: 40px;
    }
`;

const TradeTokenListWraper = styled.div`
    display: flex;
    gap: 40px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        flex-wrap: wrap;
        gap: 20px;
    }
`
const TradeTokenList = styled.div`
    display: flex;
    gap: 16px;
    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        flex-wrap: wrap;
    }
`

const TradeTokenBoxWrapper = styled.div`    
    display: grid;
    gap: 24px;
    justify-items: center;
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        .vol_mul{
            display: none !important;
        }
    }
`
const TradeTokenBox = styled.div`
    position: relative;
    width: 69.077px;
    height: 69.077px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        max-width: 50px;
        max-height: 50px;
    }
`
const TradeTokenImg = styled.img` 
    position: relative;
    z-index: 2;
    width: 66.6%;
    height: 66.6%;
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        width: 66.6%;
        height: 66.6%;
    }
`
export default function EligibleTradeSection() {
    const TRADE_TOKEN_LIST = [
        {
            img: 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/trade-tokens/eth.png',
            multiplier: '1x',
            address: 'ETH'
        },
        {
            img: 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/trade-tokens/usdb-icon.png',
            multiplier: '1x',
            address: USDB_BLAST.address
        },
        {
            img: 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/trade-tokens/musd.png',
            multiplier: '1.5x',
            address: MUSD_BLAST.address
        },
        {
            img: 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/trade-tokens/ezeth.png',
            multiplier: '1.5x',
            address: ezETH_BLAST.address
        },
        {
            img: 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/trade-tokens/pac.png',
            multiplier: '2x',
            address: PAC_BLAST.address
        },
        {
            img: 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/trade-tokens/andy.png',
            multiplier: '2x',
            address: ANDY_BLAST.address
        },
        {
            img: 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/trade-tokens/baja.png',
            multiplier: '2.5x',
            address: BAJA_BLAST.address
        },
        {
            img: 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/trade-tokens/juice.png',
            multiplier: '2.5x',
            address: JUICE_BLAST.address
        },
        {
            img: 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/trade-tokens/zkdx.png',
            multiplier: '2.5x',
            address: ZKDX_BLAST.address
        },
        {
            img: 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/trade-tokens/orbit.png',
            multiplier: '2.5x',
            address: ORBIT_BLAST.address
        },
        {
            img: 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/trade-tokens/yield.png',
            multiplier: '2.5x',
            address: YIELD_BLAST.address
        },
        {
            img: 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/trade-tokens/ole.png',
            multiplier: '2.5x',
            address: OLE_BLAST.address
        },
        {
            img: 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/trade-tokens/hype.png',
            multiplier: '2.5x',
            address: ""
        } ,
        {
            img: 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/trade-tokens/kala.png',
            multiplier: '2.5x',
            address: KALAX_BLAST.address
        } 
    ]

    const swapLink = (address: string) => {
      if (address === "ETH") {
        return `https://monoswap.io/#/swap?inputCurrency=0x4300000000000000000000000000000000000003&outputCurrency=ETH&chainName=blast`
      } else {
        return `https://monoswap.io/#/swap?inputCurrency=ETH&outputCurrency=${address}&chainName=blast`
      }
    }

    return <EligibleTradeSectionWrapper>

        <InfoBox>
            <Title>
                <LinearText>Eligible</LinearText> Trades
            </Title>
            <Text color="#EFEFE4">
                Different tokens carry different trading weight. Mark your name in the Leaderboard to make most of it
            </Text>
        </InfoBox>
        <TradeTokenListWraper>
            <TradeTokenBoxWrapper style={{ justifyItems: 'flex-start' }}>
                <TradeTokenBox style={{ justifyContent: 'flex-start' }}>
                    <Text>Tokens</Text>
                </TradeTokenBox>
                <Text className="vol_mul" style={{ display: 'flex', alignItems: 'end' }}>Vol Multiplier</Text>
            </TradeTokenBoxWrapper>

            <TradeTokenList>
                {
                    TRADE_TOKEN_LIST.map((item, index) => <TradeTokenBoxWrapper key={index}>
                        <TradeTokenBox>
                          <ExternalLink href={swapLink(item.address)} target="_blank">
                          <Img src={item.img} />
                          </ExternalLink>
                        </TradeTokenBox>
                        <Text>
                            <LinearText>{item.multiplier}</LinearText>
                        </Text>
                    </TradeTokenBoxWrapper>)
                }


            </TradeTokenList>


        </TradeTokenListWraper>

    </EligibleTradeSectionWrapper>
}