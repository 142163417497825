const currencyFormater = new Intl.NumberFormat("en-US", {
    style: "currency",
    notation: "compact",
    currency: "USD",
});

type PrizePoolType = {
    index: number;
    min: number;
    totalPoint: string;
    totalGoldPoint: number;
    totalMusd: number;
}

const PrizePoolMap: Map<number, PrizePoolType> = new Map()
PrizePoolMap.set(0, {
    index: 0,
    min: 5,
    totalPoint: '100k',
    totalGoldPoint: 100,
    totalMusd: 1000,
})

const MinVolStart = 50;
const MusdStart = 10000;
const PointStart = 1 * Math.pow(10, 6);
const GoldPointStart = 1000;

for (let index = 1; index <= 10; index++) {
    PrizePoolMap.set(MinVolStart * index, {
        index,
        min: MinVolStart * index,
        totalMusd: MusdStart * index,
        totalPoint: currencyFormater.format(PointStart * index).replace('$', ''),
        totalGoldPoint: GoldPointStart * index
    })
}




export default PrizePoolMap