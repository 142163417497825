import { useWeb3React } from "@web3-react/core";
import { Tooltip } from "antd";
import ColorBorderContentBox from "pages/MonoPot/components/ColorBorderContentBox";
import { formatXpNumber } from "pages/Xp/sections/XpTitle";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro"
import { BREAKPOINTS } from "theme";
import PrizePoolMap from "./RewardSetting";


const UnlockRewardSectionWrapper = styled.div`
    position: relative;
    z-index: 2;
    max-width: 1224px;
    margin: auto;
    margin-top: 103px;
    display: grid;
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        max-width: 90%;
        margin-top: 56px;
        padding-bottom: 30px;
    }
`
const LinearText = styled.span`
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const Text = styled.span<{
    color?: string;
    fontSize?: string;
}>`
    color: ${({ color }) => color ?? 'rgba(239, 239, 228, 0.50)'};
    font-family: Urbanist;
    font-size: ${({ fontSize }) => fontSize ?? '16px'};;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 24px */
`

const InfoBox = styled.div`
    width: 100%;
    display: grid;
    justify-content: start;
    gap: 22px;
`;

const Title = styled.h1`
    color: #EFEFE4;
    font-family: Righteous;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 60px */
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        justify-content: center;
        font-size: 32px;
        line-height: 40px;
    }
`;

const PrizePoolWrapper = styled.div`
    width: 100%;
    height: 248px;
    display: grid;
    gap: 40px;
    padding: 40px;
    background: rgba(255, 255, 255, 0.05);
    clip-path: polygon(35px 0, 100% 0, 100% calc(100% - 35px), calc(100% - 35px) 100%, 0 100%, 0 35px); 
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        height: fit-content;
        gap: 20px;
    }
    @media screen and (max-width: 500px){
        padding: 40px 20px;
        gap: 20px; 
    }
`
const PrizePoolBox = styled.div`
    display: flex;
    gap: 100px;
    img.rw_icon{
        max-width: 64px;
        max-height: 64px;
        @media screen and (max-width: 800px){
            max-width: 45px;
            max-height: 45px;
        }
        @media screen and (max-width: 500px){
            display: none !important;
        }
    }
    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        justify-content: space-between;
        gap: 10px;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        display: grid;
        gap: 20px; 
    }
`

const MyRewardBox = styled.div`
    display: flex;
    gap: 40px;
    align-items: center;
    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        gap: 20px;
        flex-wrap: wrap;
        .my_reward_item{
            width: calc(50% - 22px);
        }
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        .my_reward_item{
            width: 100%;
        }
    }
`

const MyRewardBoxSeperator = styled.div<{
    isHideOnLg?: boolean
}>`
    width: 1px;
    height: 80%;
    background: rgba(239, 239, 228, 0.50);
    opacity: 0.6;
    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        height: 50px;
        display: ${({ isHideOnLg }) => isHideOnLg ? 'none' : 'block'}
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        display: none;
    }
`

const PrizePoolBoxLeft = styled.div`   
    width: 50%;
    display: flex;
    height: fit-content;
    gap: 16px;
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        width: 100%;
    }
`
const PrizePoolBoxInfor = styled.div`   
    display: grid;
    height: 64px;
    gap: 8px;
    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        span {
            font-size: 16px;
        }
        gap: 4px;
    } 
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        gap: 0;
    } 
`

const RewardInfoWrapper = styled.div<{
    gap?: string
}>`
    display: flex;
    align-items: center;
    gap: ${({ gap }) => gap ?? '8px'};
    position: relative;
    z-index: 10;
`;

const RefInfoStatContent = styled.span`
    color: #EFEFE4;
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    text-transform: uppercase;
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        font-size: 16px;
        line-height: 18.5px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        font-size: 14px !important;
        line-height: 125% !important;
    }
`;

const RefInfoStatLabel = styled.span`
    color: rgba(239, 239, 228, 0.50);
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
`;


const RewardInfo = ({ musd, point, goldPoint, isTurnOffSepartor, gap }: { musd?: number; point?: number; goldPoint?: number; isTurnOffSepartor?: boolean, gap?: string }) => {
    return <RewardInfoWrapper gap={gap}>
        <RefInfoStatContent style={{ fontFamily: 'Righteous', fontWeight: 400, display: 'flex', alignItems: 'center', gap: '5px' }}>
            {formatXpNumber(musd)}
            <Tooltip title="MUSD">
                <img style={{ cursor: 'pointer', width: '24px', height: '24px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)' }} alt="monoswap" src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musd.svg' />
            </Tooltip>
        </RefInfoStatContent>
        {
            !isTurnOffSepartor &&
            <RefInfoStatLabel style={{ fontWeight: 600 }}>|</RefInfoStatLabel>
        }
        <RefInfoStatContent style={{ fontFamily: 'Righteous', fontWeight: 400, display: 'flex', alignItems: 'center', gap: '5px' }}>
            {formatXpNumber(point ?? 0)}
            <Tooltip title="Blast Point">
                <img style={{ cursor: 'pointer', width: '24px', height: '24px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)' }} alt="monoswap" src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chain-logo/blast.png' />
            </Tooltip>
        </RefInfoStatContent>
        {
            !isTurnOffSepartor &&
            <RefInfoStatLabel style={{ fontWeight: 600 }}>|</RefInfoStatLabel>
        }
        <RefInfoStatContent style={{ fontFamily: 'Righteous', fontWeight: 400, display: 'flex', alignItems: 'center', gap: '5px' }}>{formatXpNumber(goldPoint ?? 0)}
            <Tooltip title="Blast Gold">
                <img style={{ cursor: 'pointer', width: '24px', height: '24px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)' }} alt="monoswap" src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/blast-gold-icon.png' />
            </Tooltip>
        </RefInfoStatContent>
    </RewardInfoWrapper>
}

const PrizePoolBoxRight = styled.div`   
    display: flex;
    gap: 16px;
    min-width: 220px;
`

const TradingVolBoxWrapper = styled.div`
    margin-top: 106px;
`
const TradingColListWrapper = styled.div`
    width: fit-content;
    display: grid;
    gap: 35px;
    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        gap: 24px;
    }
`

const TradingColList = styled.div`
    width: fit-content;
    height: 133px;
    display: flex;
    gap: 10px;
    position: relative; 
    @media screen and (max-width: 450px){
        gap: 9px;
    }
    @media screen and (max-width: 390px){
        gap: 8px;
    } 
`

const TradingColItem = styled.div<{
    h: string;
    w?: string;
    hasReached: boolean;
    isMilestone: boolean;
}>`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    position: relative;
    div.col{
        height: ${({ h }) => h};
        width: ${({ w }) => w ?? '2px'};
        background: ${({ hasReached, isMilestone }) => hasReached ? 'linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)' : isMilestone ? '#EFEFE4' : 'rgba(239, 239, 228, 0.50)'};
        position: relative;
    }
    .icon{
        position: absolute;
        top: -8px;
        left: -3.11px;
        width: 10px;
        height: 10px;
        background: ${({ hasReached, isMilestone }) => hasReached ? 'linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)' : isMilestone ? '#EFEFE4' : 'rgba(239, 239, 228, 0.50)'};
        rotate: 45deg;
    }
    .vol_info{
        position: absolute;
        top: -42px;
        display: flex;
        justify-content: center;
        left: -50px;
        width: 100px;
        @media screen and (max-width: ${BREAKPOINTS.sm}px){
            top: -35px;
        }
        @media screen and (max-width: ${BREAKPOINTS.xs}px){
            top: -20px;
        }
    }
`

const HighlightCol = styled.div<{
    h: string;
    hasReached: boolean;
}>`
    background: ${({ hasReached }) => hasReached ? 'linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)' : 'rgba(239, 239, 228, 0.50)'};
    width: 1px;
    height: ${({ h }) => h};
    position: absolute;
    top: 0;
    left: 1.5px;
    padding-top: 20px;
`

const RewardInfoList = styled.div`
    display: grid;
    gap: 12px;
    div{
        display: flex;
        gap: 6px;
        align-items: center;
    }
    span{
        padding: 0 !important;
        font-size: 12px;
    }
    img.earned_img{
        width: 16px;
        height: 16px;
        border: 1px solid #dcdcdc85;
        border-radius: 50%;
    }
`

const VolText = styled.span`
    text-align: center;
    font-family: Righteous;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    text-transform: uppercase;
    padding: 12px 24px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        padding: 10px 16px;
    }
    @media screen and (max-width: ${BREAKPOINTS.xs}px){
        font-size: 12px;
        padding: 8px 14px;
    }
`

const TradingColHozirontal = styled.div`
    width: 100%;
    height: 12px;
    background: #efefe42e;
    z-index: 0;
    position: relative;
    
`
const FillTradingColHozirontal = styled.div<{
    w: string;
}>`
    position: relative;
    width: ${({ w }) => w};
    height: 100%;
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    display: flex;
    gap: 10px;
    .fill_icon{
        position: absolute;
        top: -10px;
        right: -15px;
    }
    @media screen and (max-width: 450px){
        gap: 9px;
    }
    @media screen and (max-width: 390px){
        gap: 8px;
    } 
`

const TradingColRewardList = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    @media screen and (max-width: 450px){
        gap: 9px;
    }
    @media screen and (max-width: 390px){
        gap: 8px;
    } 
`
const TradingColRewardItem = styled.div<{
    h: string;
    w?: string;
}>`
    height: ${({ h }) => h};
    width: ${({ w }) => w ?? '2px'};
    position: relative;
    .reward_col{
        position: absolute;
        top: 0;
        left: -50px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`
function TradingVolBox({ totalCurrentVol }: any) {
    type VolListType = {
        index: number;
        h: string;
        w: string;
        hasReached: boolean;
        isNextVol: boolean;
        isMilestone: boolean;
        volVal: string;
        reward: string;
        rewardInfo?: any
    }
    const REACHED_RANGE = [0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500].reverse()
    const [volList, setVolList] = useState<VolListType[]>([])
    const [invalidRange, setInvalidRange] = useState<number[]>([])

    const renderVolList = useMemo(() => {
        const newList = volList.filter(({ index }: any) => invalidRange.includes(index));
        if (newList[0]?.index == -50) return newList.slice(1) // ignore first el
        return newList
    }, [volList, invalidRange])

    const fillHorizontalWList = useMemo(() => {
        const arr = renderVolList.filter(item => item.hasReached)
        return arr
    }, [renderVolList])

    function getCurrentVolAndNextVol() {
        const currentVol = totalCurrentVol / 1000000;
        let nextVol = 0;
        if (currentVol >= 500) nextVol = 900
        else if (currentVol >= 450) nextVol = 500
        else if (currentVol >= 400) nextVol = 450
        else if (currentVol >= 350) nextVol = 400
        else if (currentVol >= 300) nextVol = 350
        else if (currentVol >= 250) nextVol = 300
        else if (currentVol >= 200) nextVol = 250
        else if (currentVol >= 150) nextVol = 200
        else if (currentVol >= 100) nextVol = 150
        else if (currentVol >= 50) nextVol = 100
        else if (currentVol >= 5) nextVol = 50
        else if (currentVol >= 0) nextVol = 0
        return [currentVol, nextVol]
    }

    function getRangeBetween(start: number, end: number) {
        const list = []
        for (let index = start; index <= end; index += 5)
            list.push(index)
        return list
    }

    const getVolValue = (value: number) => {
        if (value <= 0) {
            switch (value) {
                case -50: return 0;
                case -45: return 0.5;
                case -40: return 1;
                case -35: return 1.5;
                case -30: return 2;
                case -25: return 2.5;
                case -20: return 3;
                case -15: return 3.5;
                case -10: return 4;
                case -5: return 4.5;
                case 0: return 5;
                default: return value
            }
        }
        else return value
    }
    useEffect(() => {
        const [currentVol, nextVol] = getCurrentVolAndNextVol()
        let startHeight = 12;
        const arr: any[] = []

        for (let index = -50; index <= 550; index += 5) {
            startHeight += 1
            const volVal = getVolValue(index)
            if (REACHED_RANGE.includes(index)) arr.push({
                index,
                h: `${startHeight}px`,
                w: '3px',
                hasReached: currentVol >= (index == 0 ? 5 : index),
                isNextVol: index == 0 || index == nextVol,
                volVal: `${index == 0 ? 5 : index}M`,
                isMilestone: true,
                rewardInfo: PrizePoolMap.get(index)
            })
            else arr.push({
                index,
                h: `${startHeight}px`,
                w: '1px',
                hasReached: currentVol >= volVal,
                isNextVol: false,
                volVal: `${volVal}M`,
                isMilestone: false,
                rewardInfo: index == 0 ? PrizePoolMap.get(index) : undefined
            })
        }

        const reachedMilestone = REACHED_RANGE.find(i => (i <= currentVol))

        if (window.screen.width >= 1500) { setInvalidRange(getRangeBetween(-50, 500)) }
        else if (window.screen.width >= 1200) {
            if (reachedMilestone) {
                if (reachedMilestone <= 150)
                    setInvalidRange(getRangeBetween(-50, 300))
                else setInvalidRange(getRangeBetween(100, 500))
            }
            else setInvalidRange(getRangeBetween(-50, 400))
        }
        else if (window.screen.width >= 1000) {
            if (reachedMilestone) {
                if (reachedMilestone <= 150)
                    setInvalidRange(getRangeBetween(-50, 300))
                else if (reachedMilestone >= 400)
                    setInvalidRange(getRangeBetween(150, 500))
                else setInvalidRange(getRangeBetween(150, 500))
            }
            else setInvalidRange(getRangeBetween(-50, 300))
        }
        else if (window.screen.width >= 800) {
            if (reachedMilestone) {
                if (reachedMilestone <= 150)
                    setInvalidRange(getRangeBetween(-50, 250))
                else setInvalidRange(getRangeBetween(200, 500))
            }
            else setInvalidRange(getRangeBetween(-50, 250))
        }
        else if (window.screen.width >= 700) {
            if (reachedMilestone) {
                if (reachedMilestone == 50)
                    setInvalidRange(getRangeBetween(-50, 200))
                else if (reachedMilestone == 100)
                    setInvalidRange(getRangeBetween(50, 300))
                else setInvalidRange(getRangeBetween(250, 500))
            }
            else setInvalidRange(getRangeBetween(-50, 200))
        }
        else if (window.screen.width >= 570) {
            if (reachedMilestone) {
                if (reachedMilestone == 50)
                    setInvalidRange(getRangeBetween(-50, 150))
                else if (reachedMilestone == 100)
                    setInvalidRange(getRangeBetween(0, 200))
                else
                    setInvalidRange(getRangeBetween(300, 500))
            }
            else setInvalidRange(getRangeBetween(-50, 150))
        }
        else {
            if (reachedMilestone) {
                if (reachedMilestone == 50)
                    setInvalidRange(getRangeBetween(-50, 100))
                else if (reachedMilestone == 100)
                    setInvalidRange(getRangeBetween(50, 200))
                else
                    setInvalidRange(getRangeBetween(350, 500))
            }
            else setInvalidRange(getRangeBetween(-50, 100))
        }

        setVolList(arr)
    }, [totalCurrentVol])

    return <TradingVolBoxWrapper>
        <TradingColListWrapper>
            <TradingColList>
                {
                    renderVolList.map((item, index) => <TradingColItem key={index} className={`vol_col_${index}`} h={item.h} w={item.w} hasReached={item.hasReached} isMilestone={item.isMilestone}>
                        <div className="col">
                            {
                                index != 0 && item.isMilestone && <div className="icon" />
                            }

                        </div>
                        {
                            index == 1 && <HighlightCol hasReached={false} h={`calc(100% - ${item.h})`} style={{ background: 'unset' }}>
                                <div className="vol_info" style={{ left: '-20px' }}>
                                    <Text color="#EFEFE4">
                                        Trading Volume
                                    </Text>
                                </div>
                            </HighlightCol>
                        }
                        {
                            index != 0 && (item.hasReached || item.isNextVol) && item.isMilestone && <HighlightCol hasReached={item.hasReached} h={`calc(100% - ${item.h})`}>
                                <div className="vol_info">
                                    {
                                        item.hasReached && <ColorBorderContentBox w="fit-content" clipPath="polygon(12px 0, 100% 0, 100% calc(100% - 12px), calc(100% - 12px) 100%, 0 100%, 0 12px)" borderColor="linear-gradient(90deg, #68FFFF, #68FF9B)" >
                                            <VolText >
                                                <LinearText>{item.volVal}</LinearText>
                                            </VolText>
                                        </ColorBorderContentBox>
                                    }

                                    {
                                        item.isNextVol && !item.hasReached && <ColorBorderContentBox w="fit-content" clipPath="polygon(12px 0, 100% 0, 100% calc(100% - 12px), calc(100% - 12px) 100%, 0 100%, 0 12px)" borderColor="#EFEFE4" >
                                            <VolText >
                                                {item.volVal}
                                            </VolText>
                                        </ColorBorderContentBox>
                                    }
                                </div>

                            </HighlightCol>
                        }
                    </TradingColItem>)
                }


            </TradingColList>
            <TradingColHozirontal>
                <FillTradingColHozirontal w="fit-content" >
                    {
                        fillHorizontalWList.map((item, index) => <div key={index} className={`vol_col_${index}`} style={{ width: item.w, height: '100%' }} />)
                    }
                    <img className="fill_icon" style={{ width: '32px', height: '32px' }} src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/fill-icon.png" />
                </FillTradingColHozirontal>
            </TradingColHozirontal>

            <TradingColRewardList>
                {
                    renderVolList.map((item, index) => <TradingColRewardItem key={index} className={`vol_col_${index}`} h={'1px'} w={item.w}>
                        <div className="reward_col" style={{ left: index == 1 ? '-35px' : '-50px' }}>
                            {
                                index == 1 &&
                                <Text color="#EFEFE4" style={{ paddingTop: '10px' }}>
                                    Rewards
                                </Text>
                            }

                            {
                                index != 0 && item.hasReached && item.isMilestone && <RewardInfoList>
                                    <div>
                                        <img className='earned_img' src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musd.svg" />
                                        <VolText>
                                            {formatXpNumber(item.rewardInfo?.totalMusd ?? 0)}
                                        </VolText>
                                    </div>
                                    <div>
                                        <img className='earned_img' src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chain-logo/blast.png" />
                                        <VolText>
                                            {item.rewardInfo?.totalPoint ?? 0}
                                        </VolText>
                                    </div>
                                    <div>
                                        <img className='earned_img' src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/blast-gold-icon.png" />
                                        <VolText>
                                            {formatXpNumber(item.rewardInfo?.totalGoldPoint ?? 0)}
                                        </VolText>
                                    </div>
                                </RewardInfoList>
                            }
                            {
                                index != 0 && item.isNextVol && !item.hasReached && <RewardInfoList>
                                    <div>
                                        <img className='earned_img' src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musd.svg" />
                                        <VolText>
                                            {formatXpNumber(item.rewardInfo?.totalMusd ?? 0)}
                                        </VolText>
                                    </div>
                                    <div>
                                        <img className='earned_img' src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chain-logo/blast.png" />
                                        <VolText>
                                            {item.rewardInfo?.totalPoint ?? 0}
                                        </VolText>
                                    </div>
                                    <div>
                                        <img className='earned_img' src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/blast-gold-icon.png" />
                                        <VolText>
                                            {formatXpNumber(item.rewardInfo?.totalGoldPoint ?? 0)}
                                        </VolText>
                                    </div>
                                </RewardInfoList>
                            }
                        </div>
                    </TradingColRewardItem>)
                }

            </TradingColRewardList>
        </TradingColListWrapper>
    </TradingVolBoxWrapper>
}

export default function UnlockRewardSection({ data }: any) {
    const { account } = useWeb3React()

    return <UnlockRewardSectionWrapper>
        <InfoBox>
            <Title>
                Unlock <LinearText>Rewards</LinearText>
            </Title>
            <Text color="#EFEFE4">
                The final prize pool will be determined by the total eligible trading volume when the tournament ends.
            </Text>
        </InfoBox>
        <PrizePoolWrapper style={{ marginTop: '50px' }}>
            <PrizePoolBox>
                <PrizePoolBoxLeft>
                    <img className="rw_icon" src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/current-price-img.png" alt="monoswap" />

                    <PrizePoolBoxInfor>
                        <Text >Current Prize Pool</Text>
                        <RewardInfo gap="12px" musd={data?.totalDistributed?.musd} point={data?.totalDistributed?.point} goldPoint={data?.totalDistributed?.goldPoint} />
                    </PrizePoolBoxInfor>
                </PrizePoolBoxLeft>

                <PrizePoolBoxRight>
                    <img className="rw_icon" src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/total-trading-vol-img.png" alt="monoswap" />
                    <PrizePoolBoxInfor>
                        <Text >Total Trading Volume</Text>
                        <Text fontSize="20px" color="#FFF" style={{ fontFamily: 'Righteous', fontWeight: 400 }}>${formatXpNumber(data?.tradeContest?.totalCurrentVol ?? 0)}</Text>
                    </PrizePoolBoxInfor>
                </PrizePoolBoxRight>

            </PrizePoolBox>
            <PrizePoolBox>
                <PrizePoolBoxLeft>
                    <img className="rw_icon" src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/current-price-pool.png" />

                    <PrizePoolBoxInfor>
                        <Text >Next Prize Pool</Text>
                        <RewardInfo gap="12px" musd={data?.nextTotalDistributed?.musd} point={data?.nextTotalDistributed?.point} goldPoint={data?.nextTotalDistributed?.goldPoint} />
                    </PrizePoolBoxInfor>
                </PrizePoolBoxLeft>
                <PrizePoolBoxRight>
                    <img className="rw_icon" src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/participant.png" />
                    <PrizePoolBoxInfor>
                        <Text >Participants</Text>
                        <Text fontSize="20px" color="#FFF" style={{ fontFamily: 'Righteous', fontWeight: 400 }}>{formatXpNumber(data?.tradeContest?.totalParticipant ?? 0)}</Text>
                    </PrizePoolBoxInfor>
                </PrizePoolBoxRight>
            </PrizePoolBox>
        </PrizePoolWrapper>

        {
            account && <PrizePoolWrapper style={{ height: 'fit-content', marginTop: '16px' }}>
                <MyRewardBox>
                    <PrizePoolBoxInfor className="my_reward_item">
                        <Text >My Trading Volume</Text>
                        <Text fontSize="20px" color="#FFF" style={{ fontFamily: 'Righteous', fontWeight: 400 }}>${formatXpNumber(data?.myTop?.volume ?? 0)}</Text>
                    </PrizePoolBoxInfor>
                    <MyRewardBoxSeperator />
                    <PrizePoolBoxInfor className="my_reward_item">
                        <Text >Trading Point</Text>
                        <Text fontSize="20px" color="#FFF" style={{ fontFamily: 'Righteous', fontWeight: 400 }}>{formatXpNumber(data?.myTop?.tradingPoint ?? 0)}</Text>
                    </PrizePoolBoxInfor>
                    <MyRewardBoxSeperator isHideOnLg={true} />
                    <PrizePoolBoxInfor className="my_reward_item">
                        <Text >Minimum Trading Criteria</Text>
                        <Text fontSize="20px" color="#FFF" style={{ fontFamily: 'Righteous', fontWeight: 400 }}>$500</Text>
                    </PrizePoolBoxInfor>
                    <MyRewardBoxSeperator />
                    <PrizePoolBoxInfor className="my_reward_item">
                        <Text >Estimated Rewards</Text>
                        <RewardInfo gap="12px" musd={data?.myTop?.estimatedReward?.musd} point={data?.myTop?.estimatedReward?.point} goldPoint={data?.myTop?.estimatedReward?.goldPoint} />
                    </PrizePoolBoxInfor>
                </MyRewardBox>
            </PrizePoolWrapper>
        }

        <TradingVolBox totalCurrentVol={data?.tradeContest?.totalCurrentVol ?? 0} />

    </UnlockRewardSectionWrapper>
}