import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'
import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v2-sdk'
import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'
import { PERMIT2_ADDRESS } from '@uniswap/permit2-sdk'

type AddressMap = { [chainId: number]: string }

// blast v2 addresses
const BLAST_V2_CORE_FACTORY_ADDRESSES = '0xE27cb06A15230A7480d02956a3521E78C5bFD2D0'
const BLAST_V2_ROUTER_ADDRESS = '0x859374eA6dF8289d883fEd4E688a83381276521d'
const BLAST_INIT_CODE_HASH_V2 = '0xd1a99f7339108abbcc2eaa6478ee4a0394e2a63f04de08793721fb2f3eff5a38'

// blast v3 addresses
const BLAST_V3_CORE_FACTORY_ADDRESSES = '0x48d0F09710794313f33619c95147F34458BF7C3b'
const BLAST_ROUTER_ADDRESS = '0xE4Dd30f7a2808580C9185e975bd7770A842923c6'
const BLAST_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x0F0C3dE3cd5185454eDe5EF184bC9C7F4133fb90'
const BLAST_V3_MIGRATOR_ADDRESSES = '0x19781CB84Ca75a1D81787ae88C72F63764a3037F'
const BLAST_MULTICALL_ADDRESS = '0xF9f134E6652A5eDE4666b9A75d8CFdD972c12912'
const BLAST_TICK_LENS_ADDRESSES = '0x4a3930837f6E721A6Da5DE4E400A7e90f907fb54'
const BLAST_QUOTER_ADDRESSES = '0x29eb40F0A3522C2Baf4346803dA3a4d617bA7C96'
const BLAST_PERMIT2_ADDRESSES = '0xca3f794e8818c41b61fCd1ff6e5765d0DF299CA6'
const BLAST_INIT_CODE_HASH_V3 = '0x7ea070216c7d9135010a36147394687bab92df4695e924000eed7c4b33eb922f'

//  Custome cotract
export const BLAST_MUSD_ADDRESSES = '0x837fE561e9C5DFa73F607fDa679295DBC2Be5E40'
export const BLAST_USDB_ADDRESSES = '0x4300000000000000000000000000000000000003'
const BLAST_V3STAKER_ADDRESSES = '0xf10CAb7e3df03185a03E9829B0b38b1edc961e37'
const BLAST_MONO_CHEST = '0x90BBAD5eB482e73508069483215ad9a11cA537Df'
const BLAST_MONO_CHEST_NFT = '0xBEEfA3FC2cabCf76c42802E563aE05bcb0E3d97C'
// v2
export const LP_ALPHA_V2_FACTORY_ADDRESSES = '0x8dBf53A5C961A2ad8F9F405F40F12bDAAA6f7c59'
const BLAST_MONO_LAUNCHPAD_ADDRESS = '0x42843e81a68e926AFC5113AaF3E72869C7D4f489'

// monopot
export const BLAST_MONOPOT_ADDRESSES = '0xe56cF93657ca9dbd512363C3e69f55ab4f15022A'
export const BLAST_MONOPOT_TICKET_ADDRESSES = '0xBEEfA3FC2cabCf76c42802E563aE05bcb0E3d97C'

// =================================================
// =================================================

// blast sepolia v2 addresses
const BLAST_SEPOLIA_V2_CORE_FACTORY_ADDRESSES = '0xE63D69fFdB211dD747ad8970544043fADE7d20f5'
const BLAST_SEPOLIA_V2_ROUTER_ADDRESS = '0xdd9501781fa1c77584B0c55e0e68607AF3c35840'
const BLAST_SEPOLIA_INIT_CODE_HASH_V2 = '0xc9715eb6db3ca1b055b197bd8b44647d9fcc358d2ca358c4f49c973e48a13484'

// blast sepolia v3 addresses
const BLAST_SEPOLIA_V3_CORE_FACTORY_ADDRESSES = '0xbAB2F66B5B3Be3cC158E3aC1007A8DF0bA5d67F4'
const BLAST_SEPOLIA_ROUTER_ADDRESS = '0xF339F231678e738c4D553e6b60305b852a4C526B'
const BLAST_SEPOLIA_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0xa4b568bCdeD46bB8F84148fcccdeA37e262A3848'
const BLAST_SEPOLIA_V3_MIGRATOR_ADDRESSES = '0xB82cE774FDB825e73ABB3D2b246B218a3251302D'
const BLAST_SEPOLIA_MULTICALL_ADDRESS = '0xE535A10FC427513716304F8b717Db34edDeD4A04'
const BLAST_SEPOLIA_TICK_LENS_ADDRESSES = '0x3B993E5cE12F99cB532cC0E50EB411bB57a06766'
const BLAST_SEPOLIA_QUOTER_ADDRESSES = '0xA1CeB6084691C10401BC1Abc954918703662ab32'
const BLAST_SEPOLIA_PERMIT2_ADDRESSES = '0xB952578f3520EE8Ea45b7914994dcf4702cEe578'
const BLAST_SEPOLIA_INIT_CODE_HASH_V3 = '0xda571d86ad43edb30b030580c077ec40d48a0a4203fc3d13dc8ebda8e7b46c44'

// test token
export const BLAST_SEPOLIA_TEST_BTC_ADDRESSES = '0xcF749620571A3920a27CD8Eef17Daa1db7C93c4C'
export const BLAST_SEPOLIA_TEST_USDC_ADDRESSES = '0xda9C093a7D9e41d21Dc9A7ff5601A3FD02111d95'
export const BLAST_SEPOLIA_TEST_MONO_ADDRESSES = '0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055'
export const BLAST_SEPOLIA_TEST_XMONO_ADDRESS = '0x66fBCf5636BC4060D93aF689B125a172b67D867e'
export const BLAST_SEPOLIA_TEST_MULTIL_FAUCET_ADDRESSES = '0x3247B4de7FB17c8523ee02c19437DCc976F4dbE0'
export const BLAST_SEPOLIA_MONOPASS_NFT_ADDRESSES = '0xad2B8f26417298F3bc1Ae59a712167416fD17E38'
export const BLAST_SEPOLIA_MONOPASS_NFT_MINTER_ADDRESSES = '0x3AB16aC4e352a08263c525C0D9967572eE1d0A5c'

// test Custome cotract
const BLAST_SEPOLIA_MUSD_ADDRESSES = '0x54D12b155dA569aaEa910A778Eb3EC9cd2B26230'
const BLAST_SEPOLIA_USDB_ADDRESSES = '0x4200000000000000000000000000000000000022'
const BLAST_SEPOLIA_V3STAKER_ADDRESSES = '0xF2f0caEc37757D9dd2aDfbDDF7E17d24f55F2C30'
export const BLAST_SEPOLIA_MONOPOT_ADDRESSES = '0xD661102420dd676EDc28EF4E6BB5fc7Da31ad205'
export const BLAST_SEPOLIA_MONOPOT_TICKET_ADDRESSES = '0xCF44b600AadC00f8004d1Dfb623386251F5d1Aa2'

// v2
const BLAST_SEPOLIA_LP_ALPHA_V2_FACTORY_ADDRESSES = '0x9a1DF0972e887C2E75F65760745D4D47ad077a69'
const BLAST_SEPOLIA_MONO_LAUNCHPAD_ADDRESS = '0xd3e5b40548965f18e9cb37fa80fdc59578de6f0b'
const BLAST_SEPOLIA_MONO_PASS_ADDRESS = '0x42682806a9798181e545C77fEf512Ada14468B7d'
const BLAST_SEPOLIA_MONO_PASS_SALE_ADDRESS = '0x78bba2c6aa1aa6bb8deda5c42e8318323d03f0cd'
const BLAST_SEPOLIA_MONO_PASS_STAKE_ADDRESS = '0x4D9b22234b1717852E6eD6F2cA61C9119bC34f02'
const BLAST_SEPOLIA_DIVIDEND_ADDRESS = '0xAaB901aBfeCcD473591CdC197F9C6F5C5C544899'

const BLAST_MONO_PASS_ADDRESS = '0xD13f2003687526159F4e94aB81a1C9A3Cf840544'
const BLAST_MONO_PASS_SALE_ADDRESS = '0x64078D833c4d803c4A08a5e7592f25376eB31417'
const BLAST_MONO_PASS_STAKE_ADDRESS = '0x38C24F42fdf27771d9715C8b6DcD1403aC029BB7'
const BLAST_DIVIDEND_ADDRESS = '0x0713bCD10Cc6E0E552c1662F167BDC319b2A1B76'
const BLAST_XMONO_ADDRESS = '0x28A922aD2d2A02250473b30F3444E20b138354d1'
const BLAST_MONO_ADDRESS = '0x66889602f76E8F7efDC968771F1d2f75bdbB3FDE'

// =================================================
// config
// =================================================
const BLAST_SEPOLIA_MONO_CHEST = '0xdab71732Dd9EDFdCa4c5a9E409Bd380745266332'
const BLAST_SEPOLIA_MONO_CHEST_NFT = '0xCF44b600AadC00f8004d1Dfb623386251F5d1Aa2'
// musd
export const MUSD_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0x837fE561e9C5DFa73F607fDa679295DBC2Be5E40', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_MUSD_ADDRESSES,
  [SupportedChainId.BLAST]: BLAST_MUSD_ADDRESSES,
}
// usdb
export const USDB_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0x4300000000000000000000000000000000000003', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_USDB_ADDRESSES,
  [SupportedChainId.BLAST]: BLAST_USDB_ADDRESSES,
}
// alpha pool
export const V3STAKER_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0xf10CAb7e3df03185a03E9829B0b38b1edc961e37', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_V3STAKER_ADDRESSES,
  [SupportedChainId.BLAST]: BLAST_V3STAKER_ADDRESSES,
}
// mono chest
export const MONO_CHEST_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0x90BBAD5eB482e73508069483215ad9a11cA537Df', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_MONO_CHEST,
  [SupportedChainId.BLAST]: BLAST_MONO_CHEST,
}
export const MONO_CHEST_NFT_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0xCF44b600AadC00f8004d1Dfb623386251F5d1Aa2', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_MONO_CHEST_NFT,
  [SupportedChainId.BLAST]: BLAST_MONO_CHEST_NFT,
}

// monopass
export const MONO_PASS_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0xD13f2003687526159F4e94aB81a1C9A3Cf840544', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_MONO_PASS_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_MONO_PASS_ADDRESS,
}

export const MONO_PASS_SALE_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0x64078D833c4d803c4A08a5e7592f25376eB31417', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_MONO_PASS_SALE_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_MONO_PASS_SALE_ADDRESS,
}

export const MONO_PASS_STAKE_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0x38C24F42fdf27771d9715C8b6DcD1403aC029BB7', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_MONO_PASS_STAKE_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_MONO_PASS_STAKE_ADDRESS,
}

// dividend
export const MONO_DIVIDEND_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0x0713bCD10Cc6E0E552c1662F167BDC319b2A1B76', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_DIVIDEND_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_DIVIDEND_ADDRESS,
}
// xmono
export const XMONO_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0x66fBCf5636BC4060D93aF689B125a172b67D867e', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_TEST_XMONO_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_XMONO_ADDRESS,
}

// mono
export const MONO_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0x66889602f76E8F7efDC968771F1d2f75bdbB3FDE', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_TEST_MONO_ADDRESSES,
  [SupportedChainId.BLAST]: BLAST_MONO_ADDRESS,
}

//v2
export const LP_POOL_V2_ADDRESSES_MAP: AddressMap = {
  ...constructSameAddressMap('0x9a1DF0972e887C2E75F65760745D4D47ad077a69', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_LP_ALPHA_V2_FACTORY_ADDRESSES,
  [SupportedChainId.BLAST]: LP_ALPHA_V2_FACTORY_ADDRESSES,
}
// mono launchpad
export const MONO_LAUNCHPAD: AddressMap = {
  ...constructSameAddressMap('0x42843e81a68e926AFC5113AaF3E72869C7D4f489', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_MONO_LAUNCHPAD_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_MONO_LAUNCHPAD_ADDRESS,
}
// <============>

export const INIT_CODE_HASH_V3: AddressMap = {
  ...constructSameAddressMap('0xda571d86ad43edb30b030580c077ec40d48a0a4203fc3d13dc8ebda8e7b46c44', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_INIT_CODE_HASH_V3,
  [SupportedChainId.BLAST]: BLAST_INIT_CODE_HASH_V3,
}

export const INIT_CODE_HASH_V2: AddressMap = {
  ...constructSameAddressMap('0xc9715eb6db3ca1b055b197bd8b44647d9fcc358d2ca358c4f49c973e48a13484', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_INIT_CODE_HASH_V2,
  [SupportedChainId.BLAST]: BLAST_INIT_CODE_HASH_V2,
}

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')

export const UNISWAP_NFT_AIRDROP_CLAIM_ADDRESS = '0x8B799381ac40b838BBA4131ffB26197C432AFe78'

export const PERMIT2_ADDRESS_MAP: AddressMap = {
  ...constructSameAddressMap(PERMIT2_ADDRESS, []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_PERMIT2_ADDRESSES,
  [SupportedChainId.BLAST]: BLAST_PERMIT2_ADDRESSES,
}

export const V2_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V2_FACTORY_ADDRESS, []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_V2_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.BLAST]: BLAST_V2_CORE_FACTORY_ADDRESSES,
}

export const V2_ROUTER_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_V2_ROUTER_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_V2_ROUTER_ADDRESS,
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V3_FACTORY_ADDRESS, []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.BLAST]: BLAST_V3_CORE_FACTORY_ADDRESSES,
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.BLAST]: BLAST_V3_MIGRATOR_ADDRESSES,
}

export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_MULTICALL_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_MULTICALL_ADDRESS,
}

export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_ROUTER_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_ROUTER_ADDRESS,
}

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3',
}

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}

export const QUOTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_QUOTER_ADDRESSES,
  [SupportedChainId.BLAST]: BLAST_QUOTER_ADDRESSES,
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xC36442b4a4522E871399CD717aBDD847Ab11FE88', []),
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.BLAST]: BLAST_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.BLAST_SEPOLIA]: BLAST_SEPOLIA_TICK_LENS_ADDRESSES,
  [SupportedChainId.BLAST]: BLAST_TICK_LENS_ADDRESSES,
}
