import { useWeb3React } from "@web3-react/core";
import { NotchedButtonFill } from "components/Button";
import { NotchedButtonBolderNew } from "components/Button/NotchedButton";
import Modal from "components/Modal";
import { MONO_DIVIDEND_ADDRESSES_MAP } from "constants/addresses";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "theme";
import { ethers } from "ethers";
import { SupportedChainId } from "constants/chains";
import { formatXpNumber } from "pages/Xp/sections/XpTitle";
import { notification } from "antd";
import checkUnsupportChainId from "utils/checkUnsupportChainId";

const TitleBox = styled.div`
    display: grid;
    max-width: 600px;
    margin: auto;
    gap: 8px;
    justify-content: start;
    align-content: start;
    padding: 0 24px;
    margin-top: 24px;
`;
const Title = styled.h1`
    color: #EFEFE4;
    font-family: Righteous;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 60px */
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        justify-content: left;
        font-size: 24px;
        line-height: 125%;
    }
`;
const SubTitle = styled.h4`
    color: #EFEFE4;
    text-align: left;

    /* Urbanist/Body-2/Semi */
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 25.2px */
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        margin: auto;
        font-size: 14px;
        line-height: 125%;
        text-align: left;
    }
`;

const TabList = styled.div`
    display: flex;
    margin-bottom: 56px;
    margin-top: 24px;
    height: 50px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        height: 40px;
        margin-bottom: 20px;
    }
`
const TabItem = styled.div<{
    isActived: boolean
}>`
    display: flex;
    width: 50%;
    height: 100%;
    padding: 24px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: ${({ isActived }) => isActived ? 'linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)' : ''} ;
    cursor: ${({ isActived }) => isActived ? 'unset' : 'pointer'} ;
    color: ${({ isActived }) => isActived ? '#0A090F' : 'rgba(239, 239, 228, 0.50)'} ;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 22.5px */
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding: 12px 0px;
    }
`;

const InputBoxWrapper = styled.div`
    padding: 0 24px;
`;
const InputBoxTitle = styled.h5`
    color: #EFEFE4;
    font-family: Urbanist;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 26.25px */
    margin: 0;
    margin-bottom: 20px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        font-size: 18px;
    }
`;

const InputBox = styled.div`
    display: flex;
    height: 56px;
`;
const InputSelect = styled.div`
    width: 154.1px;
    height: 56px;
    background: rgba(239, 239, 228, 0.05);

    color: #EFEFE4;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 20px */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding: 14px;
    }
`;

const Input = styled.input`
    color: #EFEFE4;
    font-family: Urbanist;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 26.25px */
    background: rgba(239, 239, 228, 0.05);
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    padding-right: 24px;
    text-align: right;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding: 18px;
    }
`;
const InputBalanceBox = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 7px;
`;

const ErrorText = styled.span`
    color: #FF1E56;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 20px */
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding: 14px;
    }
`

const InbutBalanceContent = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
`;

const MaxButton = styled.button`
    display: flex;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: rgba(239, 239, 228, 0.05);
    border: none;
    cursor: pointer;

    span{
        text-align: center;
        font-family: Urbanist;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 15px */
        background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @media screen and (max-width: ${BREAKPOINTS.md}px){
            font-size: 10px;
        }
    }
`
const InputSeperator = styled.div`
    width: 100%;
    height: 1px;
    background: rgba(239, 239, 228, 0.12);
    backdrop-filter: blur(4.800000190734863px);
    margin: 24px 0;
`;

const BalanceLabel = styled.span`
    color: rgba(239, 239, 228, 0.50);
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
    span {
        font-family: Urbanist;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        font-size: 12px;
        span{
            font-size: 12px;
        }
    }
`;

const EstimateRewardBox = styled.div`

`
const EstimateRewardList = styled.div`
    display: grid;
    gap: 12px;
    align-items: center;
    margin-top: 16px;
`
const EstimateRewardItem = styled.div`
    display: flex;
    justify-content: space-between;
`;
const TextLinear = styled.span`
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const ButtonBox = styled.div`
    display: flex;
    gap: 24px;
    padding: 0 24px;
    padding-top: 56px;
    padding-bottom: 40px;
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        .fill-btn, .border-btn{
            height: 40px;
        }
    }
`;
const ButtonLabel = styled.span`
    text-align: center;
    /* Righteous/Button */
    font-family: Righteous;
    font-size: 18px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 100%; /* 18px */
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        font-size: 14px;
    }
`

export default function StakeDividendModal({
    isShowModal,
    setIsShowModal,
    currentTab,
    setTab,
    dividendContext,
}: {
    isShowModal: boolean;
    setIsShowModal: Function;
    currentTab: 'STAKE' | 'UNSTAKE';
    setTab: Function;
    dividendContext: any
}) {
    const { account, chainId } = useWeb3React();
    const [amount, setAmount] = useState<number>(0);
    const { totalStakedXmono, dividendUserInfo, currentEpoch,
        dividendContractWithSign,
        xMonoContract,
        xMonoContractWithSign
    } = dividendContext;

    const [loadingApproveContract, setLoadingApproveContract] = useState(false)
    const [isApproveContract, setIsApproveContract] = useState(false)
    const [xmonoAllowance, setXmonoAllowance] = useState<number>(0)
    const [hasTypedInput, setHasTypedInput] = useState(false)

    const estimatedStakeXmono = useMemo(() => currentTab == 'STAKE' ? amount + (dividendUserInfo?.amount ?? 0) : (dividendUserInfo?.amount ?? 0) - amount, [dividendUserInfo, amount])

    const { yieldShare, nextYieldShare } = useMemo(() => {
        let yieldShare = (dividendUserInfo.amount / totalStakedXmono) * 100;
        if (yieldShare == 0) yieldShare = 0
        else if (yieldShare < 1) yieldShare = Number(yieldShare.toFixed(4))
        else yieldShare = Number(yieldShare.toFixed(2))

        let nextYieldShare = (estimatedStakeXmono / totalStakedXmono) * 100
        if (nextYieldShare == 0) nextYieldShare = 0
        else if (nextYieldShare < 1) nextYieldShare = Number(nextYieldShare.toFixed(4))
        else nextYieldShare = Number(nextYieldShare.toFixed(2))
        return {
            yieldShare,
            nextYieldShare
        }
    }, [dividendUserInfo, estimatedStakeXmono, totalStakedXmono])

    const {
        rewardPerHour,
        rewardPerDay,
        nextRewardPerHour,
        nextRewardPerDay
    } = useMemo(() => {
        const nextRewardPerSecond = currentEpoch.rwPerSeccond * (estimatedStakeXmono / totalStakedXmono)
        const rewardPerSecond = currentEpoch.rwPerSeccond * (dividendUserInfo.amount / totalStakedXmono)

        return {
            rewardPerHour: rewardPerSecond * 3600,
            rewardPerDay: rewardPerSecond * 86400,
            nextRewardPerHour: nextRewardPerSecond * 3600,
            nextRewardPerDay: nextRewardPerSecond * 86400,
        }
    }, [amount, dividendUserInfo, currentEpoch, totalStakedXmono])

    const ApproveStakeContract = async () => {
        checkUnsupportChainId(chainId)
        setLoadingApproveContract(true)
        try {
            const setApproveContract = await xMonoContractWithSign?.approve(MONO_DIVIDEND_ADDRESSES_MAP[SupportedChainId.BLAST], ethers.constants.MaxUint256)
            await setApproveContract.wait()
            setLoadingApproveContract(false)
            await handleCheckApproveStakeContract()
            return true
        } catch (error) {
            console.log(error)
            setLoadingApproveContract(false)
            return false
        }
    }
    const handleCheckApproveStakeContract = async () => {
        if (!account) return

        try {
            const allowance = await xMonoContract?.allowance(account, MONO_DIVIDEND_ADDRESSES_MAP[SupportedChainId.BLAST])
            const formmatedAllowance = Number(ethers.utils.formatUnits(allowance))
            setXmonoAllowance(formmatedAllowance)
            if (formmatedAllowance > 0) {
                setIsApproveContract(true)
            } else {
                setIsApproveContract(false)
            }
        } catch (error) {
            console.log('handleCheckApproveStakeContract error', error)
        }

    }
    const handleApproveStakeContract = async () => {
        await ApproveStakeContract()
    }
    const [loadingButton, setLoadingButton] = useState<boolean>(false)

    const depositXmono = async () => {
        checkUnsupportChainId(chainId)
        if (loadingButton || amount == 0) return
        setLoadingButton(true)
        const amountDeposit = amount ? ethers.utils.parseUnits(String(amount), 18) : '0'
        try {
            const gasEstimate = await dividendContractWithSign?.estimateGas.deposit(amountDeposit)
            const gasLimitCalc = Math.floor(gasEstimate ? gasEstimate.toNumber() * 1.5 : 200000)
            let tx = await dividendContractWithSign?.deposit(amountDeposit, { gasLimit: gasLimitCalc })
            await tx.wait()
            await dividendContext.loadStakeData()
            setAmount(0)
            notification.success({
                message: 'Stake successful'
            })
        } catch (err) {
            console.log(err)
            if (err?.code == 'UNPREDICTABLE_GAS_LIMIT' || (err?.data?.message && String(err?.data?.message).indexOf('insufficient funds')))
                notification.error({
                    message: 'Insufficient funds for gas'
                })
            else
                notification.error({
                    message: 'Stake failed'
                })
        }
        finally {
            setLoadingButton(false)

        }
    }

    const unstakeLp = async () => {
        checkUnsupportChainId(chainId)
        if (loadingButton || amount == 0) return
        try {
            setLoadingButton(true)
            const gasEstimate = await dividendContractWithSign?.estimateGas.withdraw(ethers.utils.parseUnits(String(amount), 18))
            const gasLimitCalc = Math.floor(gasEstimate ? gasEstimate.toNumber() * 1.5 : 200000)
            const tx = await dividendContractWithSign?.withdraw(ethers.utils.parseUnits(String(amount), 18), { gasLimit: gasLimitCalc })
            await tx.wait()
            setAmount(0)
            dividendContext.loadStakeData()
            notification.success({
                message: 'Unstake successful'
            })
        } catch (err) {
            console.log(err)
            if (err?.code == 'UNPREDICTABLE_GAS_LIMIT' || (err?.data?.message && String(err?.data?.message).indexOf('insufficient funds')))
                notification.error({
                    message: 'Insufficient funds for gas'
                })
            else
                notification.error({
                    message: 'Unstake failed'
                })
        }
        finally {
            setLoadingButton(false)
        }
    }

    useEffect(() => {
        if (account && chainId == SupportedChainId.BLAST)
            initModal()
    }, [account]);

    const initModal = () => {
        dividendContext.loadStakeData()
        handleCheckApproveStakeContract()
    }

    useEffect(() => {
        setAmount(0);
        if (!isShowModal)
            initModal()
        setHasTypedInput(false)
    }, [isShowModal, currentTab]);

    return <Modal maxWidth={592}
        isOpen={isShowModal}
        onDismiss={() => setIsShowModal(false)}
    >
        <div style={{ width: '100%', background: '#1C1B20', position: 'relative' }}>
            <div style={{ position: 'absolute', top: '0', right: '0', padding: '24px 32px' }}>
                <svg style={{ cursor: 'pointer', width: '24px', height: '24px' }} onClick={() => setIsShowModal(false)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.6">
                        <path d="M7.05025 7.35038L16.9497 17.2499M16.9497 7.35038L7.05025 17.2499" stroke="#EFEFE4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                </svg>
            </div>
            <TitleBox>
                <Title>
                    {currentTab == 'STAKE' ? 'Stake for yield' : 'Unstake from dividends'}
                </Title>
                <SubTitle>
                    {currentTab == 'STAKE' ? 'Stake xMONO here to earn a share of protocol earnings in MUSD' : 'Stop earning a share of our protocol’s revenue'}
                </SubTitle>
            </TitleBox>
            <TabList>
                <TabItem isActived={currentTab == 'STAKE'} onClick={() => currentTab == 'UNSTAKE' && setTab('STAKE')}>
                    Stake
                </TabItem>
                <TabItem isActived={currentTab == 'UNSTAKE'} onClick={() => currentTab == 'STAKE' && setTab('UNSTAKE')}>
                    Unstake
                </TabItem>
            </TabList>

            <InputBoxWrapper>
                <InputBoxTitle>
                    Amount
                </InputBoxTitle>

                <InputBox>
                    <InputSelect>
                        <img style={{
                            cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)'
                        }} src='https://raw.githubusercontent.com/Monoswap-Labs/monoswap-token-list/main/img/blast_sepolia/assets/0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055/logo.png' />

                        <BalanceLabel style={{ color: '#EFEFE4', fontSize: '16px' }}>xMONO</BalanceLabel>
                    </InputSelect>
                    <Input type="number" value={amount} onChange={e => {
                        const newVal = e.target.value.replace(/^0+(?!\.)/, '');
                        e.target.value = newVal;
                        setAmount(Number(newVal));
                        setHasTypedInput(true);
                    }} min={0.000001} maxLength={5} />
                </InputBox>

                <InputBalanceBox>
                    {
                        currentTab == 'STAKE' ? <ErrorText>
                            {hasTypedInput && amount > dividendContext.userXmonoBalance ? 'Insufficient balance' : ''}
                        </ErrorText>
                            : <ErrorText>
                                {hasTypedInput && amount > dividendContext.dividendUserInfo.amount ? 'Insufficient balance' : ''}
                            </ErrorText>
                    }

                    {
                        currentTab == 'STAKE' ?

                            <InbutBalanceContent>
                                <MaxButton onClick={() => setAmount(dividendContext.userXmonoBalance)}><span>Max</span></MaxButton>
                                <BalanceLabel>Balance: <span style={{ color: '#EFEFE4' }}>{formatXpNumber(dividendContext.userXmonoBalance)} xMONO</span></BalanceLabel>
                            </InbutBalanceContent>
                            :
                            <InbutBalanceContent>
                                <MaxButton onClick={() => setAmount(dividendContext.dividendUserInfo.amount ?? 0)}><span>Max</span></MaxButton>
                                <BalanceLabel>Balance: <span style={{ color: '#EFEFE4' }}>{formatXpNumber(dividendContext.dividendUserInfo.amount)}  xMONO</span></BalanceLabel>
                            </InbutBalanceContent>
                    }


                </InputBalanceBox>

                {
                    amount > 0 && <>
                        <InputSeperator />
                        <EstimateRewardBox>
                            <BalanceLabel style={{ fontSize: '18px' }}>
                                Estimates
                            </BalanceLabel>
                            <EstimateRewardList>
                                <EstimateRewardItem>
                                    <BalanceLabel style={{ color: '#EFEFE4', fontSize: '16px' }}>
                                        Staking
                                    </BalanceLabel>
                                    <BalanceLabel style={{ fontSize: '16px' }}>{formatXpNumber(dividendUserInfo.amount ?? 0)} -{">"} <span style={{ color: '#EFEFE4' }}>{formatXpNumber(estimatedStakeXmono)} <TextLinear>xMONO</TextLinear></span></BalanceLabel>
                                </EstimateRewardItem>

                                <EstimateRewardItem>
                                    <BalanceLabel style={{ color: '#EFEFE4', fontSize: '16px' }}>
                                        {currentTab == 'STAKE' ? 'Yield' : 'Allocation'} share
                                    </BalanceLabel>
                                    <BalanceLabel style={{ fontSize: '16px' }}>{yieldShare}% -{">"} <span style={{ color: '#EFEFE4' }}>{nextYieldShare}%</span></BalanceLabel>
                                </EstimateRewardItem>

                                <EstimateRewardItem>
                                    <BalanceLabel style={{ color: '#EFEFE4', fontSize: '16px' }}>
                                        Hourly return/xMONO
                                    </BalanceLabel>
                                    <BalanceLabel style={{ fontSize: '16px' }}>${formatXpNumber(rewardPerHour, 4)} -{">"} <span style={{ color: '#EFEFE4' }}>${formatXpNumber(nextRewardPerHour, 4)}</span></BalanceLabel>
                                </EstimateRewardItem>

                                <EstimateRewardItem>
                                    <BalanceLabel style={{ color: '#EFEFE4', fontSize: '16px' }}>
                                        Total daily returns
                                    </BalanceLabel>
                                    <BalanceLabel style={{ fontSize: '16px' }} >${formatXpNumber(rewardPerDay, 4)} -{">"} <span style={{ color: '#EFEFE4' }}>${formatXpNumber(nextRewardPerDay, 4)}</span></BalanceLabel>
                                </EstimateRewardItem>
                                {
                                    currentTab == 'UNSTAKE' && <EstimateRewardItem>
                                        <BalanceLabel style={{ color: '#EFEFE4', fontSize: '16px' }}>
                                            Unstake fee
                                        </BalanceLabel>
                                        <BalanceLabel style={{ fontSize: '16px' }} >{'<'}{formatXpNumber(amount * 0.005, 4)} xMONO</BalanceLabel>
                                    </EstimateRewardItem>
                                }
                            </EstimateRewardList>

                        </EstimateRewardBox>
                    </>
                }
            </InputBoxWrapper>

            <ButtonBox>
                <NotchedButtonBolderNew w='50%' h="48px" bg="#0a090f" borderColor="linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)" cursor="pointer">
                    <ButtonLabel onClick={() => setIsShowModal(false)}>
                        <TextLinear>Cancel</TextLinear>
                    </ButtonLabel>
                </NotchedButtonBolderNew>
                {
                    currentTab == 'STAKE' ? <>
                        {
                            isApproveContract && (amount <= xmonoAllowance) ?
                                <NotchedButtonFill
                                    className="fill-btn"
                                    onClick={depositXmono}
                                    // disabled={dividendContext.userXmonoBalance <= 0 || amount == 0 || amount > dividendContext.userXmonoBalance} 
                                    disabled={true}
                                    width='50%' mobileMaxW="50%">
                                    <ButtonLabel>
                                        {loadingButton ? 'Staking...' : 'Stake'}
                                    </ButtonLabel>
                                </NotchedButtonFill>
                                :
                                <NotchedButtonFill
                                    onClick={handleApproveStakeContract} width='50%' mobileMaxW="50%">
                                    <ButtonLabel>
                                        {loadingApproveContract ? 'Approving...' : 'Approve'}
                                    </ButtonLabel>
                                </NotchedButtonFill>
                        }
                    </>
                        :
                        <NotchedButtonFill
                            className="fill-btn"
                            onClick={unstakeLp}
                            disabled={dividendContext.dividendUserInfo.amount <= 0 || amount == 0 || amount > dividendContext.dividendUserInfo.amount} width='50%' mobileMaxW="50%">
                            <ButtonLabel>
                                {loadingButton ? 'Unstaking...' : 'Unstake'}
                            </ButtonLabel>
                        </NotchedButtonFill>
                }

            </ButtonBox>

        </div>
    </Modal>
}