import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SwapHeader from '../../components/swap/SwapHeader'
import { Trans } from '@lingui/macro'
import { Text } from 'rebass'
import './alpha.css'
import { LP_POOL_V2_ADDRESSES_MAP, MONO_ADDRESSES_MAP } from '../../constants/addresses'
import { useWeb3React } from '@web3-react/core'
import { useTokenContract, useContract, useCustomeContract } from 'hooks/useContract'
import { ethers } from 'ethers'
import DoubleCurrencyLogo, { SingleCurrencyLogo, DoubleCurrencyLogoV2 } from 'components/DoubleLogo'
import { useCurrency, useToken } from '../../hooks/Tokens'
import { alphaPoolAddressV2 } from '../../constants/poolListData'
import { ButtonLight, ButtonPrimary, NotchedButtonFillPrimary, NotchedButtonFill } from '../../components/Button'
import { CloseIcon, CustomLightSpinner, ThemedText } from '../../theme'
import { NotchedButtonBolderNew } from 'components/Button/NotchedButton'
import axios, { Method } from 'axios'
import NonfungiblePositionManagerJson from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'
import { usePositionTokenURI } from '../../hooks/usePositionTokenURI'
import { SupportedChainId } from '../../constants/chains'
import styled, { css, useTheme } from 'styled-components/macro'
import { AutoColumn } from '../../components/Column'
import { AlertTriangle } from 'react-feather'
import { WrongNetworkCard } from './index'
//
// v2
import v2Staker_abi from '../../abis/alpha/V2Staker.json'
import v2PoolFactory_abi from '../../abis/alpha/V2PoolFactory.json'
import { formatXpNumber } from 'pages/Xp/sections/XpTitle'
import { useAlphaTvlMap } from 'state/alphapool/hooks'
import { useBlockNumber, useToggleWalletModal } from 'state/application/hooks'
import { getContract } from 'utils'
import { RPC_URLS } from 'constants/networks'
import dayjs from 'dayjs'
import { PoolV2Info, useAlphaStore } from '../../hooks/useAlphaStore'
import useTokenPrice from 'hooks/useTokenPrice'
import { BASE_API } from 'pages/Xp'

const maestroImg =
  'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/createmaestro.svg'
const reload = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/swap/reload.png'
const search = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/search.svg'
const tag = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/Tag.svg'
const listcard = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/pair2.svg'
const ethIcon = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/musd1.svg'
const requireIcon =
  'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/requireIcon.svg'
const topImage = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/topImage.svg'
const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'


const TextLinear = styled.span`
  font-family: Righteous;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 17.5px */
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export function useMultipleCurrenciesV2(currencyIds: any) {

  const currencies = currencyIds?.map(({ currencyIdA, currencyIdB, _rewardToken, incentiveToken }: any) => {
    const currencyA = useToken(currencyIdA)
    const currencyB = useToken(currencyIdB)
    const rewardToken = useToken(_rewardToken)
    const incentive = useToken(incentiveToken)

    return { currencyA, currencyB, rewardToken, incentive }
  })

  return currencies
}

// get lp of each pool

const headers = {
  'Content-Type': 'application/json',
}
const BASE_URL = 'https://api.monoswap.io'

const AlphaV2 = () => {
  const navigate = useNavigate()
  const { account, chainId } = useWeb3React()
  const [tabSelector, setTabSelector] = useState(2)
  const [customeChainId, setCustomeChainId] = useState(SupportedChainId.BLAST)
  const isSupported = chainId === SupportedChainId.BLAST
  const provider = new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.BLAST][0]);

  const tvlMap = useAlphaTvlMap()
  const alphaStore = useAlphaStore()
  const [poolStatusFilter, setPoolStatusFilter] = useState<'LIVE' | 'ENDED'>('LIVE')

  const [summaryPools, setSummaryPools] = useState<any[]>([])
  // contract pool liquidity
  useEffect(() => {
    if (chainId) {
      setCustomeChainId(chainId)
    } else {
      setCustomeChainId(SupportedChainId.BLAST)
    }
  }, [account, chainId])

  const [loadingV2Info, setLoadingV2Info] = useState(false)
  const [totalPools, setTotalPools] = useState<PoolV2Info[]>([])
  const [endedPools, setEndedPools] = useState<PoolV2Info[]>([])

  const [searchQuery, setSearchQuery] = useState('')

  // search query function
  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value)
  }

  const filteredPoolsV2 = useMemo(() => {
    if (poolStatusFilter == 'LIVE')
      return totalPools.filter((pool) => {
        return pool?.poolName?.toLowerCase().includes(searchQuery?.toLowerCase())
      })
    else return endedPools.filter((pool) => {
      return pool?.poolName?.toLowerCase().includes(searchQuery?.toLowerCase())
    })
  }, [totalPools, searchQuery, endedPools, poolStatusFilter])

  const getV2PoolInfo = async (force = false) => {
    if (alphaStore.totalV2Pools.length > 0 && !force) {
      setTotalPools(alphaStore.totalV2Pools.filter(item => !item.hasEnded))
      setEndedPools(alphaStore.totalV2Pools.filter(item => item.hasEnded))
      return
    }
    setLoadingV2Info(true)
    try {
      await alphaStore.initV2Pools()
      setLoadingV2Info(false)
    } catch (error) {
      console.log('error', error)
    }
  }
  const tokenInfoV2 = useMultipleCurrenciesV2(alphaPoolAddressV2[81457]) ?? []

  const reloadFarmingPool = () => {
    getV2PoolInfo(true)
  }

  useEffect(() => {
    if (totalPools.length > 0)
      fetch(`https://api.geckoterminal.com/api/v2/networks/blast/pools/multi/${totalPools.map(item => item._stakedToken).join(',')}`)
        .then(res => res.json())
        .then(({ data }) => {
          setSummaryPools(data)
        })
  }, [totalPools])

  useEffect(() => {
    if (alphaStore.totalV2Pools.length > 0) {
      setTotalPools(alphaStore.totalV2Pools.filter(item => !item.hasEnded))
      setEndedPools(alphaStore.totalV2Pools.filter(item => item.hasEnded))
    }
  }, alphaStore.totalV2Pools)

  useEffect(() => {
    getV2PoolInfo()
    console.log('tokenInfoV2', tokenInfoV2);
  }, [account, chainId])

  return (
    <>
      <div className="alpha-top-container-container">
      </div>
      <div className="alpha-container">
        <div className="swap-top-container" style={{ alignItems: 'flex-end' }}>
          <div className="swap-top-left" style={{ alignItems: 'flex-end' }}>
            <img src={maestroImg} alt="swap image" />
            <div className="swap-top-div">
              <div className='tab-pool-selector-wrapper'>
                <div className="tab-pool-selector">
                  <NotchedButtonBolderNew w="50px" h="100%" borderColor={'rgba(239, 239, 228, 0.5)'}>
                    <div
                      className={`tab-pool-selector-text ${tabSelector === 1 ? 'tab-pool-active' : ''}`}
                      onClick={() => {
                        navigate('/farming')
                        setTabSelector(1)
                      }}
                    >
                      V3
                    </div>
                  </NotchedButtonBolderNew>
                  <NotchedButtonBolderNew w="50px" h="100%" borderColor={'rgba(239, 239, 228, 0.5)'}>
                    <div
                      className={`tab-pool-selector-text ${tabSelector === 2 ? 'tab-pool-active' : ''}`}
                      onClick={() => {
                        setTabSelector(2)
                      }}
                      style={{ cursor: 'not-allowed' }}
                    >
                      V2
                    </div>
                  </NotchedButtonBolderNew>
                </div>

                <div className='pool-status-filter-box'>
                  <NotchedButtonBolderNew w="80px" h="100%" bg={poolStatusFilter == 'LIVE' ? '#000000' : undefined} disableHover={poolStatusFilter == 'LIVE'} clipPath='unset' borderColor={'linear-gradient(90deg, #68FFFF, #68FF9B)'}>
                    <div className='status-filter-btn' onClick={() => setPoolStatusFilter('LIVE')}>
                      Live
                    </div>
                  </NotchedButtonBolderNew>
                  <NotchedButtonBolderNew w="80px" h="100%" bg={poolStatusFilter == 'ENDED' ? '#000000' : undefined} disableHover={poolStatusFilter == 'ENDED'} clipPath='unset' borderColor={'linear-gradient(90deg, #68FFFF, #68FF9B)'}>
                    <div className='status-filter-btn' onClick={() => setPoolStatusFilter('ENDED')}>
                      Finished
                    </div>
                  </NotchedButtonBolderNew>
                </div>
              </div>
              <p className='page-title'>Pools</p>
              <p className='page-desc'>Deposit liquidity into Farming pools to earn 3 months of additional xMONO emissions.</p>
            </div>
          </div>
          <div className="swap-top-right" style={{ paddingBottom: '10px' }}>
            <div className="maestro-search">
              <img src={search} alt="swap image" />
              <input type="text" onChange={handleSearchChange} />
            </div>
            <div className='alpha-setting'>
              <img src="/farming/reload_icon.png" alt="monoswap" onClick={reloadFarmingPool} />
            </div>
          </div>
        </div>
      </div>

      <div className="maestro-cover" style={{ marginTop: '24px' }}>
        {loadingV2Info ? (
          <div style={{ width: '100%', height: '200px', textAlign: 'center', marginTop: '40px' }}>
            <CustomLightSpinner src={Circle} alt="loader" size="32px" />
          </div>
        ) : (
          <div className="alpha-list-card-container">
            {filteredPoolsV2?.length > 0 &&
              filteredPoolsV2?.map((item: any, index: any) => (<AlphaCardItem alphaInfo={item} index={index} tokenInfoV2={tokenInfoV2} key={index} />
              ))}
          </div>
        )}
        {
          !loadingV2Info && filteredPoolsV2.length == 0 && <p style={{ textAlign: 'center' }}>
            No pool available
          </p>
        }
      </div>
    </>
  )
}

const AlphaCardItem = ({
  alphaInfo,
  tokenInfoV2,
  index
}: any) => {
  const { account } = useWeb3React()
  const toggleWalletModal = useToggleWalletModal()
  const navigate = useNavigate()
  const tvlMap = useAlphaTvlMap()
  const [userTvl, setUserTvl] = useState<number>(0)
  const {
    price: xMonoPriceRes
  } = useTokenPrice(MONO_ADDRESSES_MAP[SupportedChainId.BLAST])

  const xMonoPrice = useMemo(() => xMonoPriceRes == 0 ? 2.1 : xMonoPriceRes, [xMonoPriceRes])

  const poolTvl = useMemo(() => {
    const tvl = tvlMap.get(alphaInfo._stakedToken) ?? 0
    alphaInfo.tvl = tvl
    return tvl
  }, [tvlMap, alphaInfo])

  const apr = useMemo(() => {
    if (alphaInfo) {
      const apr =
        (Number(Number(alphaInfo?._bonusEndBlock.toString()) - Number(alphaInfo?.currentBlockNumber)) *
          Number(alphaInfo?._rewardPerBlock) *
          xMonoPrice *
          365 *
          100) /
        (90 * poolTvl)
      alphaInfo.apr = formatXpNumber(apr)
      return formatXpNumber(apr)
    }
    return 0
  }, [poolTvl])

  const loadUserTvl = async () => {
    fetch(`${BASE_API}/monoswap/api/v1/pool-xp-multiplier/get-alphapool-tvl?incentive_id=${alphaInfo?._stakedToken}&user_address=${account}`)
      .then(res => res.json())
      .then((data: { userTvl: React.SetStateAction<number> }) => {
        alphaInfo.userTvl = data.userTvl
        setUserTvl(data.userTvl)
      })
  }

  useEffect(() => {
    if (account) loadUserTvl()
  }, [account, alphaInfo])

  return <div className="alpha-list-card" style={{ padding: '20px' }}
  >
    <div className="alpha-list-card-tag"></div>
    <div className="alpha-list-card-detail">
      <div className="alpha-list-card-detail-top">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div className="alpha-list-card-detail-top-img">
            <DoubleCurrencyLogo
              currency0={tokenInfoV2[index]?.currencyA ?? undefined}
              currency1={tokenInfoV2[index]?.currencyB ?? undefined}
              size={28}
            />
          </div>
          <div style={{ marginLeft: '10px', textAlign: 'left' }}>
            <p style={{ color: '#efefe4', fontSize: '18px', margin: '0px' }}>
              <span>{tokenInfoV2[index]?.currencyA?.symbol}/
                {tokenInfoV2[index]?.currencyB?.symbol}</span>
            </p>
          </div>
        </div>

        <div style={{ width: '30%' }}>
          <NotchedButtonFill
            onClick={() => {
              if (account)
                navigate(`/farmingV2/detailV2/${alphaInfo?._poolAddress}`, { state: alphaInfo })
              else toggleWalletModal()
            }}
            style={{ margin: '4px 0 0 0' }}
            padding={'7px 5px'}
            height={'28px'}
          >
            <Text fontWeight={500} fontSize={18}>
              <Trans>{account ? alphaInfo?.hasEnded ? 'View' : 'Deposit' : 'Connect'}</Trans>
            </Text>
          </NotchedButtonFill>
        </div>
      </div>

      <div className="alpha-list-card-detai--info">
        <div
          className="alpha-list-card-detai--info-child"
          style={{
            borderTop: '1px solid rgba(239,239,228,0.2)',
          }}
        >
          <p>TVL</p>
          <p style={{ marginLeft: '0 !important' }}>
            {' '}
            <span style={{ color: '#efefe4' }}>
              <p>
                ${formatXpNumber(poolTvl)}

              </p>
            </span>
          </p>
        </div>
        <div className="alpha-list-card-detai--info-child">
          <p>APR</p>
          <p>
            <span style={{ color: '#68FF9B' }}>
              {alphaInfo?.hasEnded ? 0 : formatXpNumber(apr)}%
            </span>
          </p>
        </div>

        <div className="alpha-list-card-detai--info-child">
          <p>Incentives</p>
          <div className="alpha-list-card-detai--info-child-right">
            ${formatXpNumber(alphaInfo?._poolReward)}
          </div>
        </div>
        {/* <div className="alpha-list-card-detai--info-child">
                          <p>Requirements</p>
                          <div className="alpha-list-card-detai--info-child-right">
                            <img src={requireIcon} alt="icon" />
                          </div>
                        </div> */}
        <div className="alpha-list-card-detai--info-child">
          <p>Your deposit</p>
          <p>
            $
            {formatXpNumber(userTvl)}
          </p>
        </div>
        {
          alphaInfo?.hasEnded && <div className="alpha-list-card-detai--info-child">
            <p>Status</p>
            <p style={{ padding: 0, color: '#efefe4' }}>
              ENDED
            </p>
          </div>
        }

        {/* <div className="alpha-list-card-detai--info-child">
                          <p>Pool reward</p>
                          <p>{item?.totalRewardUnclaimed}</p>
                        </div> */}
      </div>
    </div>
  </div>
}

export default AlphaV2
export const numberWithCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
