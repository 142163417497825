import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import ERC_20 from '../abis/erc20.json'
import { ethers } from "ethers";
import { RPC_URLS } from "constants/networks";
import { SupportedChainId } from "constants/chains";
import { getContract } from "utils";
import { useCustomeContract } from "./useContract";


export default function useERC20TokenBalance(tokenAddress: string) {
    const PROVIDER1 = new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.BLAST][0])
    const { account } = useWeb3React()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [balance, setBalance] = useState<number>(0)

    const erc20ContractWithSign = useCustomeContract(tokenAddress, ERC_20, true)
    const erc20Contract = getContract(tokenAddress, ERC_20, PROVIDER1)

    const loadTokenBalance = async () => {
        if (isLoading || !account) return
        try {
            setIsLoading(true)
            const stakeTokenBalance = await erc20Contract?.balanceOf(account)
            let formattedTokenBalance = Number(ethers.utils.formatUnits(stakeTokenBalance, 18)) - 0.00001;
            if (formattedTokenBalance < 0) formattedTokenBalance = 0
            setBalance(formattedTokenBalance)
        }
        catch (err) { }
        finally {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        loadTokenBalance()
    }, [account, erc20ContractWithSign])

    return {
        isLoadingBalance: isLoading,
        contractWithSign: erc20ContractWithSign,
        contractWithNoSign: erc20Contract,
        balance,
        loadTokenBalance
    }
}