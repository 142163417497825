import { useEffect, useState } from "react";




export default function useTokenPrice(address: string) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [price, setPrice] = useState<number>(0)

    useEffect(() => {
        setIsLoading(true)
        fetch(`https://api.geckoterminal.com/api/v2/simple/networks/blast/token_price/${address}`)
            .then(res => res.json())
            .then(data => {
                const price = Number(data.data.attributes.token_prices[address.toLowerCase()] ?? 0);
                setPrice(price)
            })
            .finally(() => setIsLoading(false))
    }, [])

    return {
        isLoading,
        price
    }
}