import styled from "styled-components"
import { useEffect, useMemo, useState } from "react";
import { BREAKPOINTS } from "theme";
import StakeDividendModal from "./StakeDividendModal";
import { CountdownTimer } from "pages/Alpha/AlphaDetail";
import { NotchedButtonBolderNew } from "components/Button/NotchedButton";
import { NotchedButtonFill } from "components/Button";
import { useToggleWalletModal } from "state/application/hooks";
import { useWeb3React } from "@web3-react/core";
import useDividendContext from "../../hooks/useDividendContext";
import { formatXpNumber } from "pages/Xp/sections/XpTitle";
import useMusd from "hooks/useMusd";
import { notification } from "antd";
import dayjs from "dayjs";
import checkUnsupportChainId from "utils/checkUnsupportChainId";

const XmonoPageSectionWrapper = styled.section`
    width: 100%;
    height: 100%;
    padding: 0 10px;
    margin-top: 144px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        margin-top: 44px;
    }
`;

const InfoBox = styled.div`
    width: 100%;
    display: grid;
    justify-content: center;
    gap: 56px;
    /* padding: 0 10px; */
`;

const TitleBox = styled.div`
    display: grid;
    max-width: 600px;
    margin: auto;
    gap: 16px;
    justify-content: center;
    align-content: center;
`;
const Title = styled.h1`
    color: #EFEFE4;
    text-align: center;
    font-family: Righteous;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 60px */
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        justify-content: center;
        font-size: 32px;
        line-height: 40px;
    }
`;
const SubTitle = styled.h4`
    color: #EFEFE4;
    text-align: center;

    /* Urbanist/Body-2/Semi */
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        margin: auto;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
    }
`;
const LinearText = styled.span`
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const ChestInfoBoxBorder = styled.div<{
    clipPath?: string;
    w?: string;
    maxW?: string;
    h?: string;
}>`
    max-width: 1224px;
    width: 1224px;
    height: ${({ h }) => h ?? '100%'} ;
    position: relative;
    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
    }
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        max-width: 90%;
        width: 100%;
        margin: auto;
        padding: 0 80px;
    }
    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        padding: 0;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        background: #efefe40f; 
        clip-path: polygon(27.3px 0, 100% 0, 100% calc(100% - 27.3px), calc(100% - 27.3px) 100%, 0 100%, 0 27.3px);
        .bg{
            display: none;
        }
    }
  `;

const ChestInfoStatsBox = styled.div`
    padding: 20px;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 900px){
        max-width: 100%;
        scale: 0.8;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        scale: 1;
        gap: 10px;
        padding: 20px 10px;
        flex-wrap: wrap;
    }
`;
const ChestInfoStatsItem = styled.div`
    width: 100%;
    min-width: 140px;
    display: grid;
    gap: 8px;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        width: calc(40% - 2px);
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        min-width: unset;
        /* width: 130px; */
    }
`;

const ChestInfoStatsSeparator = styled.div<{
    isShowOnMobile?: boolean;
}>`
    width: 1px;
    height: 56px;
    background: #efefe41c;
    backdrop-filter: blur(4.800000190734863px);
    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        display: ${({ isShowOnMobile }) => isShowOnMobile ? 'block' : 'none'};
    }
`;

const LabelText = styled.span`
    color: rgba(239, 239, 228, 0.50);
    text-align: center;
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
  opacity: 0.5;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 13px;
    line-height: 15.5px;
  }
`;

const ContentText = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Righteous;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 24px */
    text-transform: uppercase;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        font-size: 18px;
        line-height: 22.5px;
    }
`;

const StakeInfoBox = styled.div`
    display: flex;
    gap: 33px;
    max-width: 1223px;
    margin: 64px auto;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        flex-direction: column;
        align-items: center;
    }
`
const RewardBox = styled.div`
    width: 50%;
    background: rgba(239, 239, 228, 0.03);
    backdrop-filter: blur(4.800000190734863px);
    border: 1px solid #efefe480;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        width: 90%;
    }
`;
const RewardTop = styled.div`
    background: rgba(239, 239, 228, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 36px;
    padding-right: 23px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding-left: 18px;
        padding-right: 12px;
    }
`;

const RewardTopTitle = styled.h3`
    color: #EFEFE4;
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 30px */
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        font-size: 18px;
    }
`;
const NextEpochBox = styled.div`
    display: flex;
    flex-direction: column;
`;
const NextEpochBoxTitle = styled.h5`
    color: rgba(239, 239, 228, 0.50);
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
    opacity: 0.5;
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        font-size: 13px;
    }
`;
const NextEpochBoxContent = styled.div`
    p{
        margin: 0;
        color: #FFB800;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        font-size: 10px;
    }
`

const RewardBottom = styled.div`
    padding: 35px 36px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding: 18px;
        .fill-btn, .border-btn{
            height: 40px;
            span{
                font-size: 14px;
            }
        }
    }
`
const RewardBottomList = styled.div`
    display: grid;
    gap: 12px;
    margin-bottom: 32px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        margin-bottom: 16px;
    }
`;

const RewardBottomItem = styled.div`
    display: flex;
    justify-content: space-between;
   
`;
const RewardBottomItemLabel = styled(NextEpochBoxTitle)`
    font-size: 16px;
`;
const RewardBottomItemContent = styled.span`
    color: #EFEFE4;
    text-align: right;
    font-family: Urbanist;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 26.25px */

    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        font-size: 16px;
    }
`;

const ButtonLabel = styled.span`
    width: 100%;
    height: 100%;
    text-align: center;
    /* Righteous/Button */
    font-family: Righteous;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 18px */
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
`;



export default function DividendPage() {
    const { account, chainId } = useWeb3React();
    const dividendContext = useDividendContext()
    const { epochTime, totalReward, pendingReward, currentEpoch, totalStakedXmono, dividendUserInfo, xMonoPrice, dividendContractWithSign, loadPendingReward } = dividendContext;

    const toggleWalletModal = useToggleWalletModal();
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [currentTab, setTab] = useState<'STAKE' | 'UNSTAKE'>('STAKE');
    const { musdPrice } = useMusd()
    const [isWithdrawing, setIsWithdrawing] = useState<boolean>(false)
    const [hasStarted, setHasStarted] = useState<boolean>(false)


    const onClickClaimAll = async () => {
        checkUnsupportChainId(chainId)
        if (account) {
            if (pendingReward > 0 && !isWithdrawing) {
                try {
                    setIsWithdrawing(true)
                    const tx = await dividendContractWithSign?.withdraw(0, {
                        gasLimit: 200000
                    })
                    await tx.wait()

                    notification.success({
                        message: "Claim reward success"
                    })
                    await loadPendingReward()
                }
                catch (err) {
                    notification.error({
                        message: "Claim reward failed"
                    })
                }
                finally {
                    setIsWithdrawing(false)
                }
            }
        }
        else toggleWalletModal();
    }

    const onClickAllocate = () => {
        checkUnsupportChainId(chainId)
        if (!hasStarted) return
        if (account) {
            setTab('STAKE');
            setIsShowModal(true);
        }
        else toggleWalletModal();
    }

    const dividendShare = useMemo(() => {
        const rs = ((dividendUserInfo.amount / totalStakedXmono) * 100)
        if (rs == 0) return 0
        return rs < 1 ? rs.toFixed(4) : rs.toFixed(2)
    }, [totalStakedXmono, dividendUserInfo])

    const earningXmono = useMemo(() => {
        const rs = ((currentEpoch.totalRw * musdPrice) / totalStakedXmono)
        return rs == Number.NEGATIVE_INFINITY || rs == Number.POSITIVE_INFINITY || isNaN(rs) ? 0 : rs
    }, [totalStakedXmono, musdPrice, currentEpoch])

    const apr = useMemo(() => {
        const rs = Number(((currentEpoch.totalRw * 365 * 100) / (7 * totalStakedXmono * xMonoPrice)).toFixed(2));
        console.log('apr: ', totalStakedXmono, currentEpoch, xMonoPrice);
        
        return Number.isNaN(rs) || rs == Number.NEGATIVE_INFINITY || rs == Number.POSITIVE_INFINITY ? 0 : rs < 0 ? 0 : rs
    }, [totalStakedXmono, currentEpoch, xMonoPrice])

    const startTime = dayjs.utc('2024-06-23 13:00:00')
    useEffect(() => {
        if (dayjs.utc().isAfter(startTime))
            setHasStarted(true)
    }, [])

    return <XmonoPageSectionWrapper>
        <InfoBox>
            <TitleBox>
                <Title>
                    <span>Dividends - </span>
                    <LinearText>Earn real</LinearText>
                    <br />
                    <LinearText>yield</LinearText>
                    <span> weekly</span>
                </Title>
                <SubTitle>
                    Stake xMONO here to earn a share of protocol earnings in MUSD
                </SubTitle>
            </TitleBox>

            <ChestInfoBoxBorder>
                <img className="bg" src="/dividend/info-bg.png" alt="monoswap" />
                <ChestInfoStatsBox>
                    <ChestInfoStatsItem>
                        <LabelText>
                            Total staked
                        </LabelText>
                        <ContentText>
                            {hasStarted ? formatXpNumber(totalStakedXmono) : 0}
                        </ContentText>
                    </ChestInfoStatsItem>

                    <ChestInfoStatsSeparator isShowOnMobile={true} />

                    <ChestInfoStatsItem>
                        <LabelText>
                            Deallocation fee
                        </LabelText>
                        <ContentText>
                            0.5%
                        </ContentText>
                    </ChestInfoStatsItem>

                    <ChestInfoStatsSeparator />

                    <ChestInfoStatsItem>
                        <LabelText>
                            Total Distributed
                        </LabelText>
                        <ContentText>
                            ${formatXpNumber(totalReward * musdPrice)}
                        </ContentText>
                    </ChestInfoStatsItem>

                    <ChestInfoStatsSeparator isShowOnMobile={true} />

                    <ChestInfoStatsItem>
                        <LabelText>
                            Earnings/xMONO
                        </LabelText>
                        <ContentText>
                            ${formatXpNumber(earningXmono)}
                        </ContentText>
                    </ChestInfoStatsItem>

                    <ChestInfoStatsSeparator isShowOnMobile={false} />

                    <ChestInfoStatsItem>
                        <LabelText>
                            APR
                        </LabelText>
                        <ContentText>
                            {formatXpNumber(apr)}%
                        </ContentText>
                    </ChestInfoStatsItem>
                </ChestInfoStatsBox>
            </ChestInfoBoxBorder>
        </InfoBox>

        <StakeInfoBox>
            <RewardBox>
                <RewardTop>
                    <RewardTopTitle>
                        Rewards
                    </RewardTopTitle>
                    <NextEpochBox>
                        <NextEpochBoxTitle>
                            {hasStarted ? 'Next epoch' : 'Starting in'}
                        </NextEpochBoxTitle>
                        <NextEpochBoxContent>
                            <CountdownTimer target={hasStarted ? epochTime : Math.floor(startTime.valueOf() / 1000)} />
                        </NextEpochBoxContent>
                    </NextEpochBox>
                </RewardTop>

                <RewardBottom>
                    <RewardBottomList>

                        <RewardBottomItem>
                            <RewardBottomItemLabel>
                                Epoch rewards
                            </RewardBottomItemLabel>

                            <RewardBottomItemContent>
                                {currentEpoch.totalRw} MUSD
                            </RewardBottomItemContent>
                        </RewardBottomItem>

                        <RewardBottomItem>
                            <RewardBottomItemLabel>
                                Pending rewards
                            </RewardBottomItemLabel>

                            <RewardBottomItemContent>
                                {formatXpNumber(pendingReward)} MUSD
                            </RewardBottomItemContent>
                        </RewardBottomItem>

                    </RewardBottomList>

                    <NotchedButtonBolderNew w="100%" h="48px" bg="#0a090f" borderColor="linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)" cursor="pointer">
                        <ButtonLabel onClick={onClickClaimAll}>
                            <LinearText>
                                {account ? isWithdrawing ? 'Claiming...' : 'Claim all' : 'Connect to claim'}
                            </LinearText>
                        </ButtonLabel>
                    </NotchedButtonBolderNew>
                </RewardBottom>

            </RewardBox>

            <RewardBox>
                <RewardTop>
                    <RewardTopTitle>
                        Your Allocation
                    </RewardTopTitle>
                </RewardTop>

                <RewardBottom>
                    <RewardBottomList>

                        <RewardBottomItem>
                            <RewardBottomItemLabel>
                                Allocated
                            </RewardBottomItemLabel>

                            <RewardBottomItemContent>
                                <LinearText>
                                    {formatXpNumber(dividendUserInfo.amount)} xMONO
                                </LinearText>
                            </RewardBottomItemContent>
                        </RewardBottomItem>

                        <RewardBottomItem>
                            <RewardBottomItemLabel>
                                Dividends share
                            </RewardBottomItemLabel>

                            <RewardBottomItemContent>
                                {dividendShare}%
                            </RewardBottomItemContent>
                        </RewardBottomItem>
                    </RewardBottomList>

                    <NotchedButtonFill className="fill-btn" width='100%' disabled={!hasStarted}>
                        <ButtonLabel onClick={onClickAllocate}>
                            {account ? 'ALLOCATE' : 'Connect Wallet'}
                        </ButtonLabel>
                    </NotchedButtonFill>
                </RewardBottom>

            </RewardBox>

        </StakeInfoBox>

        <StakeDividendModal
            dividendContext={dividendContext}
            isShowModal={isShowModal}
            setIsShowModal={setIsShowModal}
            currentTab={currentTab}
            setTab={setTab}
        />
    </XmonoPageSectionWrapper>
}