import { useWeb3React } from "@web3-react/core";
import { Tooltip } from "antd";
import { NotchedButtonFill } from "components/Button";
import useCountdownTimer from "hooks/useCountdownTimer";
import { formatXpNumber } from "pages/Xp/sections/XpTitle";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useToggleWalletModal } from "state/application/hooks";
import styled from "styled-components"
import { BREAKPOINTS } from "theme";


const TradingContestSection1Wrapper = styled.div`
    position: relative;
    max-width: 1224px;
    margin: auto;
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        max-width: 90%;
    }
`

const LinearText = styled.span`
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const Text = styled.span<{
    color?: string;
    fontSize?: string;
}>`
    color: ${({ color }) => color ?? 'rgba(239, 239, 228, 0.50)'};
    font-family: Urbanist;
    font-size: ${({ fontSize }) => fontSize ?? '16px'};;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 20px */
`
const Img = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const TradingContestSection1Body = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    gap: 232px;
    @media screen and (max-width: ${BREAKPOINTS.xxl}px){
        gap: 50px;
    }

    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        display: grid;
    }
`

const TradingContestSection1Left = styled.div`
    display: grid;
    gap: 0;
`

const InfoBox = styled.div`
    width: 100%;
    display: grid;
    justify-content: center;
    gap: 56px;
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        gap: 30px;
    }
`;

const Title = styled.h1`
    color: #EFEFE4;
    text-align: center;
    font-family: Righteous;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 60px */
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        justify-content: center;
        font-size: 32px;
        line-height: 40px;
    }
`;
const SubTitle = styled.h4`
    color: #EFEFE4;
    text-align: center;

    /* Urbanist/Body-2/Semi */
    font-family: Urbanist;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        margin: auto;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
`;


const RewardListBox = styled.div`
    display: grid;
    gap: 12px;
    margin-top: 40px;
    margin-bottom: 56px;
    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        justify-items: center;
        margin-bottom: 30px;
    }
`
const RewardItem = styled.div`
    max-width: 390px;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    position: relative;
    padding: 29px 61px;
    span{
        position: relative;
        z-index: 2;
        text-align: center;
        font-family: Righteous;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 36px */
        text-transform: uppercase;
        @media screen and (max-width: ${BREAKPOINTS.sm}px){
            font-size: 20px;
        }
    }
    .token-icon{
        position: relative;
        z-index: 2;
    }
    @media screen and (max-width: 400px){
        padding: 0;
    }
`
const TradingContestSection1Right = styled.div` 
    display: grid;
    gap: 40px;
    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        justify-items: center;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        img{
            width: 100%;
            height: 100%;
        }
    }
    .section_img{
        max-width: 600px;
        max-height: 490px;
        @media screen and (max-width: ${BREAKPOINTS.sm}px){
            width: 355px;
            height: 290px;
        }
    }
`
const RemainTimeBoxWrapper = styled.div`
    display: grid;
    justify-items: center;
    gap: 24px;
`
const RemainTimeBox = styled.div`
    display: flex;
    gap: 25px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        gap: 10px;
    }
    .time_separator{
        margin-top: 20px;
        color: #EFEFE4;
        text-align: center;
        font-family: Urbanist;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 30px */
        opacity: 0.5;
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            margin-top: 7px;
        }
    }
`
const TimeBoxWrapper = styled.div`
    display: grid;
    gap: 12px;
    justify-items: center;
    min-width: 68px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        min-width: 40px;
    }
`
const TimeBox = styled.div`
    width: 100%;
    max-width: 148px;
    display: grid;
    justify-content: center;
    align-items: center;
    justify-items: center;
    padding: 10px 15px;
    background: rgba(239, 239, 228, 0.15);
    font-weight: 700;
    font-size: 32px;
    font-family: Righteous;
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        padding: 10px 20px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        span{
            font-size: 16px;
        }
    }
`

export default function TradingContestSection1({ startDate, endDate, reward }: { startDate: number, endDate: number; reward?: any }) {
    const { account } = useWeb3React()
    const toggleWalletModal = useToggleWalletModal()
    const [time, setTime] = useState<number>(0)
    const { days, hours, minutes, seconds } = useCountdownTimer('DAY', time)
    const hasStarted = useMemo(() => Date.now() / 1000 > startDate, [startDate])
    const hasEnded = (Date.now() / 1000) > 1719234000

    useEffect(() => {
        if (Date.now() / 1000 > startDate) setTime(endDate)
        else setTime(startDate)
    }, [startDate, endDate])

    return <TradingContestSection1Wrapper>

        <TradingContestSection1Body>
            <TradingContestSection1Left>
                <InfoBox>
                    <Title>
                        MONO TRADING <br />
                        <LinearText> TOURNAMENT  </LinearText>
                    </Title>
                    <SubTitle>
                        👉  Earn Triple Rewards <br /> within a swap now!   👈
                    </SubTitle>
                </InfoBox>

                <RewardListBox>
                    <RewardItem>
                        <Img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/reward-item-border.png" />
                        <Tooltip title="MUSD">
                            <img className="token-icon" style={{
                                cursor: 'pointer', width: '32px', height: '32px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)'
                            }} src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musd.svg' />
                        </Tooltip>
                        <LinearText>100,000</LinearText>
                        <LinearText>MUSD</LinearText>
                    </RewardItem>

                    <RewardItem>
                        <Img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/reward-item-border.png" />
                        <Tooltip title="Blast Point">
                            <img className="token-icon" style={{
                                cursor: 'pointer', width: '32px', height: '32px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)'
                            }} src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chain-logo/blast.png' />
                        </Tooltip>

                        <LinearText>100,000,000</LinearText>
                        <LinearText>POINTS</LinearText>
                    </RewardItem>

                    <RewardItem>
                        <Img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/reward-item-border.png" />
                        <Tooltip title="Blast Gold">
                            <img className="token-icon" style={{
                                cursor: 'pointer', width: '32px', height: '32px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)'
                            }} src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/blast-gold-icon.png' />
                        </Tooltip>
                        <LinearText>10,000</LinearText>
                        <LinearText>Gold</LinearText>
                    </RewardItem>
                </RewardListBox>

                {
                    !account ?
                        <div onClick={toggleWalletModal} style={{ textDecoration: 'none', display: 'block', width: '100%', maxWidth: '390px', margin: 'auto' }}>
                            <NotchedButtonFill >
                                CONNECT WALLET
                            </NotchedButtonFill>
                        </div>
                        :
                        <Link to='swap' style={{ textDecoration: 'none', display: 'block', width: '100%', maxWidth: '390px', margin: 'auto' }}>
                            <NotchedButtonFill >
                                UNLOCK REWARDS
                            </NotchedButtonFill>
                        </Link>
                }

            </TradingContestSection1Left>

            <TradingContestSection1Right>
                <div className="section_img">
                    <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/img-section1.png" />
                </div>

                <RemainTimeBoxWrapper>
                    {
                        hasEnded ? <Text color="#FFB800">The contest has ended</Text>
                            :
                            <>
                                {
                                    hasStarted ? <Text color="#FFB800">Ending In</Text> : <Text color="#FFB800">Starting In</Text>
                                }</>
                    }
                    <RemainTimeBox>
                        <TimeBoxWrapper>
                            <TimeBox>
                                <LinearText>{days < 0 ? '-' : days}</LinearText>
                            </TimeBox>
                            <Text className="time_suffix">day{days > 1 && ''}</Text>
                        </TimeBoxWrapper>
                        <Text className="time_separator">:</Text>
                        <TimeBoxWrapper>
                            <TimeBox>
                                <LinearText>{hours < 0 ? '-' : hours}</LinearText>
                            </TimeBox>
                            <Text className="time_suffix">hour{hours > 1 && 's'}</Text>
                        </TimeBoxWrapper>
                        <Text className="time_separator">:</Text>
                        <TimeBoxWrapper>
                            <TimeBox>
                                <LinearText>{minutes < 0 ? '-' : minutes}</LinearText>
                            </TimeBox>
                            <Text className="time_suffix">minute{minutes > 1 && 's'}</Text>
                        </TimeBoxWrapper>
                        <Text className="time_separator">:</Text>
                        <TimeBoxWrapper>
                            <TimeBox>
                                <LinearText>{seconds < 0 ? '-' : seconds}</LinearText>
                            </TimeBox>
                            <Text className="time_suffix">second{seconds > 1 && 's'}</Text>
                        </TimeBoxWrapper>

                    </RemainTimeBox>
                </RemainTimeBoxWrapper>

            </TradingContestSection1Right>
        </TradingContestSection1Body>
    </TradingContestSection1Wrapper>
}