import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { notification } from 'antd'
import Modal from 'components/Modal/index'
import { ethers } from 'ethers'
import { useCustomeContract } from 'hooks/useContract'
import { useEffect, useMemo, useState } from 'react'
import { Text } from 'rebass'
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'

import monochest_abi from '../../../abis/monochest/monochest.json'
import monoNft_abi from '../../../abis/monochest/mononft.json'
import musd_abi from '../../../abis/musd/musd.json'
import usdb_abi from '../../../abis/musd/usdb.json'
import { NotchedButtonFill } from '../../../components/Button'
import {
  MONO_CHEST_ADDRESSES_MAP,
  MONO_CHEST_NFT_ADDRESSES_MAP,
  MUSD_ADDRESSES_MAP,
  USDB_ADDRESSES_MAP,
} from '../../../constants/addresses'
import { CloseIcon, CustomLightSpinner } from '../../../theme'
import ChestOpenAnimationModal from './ChestOpenAnimationModal'
import { SupportedChainId } from 'constants/chains'
import { getContract } from 'utils'
import { RPC_URLS } from 'constants/networks'
import checkUnsupportChainId from 'utils/checkUnsupportChainId'

const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'

const BuyChestWrapper = styled.div`
  max-width: 592px;
  padding: 24px 32px;
  /* border: 1px solid gray; */
  background: #1c1b20;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    padding: 10px 10px;
  }
`
const ChestTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const ChestBoxTitle = styled.div`
  color: #efefe4;
  font-family: Righteous;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 30px */
  width: calc(100% - 24px);
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 18px;
  }
`

const ChestImgBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 10px;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    flex-direction: column;
  }
`

const ChestImg = styled.img`
  position: relative;
  z-index: 1;
  max-width: 224px;
  max-height: 193px;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    max-width: 250px;
    max-height: 220px;
  }
`
const ChestCntText = styled.p`
  font-family: Urbanist;
  height: 40px;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 26.25px */
  background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
`

const AmountBoxWrapper = styled.div``
const AmountInputBox = styled.div`
  display: flex;
  height: 42px;
`
const AmountInputInDecrease = styled.div`
  width: 70px;
  height: 100%;
  background: rgba(239, 239, 228, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(239, 239, 228, 0.5);
  text-align: center;
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;

  line-height: 125%; /* 30px */
`
const AmountInput = styled.input`
  color: #efefe4;
  font-family: Urbanist;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 26.25px */
  background: #efefe405;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  padding-right: 24px;
  text-align: center;
`

const AmountBoxTitle = styled.h5`
  color: #efefe4;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 80%; /* 15px */
  text-transform: uppercase;
  opacity: 0.7;
`
const PaymentBoxWrapper = styled.div`
  margin-bottom: 10px;
`
interface PaymentBoxProps {
  isSelected: boolean
}
const PaymentBox = styled.div<PaymentBoxProps>`
  width: calc(100% - 6px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; 
  border: 1px solid transparent;
  transition: border-color 0.3s ease;
  margin-bottom: 7px;
  &:hover {
    border-color: #68ffbc;
    cursor: pointer;
  }
  ${({ isSelected }: any) =>
    isSelected &&
    `
  border-color: #68ffbc;
`}
`

const PaymentBoxItemLeft = styled.div`
  background: rgba(239, 239, 228, 0.05);
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 12px;
  position: relative;
  span {
    color: #efefe4;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 20px */
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    span {
      font-size: 14px;
    }
  }
`

const PaymentBoxItemRight = styled(PaymentBoxItemLeft)`
  background: #efefe405;
  width: 50%;
  text-align: center;
  padding-left: 20%;
  span{
    font-size: 21px;
  }
`
const BalanceBox = styled.p`
  font-size: 14px;
  margin: 3px 0px;
  width: 100%;
  text-align: left;
  span {
    color: rgba(239, 239, 228, 0.5);
  }
`
interface BuyChestModalInterface {
  isOpen: boolean
  onDismiss: () => void
  data: any
  musdPrice: any
  getContractInfo: () => void
  loaddingApproveNftContract: any
  checkApproveNftContract: any
  handleApproveNftContract: any
  loadNftKeyBalance: any,
  userNftBalance: number
}
const usdbIcon = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/usdb.svg'

export default function BuyChestModal({
  isOpen,
  onDismiss,
  data,
  musdPrice,
  getContractInfo,
  loaddingApproveNftContract,
  checkApproveNftContract,
  handleApproveNftContract,
  loadNftKeyBalance,
  userNftBalance,
}: BuyChestModalInterface) {
  // state
  const { account, chainId } = useWeb3React()

  const [amount, setAmount] = useState(1)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [loadingApproveUsdbContract, setLoadingApproveUsdbContract] = useState(false)
  const [checkApproveUsdbContract, setCheckApproveUsdbContract] = useState(false)
  const [loadingApproveMusdContract, setLoadingApproveMusdContract] = useState(false)
  const [checkApproveMusdContract, setCheckApproveMusdContract] = useState(false)

  const [loadingBalance, setLoadingBalance] = useState(false)
  const [userUsdbBalance, setUserUsdbBalance] = useState('0')
  const [userMusdBalance, setUserMusdBalance] = useState('0')

  const [loadingBuyChest, setLoadingBuyChest] = useState(false)
  const [isBuySuccess, setIsBuySuccess] = useState(false)
  const [isShowOpenAnimation, setIsShowOpenAnimation] = useState(false)

  const usdbContractWithSign = useCustomeContract(USDB_ADDRESSES_MAP[SupportedChainId.BLAST], usdb_abi, true)
  const musdContractWithSign = useCustomeContract(MUSD_ADDRESSES_MAP[SupportedChainId.BLAST], musd_abi, true)

  const musdContract = getContract(MUSD_ADDRESSES_MAP[SupportedChainId.BLAST], musd_abi, new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.BLAST][2]))
  const usdbContract = getContract(USDB_ADDRESSES_MAP[SupportedChainId.BLAST], usdb_abi, new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.BLAST][2]))

  const monochest = useCustomeContract(MONO_CHEST_ADDRESSES_MAP[SupportedChainId.BLAST], monochest_abi, true)

  const [rewardList, setRewardList] = useState<any>([])

  const getUserBalance = async () => {
    setLoadingBalance(true)
    try {
      const userUsdb = await usdbContract?.balanceOf(account)
      const userMusd = await musdContract?.balanceOf(account)
      // usdb
      const formmattedUsdbUserBalance = Number(ethers.utils.formatUnits(userUsdb, 18)).toFixed(2).toString()
      setUserUsdbBalance(formmattedUsdbUserBalance ?? 0)
      // musd
      const formmattedMusdUserBalance = Number(ethers.utils.formatUnits(userMusd, 18)).toFixed(2).toString()
      setUserMusdBalance(formmattedMusdUserBalance ?? 0)
      setLoadingBalance(false)
    } catch (error) {
      console.log('getUserBalance error', error)
      setLoadingBalance(false)
    }
  }
  const increaseAmount = () => {
    setAmount((prevAmount) => {
      if (prevAmount === 1) {
        return prevAmount + 4
      } else if (prevAmount === 5) {
        return prevAmount + 5
      } else if (prevAmount === 10) return (prevAmount = 20)
      else if (prevAmount === 20) return (prevAmount = 50)
      else if (prevAmount === 50) return (prevAmount = 100)
      else if (prevAmount === 100) return (prevAmount = 100)
      return prevAmount
    })
  }
  const decreaseAmount = () => {
    setAmount((prevAmount) => {
      if (prevAmount < 2) {
        return (prevAmount = 1)
      } else if (prevAmount === 5) {
        return prevAmount = 1
      } else if ((prevAmount === 10)) return prevAmount = 5
      else if ((prevAmount === 20)) return prevAmount = 10
      else if ((prevAmount === 50)) return prevAmount = 20
      else if ((prevAmount === 100)) return prevAmount = 50
      return prevAmount
    })
  }
  const handlePaymentMethodSelect = (method: any) => {
    setSelectedPaymentMethod(method)
  }

  const musdCost = useMemo(() => (amount * data.usdbPrice) / musdPrice, [selectedPaymentMethod, amount, musdPrice, data])
  const musdDiscount = useMemo(() => musdCost * 0.05, [musdCost])
  const finalMusdCost = useMemo(() => musdCost - musdDiscount, [musdDiscount])

  const usdbCost = useMemo(() => {
    return amount * data.usdbPrice
  }, [selectedPaymentMethod, amount, musdPrice, data])

  // approve usdb
  const approveUsdbContract = async () => {
    checkUnsupportChainId(chainId)
    if (loadingApproveUsdbContract) return
    setLoadingApproveUsdbContract(true)
    try {
      const setApproveContract = await usdbContractWithSign?.approve(
        MONO_CHEST_ADDRESSES_MAP[SupportedChainId.BLAST],
        ethers.constants.MaxUint256
      )
      await setApproveContract.wait()
      await handleCheckApproveUsdbContract()
      setLoadingApproveUsdbContract(false)
      return true
    } catch (error) {
      console.log(error)
      setLoadingApproveUsdbContract(false)
      return false
    }
  }

  const handleCheckApproveUsdbContract = async () => {
    try {
      const allowance = await usdbContract?.allowance(account, MONO_CHEST_ADDRESSES_MAP[SupportedChainId.BLAST])
      const formmatedAllowance = ethers.utils.formatUnits(allowance, 18)

      if (Number(formmatedAllowance) >= Number(amount)) {
        setCheckApproveUsdbContract(true)
      } else {
        setCheckApproveUsdbContract(false)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleApproveUsdbContract = async () => {
    await approveUsdbContract()
    handleCheckApproveUsdbContract()
  }
  // approve MUSD
  const approveMusdContract = async () => {
    checkUnsupportChainId(chainId)
    if (loadingApproveMusdContract) return
    setLoadingApproveMusdContract(true)
    try {
      const setApproveContract = await musdContractWithSign?.approve(
        MONO_CHEST_ADDRESSES_MAP[SupportedChainId.BLAST],
        ethers.constants.MaxUint256
      )
      await setApproveContract.wait()
      await handleCheckApproveMusdContract()
      setLoadingApproveMusdContract(false)

      return true
    } catch (error) {
      console.log(error)
      setLoadingApproveMusdContract(false)
      return false
    }
  }

  const handleCheckApproveMusdContract = async () => {
    try {
      const allowance = await musdContract?.allowance(account, MONO_CHEST_ADDRESSES_MAP[SupportedChainId.BLAST])
      const formmatedAllowance = ethers.utils.formatUnits(allowance, 18)

      if (Number(formmatedAllowance) >= Number(amount)) {
        setCheckApproveMusdContract(true)
      } else {
        setCheckApproveMusdContract(false)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleApproveMusdContract = async () => {
    await approveMusdContract()
    handleCheckApproveMusdContract()
  }

  // buy box

  // monoChest.openChest(amount, chestType, paymentToken, false);
  // amount: 0=> 10
  // chestType: 0 => 4,
  // paymentToken: 0 - USDB, 1 - MUSD, 2 - Key
  // gasLimit set = amount * 100000

  const handleBuyChest = async (event: string) => {
    checkUnsupportChainId(chainId)
    if (loadingBuyChest) return
    setLoadingBuyChest(true)
    setIsShowOpenAnimation(true)
    try {
      let buyChestAmount = Number(amount)
      const chestType = Number(data.level - 1)
      let paymentToken
      switch (event) {
        case 'MUSD':
          paymentToken = 1
          break
        case 'USDB':
          paymentToken = 0
          break
        case 'Key NFT':
          paymentToken = 2
          buyChestAmount = userNftBalance
          break
        default:
          paymentToken = 0
      }


      const gasEstimate = await monochest?.estimateGas.openChest(buyChestAmount, chestType, paymentToken, false, {
        value: data.openFee
      });
      const gasLimitCalc = Math.floor(gasEstimate ? gasEstimate.toNumber() * 1.5 : amount * 200000)

      const tx = await monochest?.openChest(buyChestAmount, chestType, paymentToken, false, {
        gasLimit: gasLimitCalc,
        value: data.openFee
      })

      const txLog = await tx.wait()

      if (txLog?.status != '0') {
        getContractInfo()
        getUserBalance()
        setLoadingBuyChest(false)
        const monochestABIInter = new ethers.utils.Interface(monochest_abi);
        const rewardListRes: {
          type: string;
          amount: string | number
        }[] = []

        const eventLogs = txLog.events
        for (let logIndex = 0; logIndex < eventLogs.length; logIndex++) {
          const eventLog: any = eventLogs[logIndex];
          try {
            const parsedLog = monochestABIInter.parseLog(eventLog);
            if (!parsedLog) continue;
            if (parsedLog.name == 'ChestOpened') {
              const [_, amountXmono, amountUsd, amountTicketCnt, hasMonoPass] = parsedLog.args;
              const xmono = Number(ethers.utils.formatUnits(amountXmono.toString(), 18))
              if (xmono > 0) rewardListRes.push({
                type: 'xmono',
                amount: xmono
              });

              const musd = Number(ethers.utils.formatUnits(amountUsd.toString(), 18))
              if (musd > 0) rewardListRes.push({
                type: 'musd',
                amount: musd
              });

              const ticketCnt = amountTicketCnt.toString();
              rewardListRes.push({
                type: 'ticket',
                amount: ticketCnt
              });
              if (hasMonoPass) rewardListRes.push({
                type: 'monopass',
                amount: 1
              });
            }
          }
          catch (logErr) {
            console.log('event log err: ', logErr);

          }
        }
        setRewardList(rewardListRes)
        setIsBuySuccess(true)
      }
      else throw "Tx failed"
      setLoadingBuyChest(false)
      setIsBuySuccess(true)
      if (event === 'USDB')
        handleCheckApproveUsdbContract()
      else if (event === 'MUSD')
        handleCheckApproveMusdContract()
      else if (event === 'Key NFT') { loadNftKeyBalance(); setSelectedPaymentMethod(null) }
    } catch (err) {
      console.log('buy error', err)
      setLoadingBuyChest(false)
      setIsBuySuccess(false)
      setIsShowOpenAnimation(false)
      if (err?.code == 'UNPREDICTABLE_GAS_LIMIT' || (err?.data?.message && String(err?.data?.message).indexOf('insufficient funds')))
        notification.error({
          message: 'Insufficient funds for gas'
        })
      else notification.error({
        message: "Open chest failed"
      })
    }
    finally {
      if (event == 'Key NFT')
        onDismiss()
    }
  }

  useEffect(() => {
    if (account !== null && account !== undefined) {
      handleCheckApproveUsdbContract()
      handleCheckApproveMusdContract()
    }
  }, [account, chainId, amount])
  useEffect(() => {
    if (account !== null && account !== undefined) {
      getUserBalance()
    }
  }, [account, chainId])

  useEffect(() => {
    if (isOpen) getUserBalance()
  }, [isOpen])

  useEffect(() => {
    if (!isOpen) {
      setAmount(1)
      setSelectedPaymentMethod(null)
    }
    else if (isOpen && userNftBalance > 0) {
      setAmount(userNftBalance)
      handleBuyChest('Key NFT')
    }
  }, [isOpen])

  return (
    <>
      <Modal isOpen={isOpen} onDismiss={() => { onDismiss(); setAmount(1) }} scrolloverlay>
        <BuyChestWrapper>
          <ChestTitleBox>
            <ChestBoxTitle>Buy {data.chestName}</ChestBoxTitle>
            <CloseIcon onClick={onDismiss} />
          </ChestTitleBox>

          <ChestImgBox>
            <ChestImg src={`/chest/chest${data.level}.gif`} />
            <ChestCntText>x {amount}</ChestCntText>
          </ChestImgBox>

          <AmountBoxWrapper>
            <AmountBoxTitle>choose the amount</AmountBoxTitle>

            <AmountInputBox>
              <AmountInputInDecrease onClick={decreaseAmount}>-</AmountInputInDecrease>

              <AmountInput type="number" value={amount} readOnly />

              <AmountInputInDecrease onClick={increaseAmount}>+</AmountInputInDecrease>
            </AmountInputBox>
          </AmountBoxWrapper>

          <PaymentBoxWrapper>
            <AmountBoxTitle>choose to pay in</AmountBoxTitle>

            <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap', width: '100%' }}>
              <BalanceBox>
                <span>USDB Balance: </span>
                {loadingBalance ? (
                  <CustomLightSpinner src={Circle} alt="loader" size="10px" />
                ) : Number(userUsdbBalance) > 1000 ? (
                  numberWithCommas(userUsdbBalance)
                ) : (
                  userUsdbBalance
                )}
              </BalanceBox>
              <PaymentBox onClick={() => handlePaymentMethodSelect('USDB')} isSelected={selectedPaymentMethod === 'USDB'}>
                <PaymentBoxItemLeft>
                  <img
                    style={{
                      cursor: 'pointer',
                      width: '32px',
                      height: '32px',
                      borderRadius: '18px',
                      border: '1px solid rgba(255, 255, 255, 0.20)',
                    }}
                    src={usdbIcon}
                  />
                  <span>USDB</span>
                </PaymentBoxItemLeft>
                <PaymentBoxItemRight>
                  <span>{usdbCost}</span>
                </PaymentBoxItemRight>
              </PaymentBox>
              <BalanceBox>
                <span>MUSD Balance: </span>
                {loadingBalance ? (
                  <CustomLightSpinner src={Circle} alt="loader" size="10px" />
                ) : Number(userMusdBalance) > 1000 ? (
                  numberWithCommas(userMusdBalance)
                ) : (
                  userMusdBalance
                )}
              </BalanceBox>
              <PaymentBox style={{ marginBottom: '0' }} onClick={() => handlePaymentMethodSelect('MUSD')} isSelected={selectedPaymentMethod === 'MUSD'}>
                <PaymentBoxItemLeft>
                  <img
                    style={{
                      cursor: 'pointer',
                      width: '32px',
                      height: '32px',
                      borderRadius: '18px',
                      border: '1px solid rgba(255, 255, 255, 0.20)',
                    }}
                    src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musd.svg"
                  />
                  <span>MUSD</span>
                </PaymentBoxItemLeft>
                <PaymentBoxItemRight>
                  <span>
                    {(finalMusdCost).toFixed(2)}
                  </span>
                </PaymentBoxItemRight>
              </PaymentBox>

              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: '16px !important' }}>
                <BalanceBox>
                  <span>5% Discount:</span>
                </BalanceBox>
                <BalanceBox style={{ textAlign: 'center', paddingRight: '10px', }}>
                  <span style={{ color: "#efefe4" }}>~ {(musdDiscount).toFixed(2)} MUSD </span>
                </BalanceBox>
              </div>

            </div>
          </PaymentBoxWrapper>

          {selectedPaymentMethod === 'USDB' &&
            (checkApproveUsdbContract ? (
              <>
                {Number(usdbCost) > Number(userUsdbBalance) ? (
                  <p
                    style={{
                      color: '#ff1e56',
                      fontSize: '12px',
                      paddingBottom: '10px',
                    }}
                  >
                    Not enough USDB balance!
                  </p>
                ) : (
                  ''
                )}
                <NotchedButtonFill
                  onClick={() => handleBuyChest('USDB')}
                  style={{ margin: '4px 0 0 0' }}
                  padding="15px 5px"
                  height="42px"
                  disabled={!selectedPaymentMethod || Number(usdbCost) > Number(userUsdbBalance) || Number(userUsdbBalance) === 0}
                >
                  <Text fontWeight={500} fontSize={18}>
                    <Trans>
                      {loadingBuyChest ? 'Opening...' : 'Open'}
                    </Trans>
                  </Text>
                </NotchedButtonFill>
              </>
            ) : (
              <>
                {Number(usdbCost) > Number(userUsdbBalance) ? (
                  <p
                    style={{
                      color: '#ff1e56',
                      fontSize: '12px',
                      paddingBottom: '10px',
                    }}
                  >
                    Not enough USDB balance!
                  </p>
                ) : (
                  ''
                )}
                <NotchedButtonFill
                  onClick={handleApproveUsdbContract}
                  style={{ margin: '4px 0 0 0' }}
                  padding="15px 5px"
                  height="42px"
                  disabled={!selectedPaymentMethod || Number(usdbCost) > Number(userUsdbBalance)}
                  color="#000"
                >
                  <Text fontWeight={500} fontSize={18}>
                    <Trans>{loadingApproveUsdbContract ? 'Loading...' : 'Approve USDB'}</Trans>
                  </Text>
                </NotchedButtonFill>
              </>
            ))}
          {selectedPaymentMethod === 'Key NFT' &&
            (checkApproveNftContract ? (
              <>
                {Number(amount) > Number(userNftBalance) ? (
                  <p
                    style={{
                      color: '#ff1e56',
                      fontSize: '12px',
                      paddingBottom: '10px',
                    }}
                  >
                    Not enough NFT balance!
                  </p>
                ) : (
                  ''
                )}
                <NotchedButtonFill
                  onClick={() => handleBuyChest('Key NFT')}
                  style={{ margin: '4px 0 0 0' }}
                  padding="15px 5px"
                  height="42px"
                  disabled={!selectedPaymentMethod || Number(amount) > Number(userNftBalance) || Number(userNftBalance) === 0}
                >
                  <Text fontWeight={500} fontSize={18}>
                    <Trans>{loadingBuyChest ? 'Opening...' : 'Open'}</Trans>
                  </Text>
                </NotchedButtonFill>
              </>
            ) : (
              <>
                {Number(amount) > Number(userNftBalance) ? (
                  <p
                    style={{
                      color: '#ff1e56',
                      fontSize: '12px',
                      paddingBottom: '10px',
                    }}
                  >
                    Not enough NFT balance!
                  </p>
                ) : (
                  ''
                )}
                <NotchedButtonFill
                  onClick={handleApproveNftContract}
                  style={{ margin: '4px 0 0 0' }}
                  padding="15px 5px"
                  height="42px"
                  disabled={!selectedPaymentMethod || Number(amount) > Number(userNftBalance)}
                >
                  <Text fontWeight={500} fontSize={18}>
                    <Trans>{loaddingApproveNftContract ? 'Loading...' : 'Approve NFT'}</Trans>
                  </Text>
                </NotchedButtonFill>
              </>
            ))}

          {selectedPaymentMethod === 'MUSD' &&
            (checkApproveMusdContract ? (
              <>
                {Number(finalMusdCost) > Number(userMusdBalance) ? (
                  <p
                    style={{
                      color: '#ff1e56',
                      fontSize: '12px',
                      paddingBottom: '10px',
                    }}
                  >
                    Not enough MUSD balance!
                  </p>
                ) : (
                  ''
                )}
                <NotchedButtonFill
                  onClick={() => handleBuyChest('MUSD')}
                  style={{ margin: '4px 0 0 0' }}
                  padding="15px 5px"
                  height="42px"
                  disabled={!selectedPaymentMethod || Number(finalMusdCost) > Number(userMusdBalance) || Number(userMusdBalance) === 0}
                >
                  <Text fontWeight={500} fontSize={18}>
                    <Trans>{loadingBuyChest ? 'Opening...' : 'Open'}</Trans>
                  </Text>
                </NotchedButtonFill>
              </>
            ) : (
              <>
                {Number(finalMusdCost) > Number(userMusdBalance) ? (
                  <p
                    style={{
                      color: '#ff1e56',
                      fontSize: '12px',
                      paddingBottom: '10px',
                    }}
                  >
                    Not enough MUSD balance!
                  </p>
                ) : (
                  ''
                )}
                <NotchedButtonFill
                  onClick={handleApproveMusdContract}
                  style={{ margin: '4px 0 0 0' }}
                  padding="15px 5px"
                  height="42px"
                  disabled={!selectedPaymentMethod || Number(finalMusdCost) > Number(userMusdBalance)}
                  color="#000"
                >
                  <Text fontWeight={500} fontSize={18}>
                    <Trans>{loadingApproveMusdContract ? 'Loading...' : 'Approve MUSD'}</Trans>
                  </Text>
                </NotchedButtonFill>
              </>
            ))}

          {!selectedPaymentMethod && (
            <NotchedButtonFill
              onClick={() => { }}
              style={{ margin: '4px 0 0 0' }}
              padding="15px 5px"
              height="42px"
              disabled={!selectedPaymentMethod}
            >
              <Text fontWeight={500} fontSize={18}>
                <Trans>
                  Open
                </Trans>
              </Text>
            </NotchedButtonFill>
          )}
        </BuyChestWrapper>
      </Modal>

      <Modal isOpen={isShowOpenAnimation} onDismiss={() => { }} maxWidth={592} scrolloverlay>
        <ChestOpenAnimationModal
          amountChest={amount}
          isBuying={loadingBuyChest}
          isBuySuccess={isBuySuccess}
          chestType={Number(data.level - 1)}
          rewardList={rewardList}
          onClose={() => {
            setIsBuySuccess(false)
            setRewardList([])
            setIsShowOpenAnimation(false)
          }}
        />
      </Modal>
    </>
  )
}
export const numberWithCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
