import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NotchedButtonFill, NotchedButtonFillBase } from '../../components/Button'
import './alpha.css'
import { useTokenContract, useContract, useCustomeContract } from 'hooks/useContract'
import v2Staker_abi from '../../abis/alpha/V2Staker.json'
import ERC_20 from '../../abis/erc20.json'
import v2PoolFactory_abi from '../../abis/alpha/V2PoolFactory.json'
import { usePositionTokenURI } from '../../hooks/usePositionTokenURI'
import { ExternalLink } from '../../theme'
import { getExplorerLink, ExplorerDataType } from 'utils/getExplorerLink'
import { V3STAKER_ADDRESSES_MAP, NONFUNGIBLE_POSITION_MANAGER_ADDRESSES } from '../../constants/addresses'

import { ethers } from 'ethers'
import DoubleCurrencyLogo, { SingleCurrencyLogo, DoubleCurrencyLogoV2 } from 'components/DoubleLogo'
import { useCurrency } from '../../hooks/Tokens'
import { useMultipleCurrenciesV2 } from './AlphaV2'
import { useWeb3React } from '@web3-react/core'
import { alphaPoolAddressV2 } from '../../constants/poolListData'

import { SupportedChainId } from '../../constants/chains'
import { WrongNetworkCard } from './index'
import axios, { Method } from 'axios'

// modal
import DepositModal from 'components/AlphaV2/DepositModal'
import UnstakeModal from 'components/AlphaV2/UnstakeModal'
import { PoolV2Info } from '../../hooks/useAlphaStore'
import { formatXpNumber } from 'pages/Xp/sections/XpTitle'
import { useAlphaTvlMap } from 'state/alphapool/hooks'
import { BASE_API } from 'pages/Xp'
const pairUsdt =
  'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/musdusdb.svg'
const infoIcon = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/info.svg'
const plusIcon =
  'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/plus-circle.svg'
const outLink = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/outlink.svg'
const eths = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/gift2.svg'
const staked =
  'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/percent-square.svg'
const totals =
  'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/circle-dollar-sign.svg'


type userInfo = {
  amount: string
  depositId: string
  lockUntil: string
  owner: string
  virtualAmount: string
}

const AlphaDetailV2 = () => {
  const navigate = useNavigate()
  const { account, chainId } = useWeb3React()
  const isSupported = chainId === SupportedChainId.BLAST

  if (!isSupported) {
    navigate('/farmingV2')
    return null
  }

  const tvlMap = useAlphaTvlMap()

  const [customeChainId, setCustomeChainId] = useState(SupportedChainId.BLAST)
  const [poolStatus, setPoolStatus] = useState('')
  const [yourPedingReward, setYourPendingReward] = useState()
  const [yourLockedTime, setYourLockedTime] = useState()
  // v2 stats
  const [userStakeTokenBalance, setUserStakeTokenBalance] = useState('')
  const [userpendingRewards, setUserPendingRewards] = useState('')
  const [totalUserDeposit, setTotalUserDeposit] = useState<any>()
  const [loadingStakeContract, setLoadingStakeContract] = useState(false)
  const [userDepositInfo, setUserDepositInfo] = useState<userInfo[] | undefined>(undefined)
  const [endTime, setEndTime] = useState('')

  // modal
  const [isOPenModalDeposit, setIsOPenModalDeposit] = useState(false)
  const [isOPenModalHarvest, setIsOPenModalHarvest] = useState(false)
  const [isOPenModalWithdraw, setIsOPenModalWithdraw] = useState(false)
  // function claim
  const [loadingHarvest, setLoadingHarvest] = useState(false)
  // trigger call modal function
  const [trigger, setTrigger] = useState(false)

  useEffect(() => {
    if (chainId) {
      setCustomeChainId(chainId)
    } else {
      setCustomeChainId(SupportedChainId.BLAST)
    }
  }, [account, chainId])

  const poolAddress = useParams()
  const filteredPools = getPoolByAddress(poolAddress?.poolAddress)
  const tokenInfoV2 = useMultipleCurrenciesV2(filteredPools)

  const locationState = useLocation()
  let poolInfo: PoolV2Info | undefined = undefined
  if (locationState?.state && typeof locationState?.state === 'object') {
    poolInfo = locationState?.state as PoolV2Info
  }
  function getPoolByAddress(address: any) {
    return (
      alphaPoolAddressV2[chainId!]?.filter((item) => item._poolAddress.toLowerCase() === address?.toLowerCase()) || []
    )
  }
  const STAKING_ADDRESS = poolInfo?._poolAddress
  const STAKE_TOKEN_ADDRESS = poolInfo?._stakedToken
  // stake contract
  const v2staker = useCustomeContract(STAKING_ADDRESS, v2Staker_abi, false)
  const v2stakerWithSign = useCustomeContract(STAKING_ADDRESS, v2Staker_abi, true)
  // token stake contract

  const tokenStakeContract = useCustomeContract(STAKE_TOKEN_ADDRESS, ERC_20, false)
  const tokenStakeContractWithSign = useCustomeContract(STAKE_TOKEN_ADDRESS, ERC_20, true)
  // get user balance
  async function getUserStakeInfo() {
    setLoadingStakeContract(true)
    try {
      if (account) {
        const stakeTokenBalance = await tokenStakeContractWithSign?.balanceOf(account)

        const formattedstakeTokenBalance = Number(
          Number(ethers.utils.formatUnits(stakeTokenBalance, 18)) - Number(0.001)
        ).toFixed(10)
        setUserStakeTokenBalance(Number(formattedstakeTokenBalance) > 0 ? formattedstakeTokenBalance : '0')

        // get user deposit info
        const infor = await v2stakerWithSign?.getUserDeposits(account)
        const currentDateTimestamp = Math.round(new Date().getTime() / 1000)

        let userPosition = []
        let totalAmount = 0
        for (let i = 0; i < infor?.length; i++) {
          let item = infor[i]
          const unstakeAllow = Number(item?.lockUntil) - Number(currentDateTimestamp) > 0 ? true : false
          const increateTimeAndAmountAllow =
            Number(item?.lockUntil) > Number(Number(currentDateTimestamp) + Number(poolInfo?._minLockTime))
              ? true
              : false
          let Array = {
            amount: ethers.utils.formatUnits(item?.amount, 18),
            depositId: item?.depositId.toString(),
            lockUntil: item?.lockUntil.toString(),
            owner: item?.owner.toString(),
            virtualAmount: item?.virtualAmount.toString(),
            isUnstake: unstakeAllow,
            increateTimeAndAmountAllow: increateTimeAndAmountAllow,
          }
          userPosition.push(Array)
          totalAmount += Number(ethers.utils.formatUnits(item?.amount, 18))
        }
        setTotalUserDeposit(totalAmount)
        setUserDepositInfo(userPosition)
        // stake contract info
        const pendingReward = await v2stakerWithSign?.pendingReward(account)
        const formmattedUserPendingReward = Number(ethers.utils.formatUnits(pendingReward, 18)).toFixed(2)
        setUserPendingRewards(formmattedUserPendingReward)
        // console.log('formmattedUserPendingReward', formmattedUserPendingReward)
        setLoadingStakeContract(false)
      }
    } catch (error) {
      console.log('error', error)
      setLoadingStakeContract(false)
    }
  }
  // console.log('userDepositInfo', userDepositInfo)

  // function claim reward
  const harvest = async () => {
    setLoadingHarvest(true)
    const gasLimit = 2100000

    try {
      const tx = await v2stakerWithSign?.claimReward({ gasLimit })
      await tx.wait()
      if (tx) {
        console.log('tx', tx)
        setLoadingHarvest(false)
        getUserStakeInfo()
      }

      setLoadingHarvest(false)
    } catch (error) {
      console.log(error)
      setLoadingHarvest(false)
    }
  }

  function checkStatus(data: any) {
    const startTime = data?._startBlock
    const endTime = data?._bonusEndBlock
    const now = data?.currentBlockNumber

    const dateNow = Math.floor(Date.now() / 1000)
    const endIn = (Number(dateNow) + Number(endTime)).toString()
    setEndTime(endIn)
    if (now < startTime) {
      setPoolStatus('Incoming')
      return 'Incoming'
    } else if (now > startTime && now < endTime) {
      setPoolStatus('Active')
      return 'Active'
    } else {
      setPoolStatus('End')
      return 'End'
    }
  }
  function convertUnixTimestampToDate(unixTimestamp: any) {
    const timestampInMilliseconds = unixTimestamp * 1000
    const date = new Date(timestampInMilliseconds)
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hourCycle: 'h23',
    })

    return formattedDate
  }

  const poolTvl = useMemo(() => {
    if (!poolInfo) return 0
    const tvl = tvlMap.get(poolInfo._stakedToken) ?? 0
    return tvl
  }, [tvlMap])

  const [userTvl, setUserTvl] = useState<number>(0)
  const loadUserTvl = () => {
    if (account)
      fetch(`${BASE_API}/monoswap/api/v1/pool-xp-multiplier/get-alphapool-tvl?incentive_id=${poolInfo?._stakedToken}&user_address=${account}`)
        .then(res => res.json())
        .then(data => {
          setUserTvl(data.userTvl)
        })
  }

  useEffect(() => {
    if (account) {
      getUserStakeInfo()
    }
  }, [account, chainId])
  useEffect(() => {
    if (poolInfo) {
      checkStatus(poolInfo)
      loadUserTvl()
    }
  }, [account, chainId])

  return (
    <>
      {!isSupported && chainId !== 81457 && navigate('/farming')}
      {isSupported && tokenInfoV2 && (
        <>
          <DepositModal
            isOpen={isOPenModalDeposit}
            onDismiss={() => setIsOPenModalDeposit(false)}
            tokenInfo={tokenInfoV2}
            poolInfo={poolInfo}
            getUserStakeInfo={getUserStakeInfo}
            userStakeTokenBalance={userStakeTokenBalance}
            trigger={trigger}
          />
          <UnstakeModal
            isOpen={isOPenModalWithdraw}
            onDismiss={() => setIsOPenModalWithdraw(false)}
            tokenInfo={tokenInfoV2}
            poolInfo={poolInfo}
            getUserStakeInfo={getUserStakeInfo}
            userStakeTokenBalance={userStakeTokenBalance}
            userDepositInfo={userDepositInfo}
            setTrigger={setTrigger}
          />
          <div className="musd-container">
            <div className="maestro-navigate">
              <p
                onClick={() => {
                  navigate('/farmingV2')
                }}
              >
                Farming V2 List
              </p>{' '}
              <p>&gt;</p> <p style={{ color: '#efefe4' }}> {tokenInfoV2[0]?.currencyA?.symbol}-{tokenInfoV2[0]?.currencyB?.symbol}</p>
            </div>
          </div>
          <div className="maestro-detail-cover">
            <div className="maestro-detail-container">
              <div className="maestro-detail-top">
                <div className="maestro-detail-top-left">
                  <DoubleCurrencyLogoV2
                    currency0={tokenInfoV2[0]?.currencyA ?? undefined}
                    currency1={tokenInfoV2[0]?.currencyB ?? undefined}
                    size={28}
                  />
                  <div>
                    <p>
                      Stake {tokenInfoV2[0]?.currencyA?.symbol}/{tokenInfoV2[0]?.currencyB?.symbol} to earn xMONO{' '}
                      {/* <span style={{ color: '#68FFFF' }}>v2</span> */}
                    </p>
                    <p style={{ color: 'rgba(239,239,228,0.5)' }}>#ID-{poolInfo?.poolId}</p>
                  </div>
                </div>
                <div className="maestro-detail-top-right">
                  <div className="maestro-detail-top-right-left">
                    <div className="maestro-detail-top-right-child">
                      <p style={{ color: 'rgba(239,239,228,0.5)', fontSize: '12px' }}>TVL</p>
                      <p>
                        $
                        {formatXpNumber(poolTvl)}{' '}
                      </p>
                    </div>
                    <div className="maestro-detail-top-right-child">
                      <p style={{ color: 'rgba(239,239,228,0.5)', fontSize: '12px' }}>APR</p>
                      <p className="maestro-detail-top-right-child-info">
                        {Number(poolInfo?.apr) > 1000 ? numberWithCommas(Number(poolInfo?.apr)) : poolInfo?.apr}%
                      </p>
                    </div>
                    <div className="maestro-detail-top-right-child no-border">
                      <p style={{ color: 'rgba(239,239,228,0.5)', fontSize: '12px' }}>Pool reward </p>
                      <p className="maestro-detail-top-right-child-info">
                        {Number(Number(poolInfo?._poolReward)) > 1000
                          ? numberWithCommas(Number(poolInfo?._poolReward).toFixed(2))
                          : Number(Number(poolInfo?._poolReward)).toFixed(2)}{' '}
                        xMONO{' '}
                        <span style={{ color: 'rgba(239,239,228,0.5)', fontSize: '12px' }}></span>
                      </p>
                    </div>
                  </div>

                  <div className="maestro-detail-top-right-child-button">
                    <ExternalLink
                      href={getExplorerLink(
                        chainId ? chainId : 81457,
                        STAKING_ADDRESS ? STAKING_ADDRESS : '',
                        ExplorerDataType.ADDRESS
                      )}
                      target="_blank"
                    >
                      <NotchedButtonFillBase padding={'0px 10px'} fontSize={'14px'} width={'150px'}>
                        <img src={outLink} alt="" style={{ marginRight: '3px' }} /> CONTRACT
                      </NotchedButtonFillBase>
                    </ExternalLink>

                    {/* http://localhost:3000/#/add/ETH/0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055/3000 */}
                    <NotchedButtonFill
                      padding={'0px 10px'}
                      fontSize={'14px'}
                      width={'150px'}
                      mbFontSize='14px'
                      onClick={() => {
                        navigate(
                          `/add/v2/${filteredPools[0]?._pairToken === '0x4200000000000000000000000000000000000023'
                            ? 'ETH'
                            : filteredPools[0]?._pairToken
                          }/${filteredPools[0]?._stakedToken === '0x4200000000000000000000000000000000000023'
                            ? 'ETH'
                            : filteredPools[0]?._stakedToken
                          }`
                        )
                      }}
                    >
                      + NEW POSITION
                    </NotchedButtonFill>
                  </div>
                </div>
              </div>
              <div className="alpha-detail-card-container-container">
                <div className="alpha-detail-card-container">
                  <div className="alpha-detail-card">
                    <p className="alpha-detail-card-title">Status</p>
                    <div className="alpha-detail-card-child">
                      <p>Status</p>
                      {
                        poolInfo?.hasEnded ? <p style={{ color: '#ff0000' }}>Ended</p>
                          : <p className="alpha-detail-card-child-active">{poolStatus}</p>
                      }
                    </div>
                    <div className="alpha-detail-card-child">
                      <p>Duration</p>
                      <p>90 days</p>
                    </div>
                    <div className="alpha-detail-card-child">
                      <p>Est end in</p>
                      {
                        poolInfo?.hasEnded ? '0d: 0h: 0m: 0s' : <CountdownTimer target={poolInfo?._poolEnd} />
                      }
                    </div>
                  </div>
                  <div className="alpha-detail-card">
                    <p className="alpha-detail-card-title">Authorizations</p>
                    <div className="alpha-detail-card-child">
                      <p>Deposits</p>
                      {
                        poolInfo?.hasEnded ? <p style={{ color: '#ff0000' }}>Disabled</p>
                          : <p className="alpha-detail-card-child-active">Enabled</p>
                      }
                    </div>
                    <div className="alpha-detail-card-child">
                      <p>Deposit end time</p>
                      <p className="alpha-detail-card-child-active">-</p>
                    </div>
                    <div className="alpha-detail-card-child">
                      <p>Harvests</p>
                      {
                        poolInfo?.hasEnded ? <p style={{ color: '#ff0000' }}>Disabled</p>
                          : <p className="alpha-detail-card-child-active">Enabled</p>
                      }
                    </div>
                  </div>
                </div>
                <div className="alpha-detail-card-container">
                  <div className="alpha-detail-card">
                    <p className="alpha-detail-card-title">Requirements</p>
                    <div className="alpha-detail-card-child">
                      <p>Minimum amount</p>
                      <p>-</p>
                    </div>
                    <div className="alpha-detail-card-child">
                      <p>Minimum lock</p>
                      {poolInfo && Number(poolInfo._minLockTime) < 1 ? (
                        <p>-</p>
                      ) : (
                        <p>{poolInfo && Number(poolInfo._maxLockTime) / 86400} days</p>
                      )}
                    </div>
                    <div className="alpha-detail-card-child">
                      <p>Locked until</p>
                      {poolInfo && Number(poolInfo._minLockTime) < 1 ? (
                        <p>-</p>
                      ) : (
                        <p>
                          {userDepositInfo && userDepositInfo.length > 0
                            ? convertUnixTimestampToDate(userDepositInfo[0]?.lockUntil)
                            : '-'}
                        </p>
                      )}
                    </div>
                    <div className="alpha-detail-card-child">
                      <p>Whitelist</p>
                      <p>-</p>
                    </div>
                  </div>
                  <div className="alpha-detail-card">
                    <div className="alpha-detail-card-title">
                      <p>Staked positions</p>
                      <div className="alpha-detail-card-title-right">
                        <NotchedButtonFill padding={'3px 10px'} fontSize={'12px'} height={'30px'} mbFontSize='12px' onClick={harvest}>
                          {loadingHarvest ? 'HARVESTING...' : 'HARVEST'}
                        </NotchedButtonFill>
                        <NotchedButtonFill
                          padding={'3px 15px'}
                          fontSize={'12px'}
                          height={'30px'}
                          mbFontSize='12px'
                          onClick={() => setIsOPenModalWithdraw(true)}
                        >
                          UNSTAKE
                        </NotchedButtonFill>
                        <NotchedButtonFill
                          padding={'3px 15px'}
                          fontSize={'12px'}
                          height={'30px'}
                          mbFontSize='12px' 
                          // disabled={poolInfo?.hasEnded}
                          // onClick={() => setIsOPenModalDeposit(true)}
                          disabled={true}
                        >
                          DEPOSIT
                        </NotchedButtonFill>
                      </div>
                    </div>
                    <div className="alpha-detail-card-card-container">
                      <div className="alpha-detail-card-card">
                        <div className="alpha-detail-card-card-img" style={{ height: '18px' }}>
                          {/* <SingleCurrencyLogo currency0={tokenInfoV2[0]?.incentive ?? undefined} size={18} /> */}
                          <img src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/circle-dollar-sign.svg'
                            style={{ width: '18px', height: '18px' }} />
                        </div>
                        <p style={{ fontSize: '14px' }}>Your Deposits</p>
                        <p style={{ color: '#efefe4', fontSize: '14px' }}>
                          ${formatXpNumber(userTvl)}{' '}
                        </p>
                      </div>
                      <div className="alpha-detail-card-card">
                        <div className="alpha-detail-card-card-img">
                          <SingleCurrencyLogo currency0={tokenInfoV2[0]?.rewardToken ?? undefined} size={18} />
                        </div>
                        <p style={{ fontSize: '14px' }}>Your pending rewards</p>
                        <p style={{ color: '#efefe4', fontSize: '14px' }}>
                          {/* {userpendingRewards ? Number(userpendingRewards).toFixed(2) : '0.00'}{' '} */}
                          {Number(userpendingRewards) > 1000
                            ? numberWithCommas(Number(userpendingRewards).toFixed(2))
                            : Number(userpendingRewards).toFixed(2)}{' '}
                          {tokenInfoV2[0]?.rewardToken?.symbol}
                        </p>
                      </div>
                      <div className="alpha-detail-card-card">
                        <div className="alpha-detail-card-card-img" style={{ height: '18px' }}>
                          <SingleCurrencyLogo currency0={tokenInfoV2[0]?.currencyA ?? undefined} size={18} />
                        </div>
                        <p style={{ fontSize: '14px' }}>Your balance</p>
                        <p style={{ color: '#efefe4', fontSize: '14px' }}>
                          {/* {userStakeTokenBalance ? Number(userStakeTokenBalance).toFixed(2) : '0.00'}{' '} */}
                          {Number(userStakeTokenBalance) > 1000
                            ? numberWithCommas(Number(userStakeTokenBalance).toFixed(4))
                            : Number(userStakeTokenBalance).toFixed(4)}{' '}
                          LP
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default AlphaDetailV2

export const CountdownTimer = ({ target }: any) => {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Math.floor(Date.now() / 1000)
      const distance = target - now

      setDays(
        parseInt(
          Math.floor(distance / 86400)
            .toString()
            .padStart(2, '0')
        )
      )
      setHours(
        parseInt(
          Math.floor((distance % 86400) / 3600)
            .toString()
            .padStart(2, '0')
        )
      )
      setMinutes(
        parseInt(
          Math.floor((distance % 3600) / 60)
            .toString()
            .padStart(2, '0')
        )
      )
      setSeconds(parseInt((distance % 60).toString().padStart(2, '0')))
    }, 1000)

    return () => clearInterval(interval)
  }, [target])

  return (
    <div className="count-down-box">
      <p className="count-down-show-box">{days.toString().padStart(2, '0')}d :</p>
      <p className="count-down-show-box">{hours.toString().padStart(2, '0')}h :</p>
      <p className="count-down-show-box">{minutes.toString().padStart(2, '0')}m :</p>
      <p className="count-down-show-box">{seconds.toString().padStart(2, '0')}s</p>
    </div>
  )
}
function addSecondsAndFormat(seconds: any) {
  const now = Date.now()
  const milliseconds = seconds * 1000
  const futureTimestamp = now + milliseconds
  const futureDate = new Date(futureTimestamp)
  const formattedDate = futureDate.getDate() + '/' + (futureDate.getMonth() + 1) + '/' + futureDate.getFullYear()
  return formattedDate
}
export const numberWithCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
