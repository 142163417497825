import Modal from '../Modal'
import { Trans } from '@lingui/macro'
import { Text } from 'rebass'
import './modal.css'
import styled, { useTheme } from 'styled-components/macro'
import { CloseIcon, CustomLightSpinner } from '../../theme'
import { AutoColumn, ColumnCenter } from '../Column'
import { ButtonLight, ButtonPrimary, NotchedButtonFillPrimary, NotchedButtonFill } from '../Button'
import { CheckCircle, AlertCircle, AlertTriangle, ChevronsRight } from 'react-feather'
import AnimatedConfirmation from '../TransactionConfirmationModal/AnimatedConfirmation'
import { usePositionTokenURI } from '../../hooks/usePositionTokenURI'
import { useGetCustomeImage } from '../../hooks/useGetCustomeImage'
import { ethers } from 'ethers'
import React, { useContext, useEffect, useRef, useState } from 'react'
import CheckButtonComponent from 'components/Button/CheckButton'
import { Contract } from '@ethersproject/contracts'
import { useWeb3React } from '@web3-react/core'
import { useTokenContract, useContract, useCustomeContract } from 'hooks/useContract'
import v2Staker_abi from '../../abis/alpha/V2Staker.json'
import v2PoolFactory_abi from '../../abis/alpha/V2PoolFactory.json'
import ERC_20 from '../../abis/erc20.json'
import emptyImage from '../../assets/images/alpha/message-circle-question.svg'

// image
const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'

import { SupportedChainId } from '../../constants/chains'
import Badge, { BadgeVariant } from '../Badge'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
interface DepositModalInterface {
  isOpen: boolean
  onDismiss: () => void
  getUserStakeInfo: () => void
  tokenInfo: any
  poolInfo: any
  userStakeTokenBalance: any
  userDepositInfo: any
  setTrigger: (data: boolean) => void
}
type userInfo = {
  amount: string
  depositId: string
  lockUntil: string
  owner: string
  virtualAmount: string
  isUnstake: boolean
}

const Wrapper = styled.div`
  background-color: #1c1b20;
  outline: none;
  width: 100%;
  padding: 10px 20px;
  height: fit-content;
  margin-bottom: 20px;
`
const WrapperTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: #efefe4;
  }
`

const BadgeText = styled.div`
  font-weight: 500;
  font-size: 12px;
`

export default function UnstakeModal({
  isOpen,
  onDismiss,
  tokenInfo,
  poolInfo,
  getUserStakeInfo,
  userStakeTokenBalance,
  userDepositInfo,
  setTrigger,
}: DepositModalInterface) {
  const [ethPrice, setEthPrice] = useState(3000)
  const { account, chainId } = useWeb3React()
  const [customeChainId, setCustomeChainId] = useState(SupportedChainId.BLAST)
  useEffect(() => {
    if (chainId) {
      setCustomeChainId(chainId)
    } else {
      setCustomeChainId(SupportedChainId.BLAST)
    }
  }, [account, chainId])
  // contract
  const STAKING_ADDRESS = poolInfo?._poolAddress
  const STAKE_TOKEN_ADDRESS = poolInfo?._stakedToken

  const v2staker = useCustomeContract(STAKING_ADDRESS, v2Staker_abi, false)
  const v2stakerWithSign = useCustomeContract(STAKING_ADDRESS, v2Staker_abi, true)
  const tokenStakeContract = useCustomeContract(STAKE_TOKEN_ADDRESS, ERC_20, true)

  // state
  const [loadingUserDeposit, setLoadingUserDeposit] = useState(false)

  // const [userDepositInfo, setUserDepositInfo] = useState<userInfo[] | undefined>(undefined)
  const [checkedIdsIncreaseTime, setCheckedIdsIncreaseTime] = useState<string[]>([])
  const [loadingUnstake, setloadingUnstake] = useState(false)

  // increase function
  const handleUnstake = async () => {
    setloadingUnstake(true)
    const depositId = checkedIdsIncreaseTime[0]?.toString()
    try {
      const gasLimit = 210000
      const tx = await v2stakerWithSign?.withdraw(depositId, { gasLimit })
      await tx.wait()
      if (tx) {
        console.log('tx', tx)
        setloadingUnstake(false)
        getUserStakeInfo()
        setTrigger(true)
      }

      setloadingUnstake(false)
    } catch (error) {
      console.log(error)
      setloadingUnstake(false)
    }
  }

  // fnc

  function convertUnixTimestampToDate(unixTimestamp: any) {
    const timestampInMilliseconds = unixTimestamp * 1000
    const date = new Date(timestampInMilliseconds)
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hourCycle: 'h23',
    })

    return formattedDate
  }

  //"1710210200"
  // get getUserDeposits function
  // const getUserDeposits = async () => {
  //   setLoadingUserDeposit(true)
  //   try {
  //     const infor = await v2stakerWithSign?.getUserDeposits(account)
  //     const currentDateTimestamp = Math.round(new Date().getTime() / 1000)

  //     let userPosition = []
  //     for (let i = 0; i < infor?.length; i++) {
  //       let item = infor[i]
  //       const unstakeAllow = Number(item?.lockUntil) - Number(currentDateTimestamp) > 0 ? true : false
  //       let Array = {
  //         amount: ethers.utils.formatUnits(item?.amount, 18),
  //         depositId: item?.depositId.toString(),
  //         lockUntil: item?.lockUntil.toString(),
  //         owner: item?.owner.toString(),
  //         virtualAmount: item?.virtualAmount.toString(),
  //         isUnstake: unstakeAllow,
  //       }
  //       userPosition.push(Array)
  //     }
  //     setUserDepositInfo(userPosition)
  //     setLoadingUserDeposit(false)
  //   } catch (error) {
  //     console.log(error)
  //     setLoadingUserDeposit(false)
  //   }
  // }

  const handleCheckChangeIncreaseTime = (formattedTokenId: string, isChecked: boolean) => {
    if (isChecked) {
      setCheckedIdsIncreaseTime([formattedTokenId])
    } else {
      setCheckedIdsIncreaseTime([])
    }
  }
  let disableConfirmButtonIncreaseTime = checkedIdsIncreaseTime.length > 0 ? false : true

  useEffect(() => {
    if (account !== null && account !== undefined) {
      getUserStakeInfo()
    }
  }, [account, chainId])
  // console.log('userStakeInfo', userDepositInfo)

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <WrapperTop>
          <p>Unstake</p>
          <CloseIcon onClick={onDismiss} />
        </WrapperTop>

        <div>
          <div>
            <p>Select your positions to unstake</p>
            <div className="stake-position-container">
              {userDepositInfo && userDepositInfo?.length > 0 ? (
                userDepositInfo?.map((i: any, index: any) => (
                  <div className="deposit-card-containers" key={index}>
                    <div className="deposit-card-container-title">
                      <div>
                        <p
                          className="deposit-card-container-title-p"
                          style={{ color: 'rgba(239, 239, 228, 0.5)', fontSize: '12px' }}
                        >
                          Staked:{' '}
                          <span className="deposit-card-container-title-span" style={{ color: '#efefe4' }}>
                            {Number(i?.amount).toFixed(2)}
                          </span>{' '}
                        </p>

                        {poolInfo && Number(poolInfo._minLockTime) < 1 ? (
                          <></>
                        ) : (
                          <p
                            className="deposit-card-container-title-p"
                            style={{color: 'rgba(239, 239, 228, 0.5)', fontSize: '12px'}}
                          >
                            Lock until:{' '}
                            <span style={{color: '#efefe4', fontSize: '12px'}}>
                            {convertUnixTimestampToDate(i?.lockUntil)}{' '}
                          </span>
                          </p>
                        )}
                      </div>

                      <CheckButtonComponent
                        onCheckChange={(isChecked) => handleCheckChangeIncreaseTime(i.depositId, isChecked)}
                        isChecked={checkedIdsIncreaseTime.includes(i.depositId)}
                        formattedTokenId={i?.depositId}
                        disabled={i?.isUnstake}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="deposit-card-container-img">
                  <img src={emptyImage} alt="img" />
                  <p>You don&apos;t have any compatible position</p>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%',
              marginTop: '10px',
            }}
          >
            <NotchedButtonFill
              onClick={handleUnstake}
              style={{ margin: '4px 0 0 0' }}
              disabled={disableConfirmButtonIncreaseTime}
            >
              <Text fontWeight={500} fontSize={20}>
                <Trans>{loadingUnstake ? 'Loading...' : 'Unstake'}</Trans>
              </Text>
            </NotchedButtonFill>
          </div>
        </div>

        <NotchedButtonFillPrimary onClick={onDismiss} style={{ margin: '14px 0 0 0' }}>
          <Text fontWeight={500} fontSize={20}>
            <Trans>Close</Trans>
          </Text>
        </NotchedButtonFillPrimary>
      </Wrapper>
    </Modal>
  )
}
