import {PositionDetails} from "../types/position";
import {Pool, Position} from "@uniswap/v3-sdk";
import {Currency, CurrencyAmount, Percent, Token} from "@uniswap/sdk-core";
import {useToken} from "./Tokens";
import {usePool} from "./usePools";
import {unwrappedToken} from "../utils/unwrappedToken";


export function useMultiplePositions(positions: PositionDetails[]): {
  liquidityValue0?: string
  liquidityValue1?: string
  currency0?: CurrencyAmount<Currency>,
  currency1?: CurrencyAmount<Currency>,
  outOfRange?: boolean
}[] {
  if (!positions || !Array.isArray(positions)) {
    return [];
  }

  const token0 = useToken(positions[0]?.token0)
  const token1 = useToken(positions[0]?.token1)

  const [, pool] = usePool(token0 ?? undefined, token1 ?? undefined, positions[0]?.fee)

  const array = [];

  for (let i = 0; i < Math.min(positions.length, 11); i++) {
    const { liquidityValue0, liquidityValue1, currency0, currency1, outOfRange } = useDerivedV3PositionInfo(positions[i], pool,false, token0, token1)
    array.push({ liquidityValue0, liquidityValue1, currency0, currency1, outOfRange });
  }

  return array;
}

export function useDerivedV3PositionInfo(
  position?: PositionDetails,
  pool?: Pool | null,
  asWETH = false,
  token0?: Token | null | undefined,
  token1?: Token | null | undefined,
): {
  liquidityValue0?: string
  liquidityValue1?: string
  currency0?: CurrencyAmount<Currency>,
  currency1?: CurrencyAmount<Currency>,
  outOfRange: boolean
} {
  const positionSDK = pool && position?.liquidity && typeof position?.tickLower === 'number' && typeof position?.tickUpper === 'number'
    ? new Position({
      pool,
      liquidity: position.liquidity.toString(),
      tickLower: position.tickLower,
      tickUpper: position.tickUpper,
    })
    : undefined

  const liquidityPercentage = new Percent(100, 100)

  const discountedAmount0 = positionSDK
    ? liquidityPercentage.multiply(positionSDK.amount0.quotient).quotient
    : undefined
  const discountedAmount1 = positionSDK
    ? liquidityPercentage.multiply(positionSDK.amount1.quotient).quotient
    : undefined

  const liquidityValue0 =
    token0 && discountedAmount0
      ? CurrencyAmount.fromRawAmount(asWETH ? token0 : unwrappedToken(token0), discountedAmount0)
      : undefined
  const liquidityValue1 =
    token1 && discountedAmount1
      ? CurrencyAmount.fromRawAmount(asWETH ? token1 : unwrappedToken(token1), discountedAmount1)
      : undefined

  const outOfRange =
    pool && position ? pool.tickCurrent < position.tickLower || pool.tickCurrent > position.tickUpper : false

  return {
    liquidityValue0: liquidityValue0?.toSignificant(4),
    liquidityValue1: liquidityValue1?.toSignificant(4),
    currency0: liquidityValue0,
    currency1: liquidityValue1,
    outOfRange: outOfRange
  }
}
