import styled from "styled-components"
import TradingContestSection1 from "./sections/TradingContestSection1"
import EligibleTradeSection from "./sections/EligibleTradeSection"
import UnlockRewardSection from "./sections/UnlockRewardSection"
import TradingContestLeaderboard from "./sections/TradingContestLeaderboard"
import { useEffect, useState } from "react"
import axios from "axios"
import { BASE_API } from "pages/Xp"
import { useWeb3React } from "@web3-react/core"

const TradingContestPageWrapper = styled.div` 
    width: 100%;
    height: 100%;
    margin: auto;
    background: black;
    position: relative;
    padding-top: 180px;
`
const Img = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
`
type RewardType = {
    musd: number;
    mono: number;
    xmono: number;
    point: number;
    goldPoint: number;
}

type XpLeaderboardType = {
    currentEpoch: {
        seq: number,
        startDate: number,
        endDate: number,
    };
    totalDistributed: RewardType,
    nextTotalDistributed: RewardType,
    myPendingReward?: RewardType,
    list: [{
        rank: number;
        address: string;
        xp: number;
        reward?: RewardType
    }],
    totalCurrentVol: number,
    myTop: {
        rank: number, xp: number, volume: number, myRefXp: number, invitedCnt: number, reward?: RewardType
    },
    refCode: string
}

export default function TradingContestPage() {
    const { account } = useWeb3React()
    const [data, setData] = useState<XpLeaderboardType>();
    let isLoadingLeaderboard = false;
    const loadLeaderboardData = () => {
        if (isLoadingLeaderboard) return;
        isLoadingLeaderboard = true;
        axios.get(`${BASE_API}/monoswap/api/v1/mono-trading/leaderboard?address=${account ?? ''}`)
            .then(({ data }: { data: XpLeaderboardType }) => {
                setData(data);
            })
            .catch(err => { })
            .finally(() => isLoadingLeaderboard = false);
    }
    useEffect(() => {
        loadLeaderboardData()
    }, [account])

    return <TradingContestPageWrapper>
        <Img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/trading-contest/trading-contest-bg.png" />
        <TradingContestSection1 startDate={data?.currentEpoch.startDate ?? 0} endDate={data?.currentEpoch.endDate ?? 0} reward={data?.nextTotalDistributed} />
        <EligibleTradeSection />

        <div style={{ margin: '100px auto', background: 'black' }}>
            <img style={{ margin: 'auto', display: 'block', width: '100%', maxWidth: '267px', position: 'relative', zIndex: 1 }} src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/hr-icon.png' />
        </div>
        <UnlockRewardSection data={data} />
        <div style={{ margin: '50px auto', marginTop: '100px', background: 'black' }}>
            <img style={{ margin: 'auto', display: 'block', width: '100%', maxWidth: '267px', position: 'relative', zIndex: 1 }} src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/hr-icon.png' />
        </div>
        <TradingContestLeaderboard data={data} />
    </TradingContestPageWrapper>
}