import styled from "styled-components"
import { useEffect, useMemo, useState } from "react";
import { BREAKPOINTS, CustomLightSpinner } from "theme";
import { NotchedButtonBolderNew } from "components/Button/NotchedButton";
import { NotchedButtonFill } from "components/Button";
import { useToggleWalletModal } from "state/application/hooks";
import { useWeb3React } from "@web3-react/core";
import useDividendContext from "../../hooks/useDividendContext";
import { formatXpNumber } from "pages/Xp/sections/XpTitle";
import { notification } from "antd";
import { ethers } from "ethers";
import { MONO_ADDRESSES_MAP, MONO_DIVIDEND_ADDRESSES_MAP, XMONO_ADDRESSES_MAP } from "constants/addresses";
import { SupportedChainId } from "constants/chains";
import useERC20TokenBalance from "hooks/useERC20TokenBalance";
import XMONO_ABI from "../../abis/xmono/xmono.json"
import { useCustomeContract } from "hooks/useContract";
import useDebounce from "hooks/useDebounce";
import useCountdownTimer from "hooks/useCountdownTimer";
import { getContract } from "utils";
import { RPC_URLS } from "constants/networks";
import { toFixedNum } from "utils/formatNumbers";
import { calculateRedeem, getPercentageBasedOnRedeemDays } from "./xmonoSetting";
import checkUnsupportChainId from "utils/checkUnsupportChainId";
import { useNavigate } from "react-router-dom";

const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'

const XmonoPageSectionWrapper = styled.section`
    width: 100%;
    height: 100%;
    padding: 0 10px;
    margin-top: 144px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        margin-top: 44px;
    }
`;

const InfoBox = styled.div`
    width: 100%;
    display: grid;
    justify-content: center;
    gap: 56px;
`;

const TitleBoxWrapper = styled.div`
    max-width: 1224px;
    width: 100%;
    margin: auto;
    display: flex;
    gap: 28px;
    &>img{
        max-width: 72px;
        max-height: 72px;
        border-radius: 72px;
        border: 1px solid rgba(255, 255, 255, 0.10);
    }
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        max-width: 90%;
    }
`

const TitleBox = styled.div`
    display: grid;
    gap: 8px;
    justify-content: flex-start;
    align-content: center;
`;
const Title = styled.h1`
    color: #EFEFE4;
    text-align: left;
    font-family: Righteous;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 60px */
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        justify-content: center;
        font-size: 32px;
        line-height: 40px;
    }
`;
const SubTitle = styled.h4`
    color: #EFEFE4;
    text-align: left;

    /* Urbanist/Body-2/Semi */
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        margin: auto;
        font-size: 14px;
        line-height: 20px;
    }
`;

const ChestInfoBoxBorder = styled.div<{
    clipPath?: string;
    w?: string;
    maxW?: string;
    h?: string;
}>`
    max-width: 1224px;
    width: 1224px;
    height: ${({ h }) => h ?? '100%'} ;
    position: relative;
    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
    }
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        max-width: 90%;
        width: 100%;
        margin: auto;
        padding: 0 80px;
    }
    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        padding: 0;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        background: #efefe40f; 
        clip-path: polygon(27.3px 0, 100% 0, 100% calc(100% - 27.3px), calc(100% - 27.3px) 100%, 0 100%, 0 27.3px);
        .bg{
            display: none;
        }
    }
  `;

const ChestInfoStatsBox = styled.div`
    padding: 20px;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 900px){
        max-width: 100%;
        scale: 0.8;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        scale: 1;
        gap: 10px;
        padding: 20px 10px;
        flex-wrap: wrap;
    }
    @media screen and (max-width: 450px){
        display: grid;
        gap: 15px;
        justify-items: center;
    }
`;

const ChestInfoStatsItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    min-width: 180px;
    &>img{
        max-width: 44px;
        max-height: 44px;
        @media screen and (max-width: ${BREAKPOINTS.md}px){
            max-width: 32px;
            max-height: 32px;
        }
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        min-width: 160px;
        width: calc(40% - 2px);
        justify-content: space-between;
        padding: 0 10px;
    }
    @media screen and (max-width: 450px){
        width: 100%;
    }
`
const ChestInfoStatsItem = styled.div`
    width: 100%;
    display: grid;
    gap: 8px;
    justify-content: start;
    align-items: center;
    @media screen and (max-width: ${BREAKPOINTS.xs}px) {
        gap: 0;
    }
`;

const ChestInfoStatsSeparator = styled.div<{
    isShowOnMobile?: boolean;
}>`
    width: 1px;
    height: 56px;
    background: #efefe41c;
    backdrop-filter: blur(4.800000190734863px);
    @media screen and (max-width: ${BREAKPOINTS.lg}px){
        display: ${({ isShowOnMobile }) => isShowOnMobile ? 'block' : 'none'};
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        display: none;
    }
`;

const LabelText = styled.span`
    color: rgba(239, 239, 228, 0.50);
    text-align: left;
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
    opacity: 0.5;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        font-size: 13px;
    }
    @media screen and (max-width: ${BREAKPOINTS.xs}px) {
        font-size: 11px;
    }
`;

const ContentText = styled.span`
    color: #FFF;
    text-align: left;
    font-family: Righteous;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 24px */
    text-transform: uppercase;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        font-size: 18px;
        line-height: 22.5px;
    }
    @media screen and (max-width: ${BREAKPOINTS.xs}px) {
        font-size: 16px;
    }
`;

const StakeInfoBoxWrapper = styled.div`
    gap: 33px;
    margin: 64px auto;
    max-width: 717px;
    width: 100%;
`
const StakeBox = styled.div`
    background: rgba(239, 239, 228, 0.03);
    backdrop-filter: blur(4.800000190734863px);
    border: 1px solid #efefe480;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        width: 90%;
        margin: auto;
    }
`;
const StakeBoxTop = styled.div`
    background: rgba(239, 239, 228, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const TabList = styled.div`
    display: flex;
    height: 50px;
    width: 100%;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        height: 40px;
    }
`
const TabItem = styled.div<{
    isActived: boolean
}>`
    display: flex;
    width: 50%;
    height: 100%;
    padding: 24px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: ${({ isActived }) => isActived ? 'linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)' : ''} ;
    cursor: ${({ isActived }) => isActived ? 'unset' : 'pointer'} ;
    color: ${({ isActived }) => isActived ? '#0A090F' : 'rgba(239, 239, 228, 0.50)'} ;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 22.5px */
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding: 12px 0px;
    }
`;

const StakeBoxBottom = styled.div`
    padding: 32px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding: 18px;
        .fill-btn, .border-btn{
            height: 40px;
            span{
                font-size: 14px;
            }
        }
    }
`

const StakeInfoTitleBox = styled.div`
    display: grid;
    max-width: 600px;
    gap: 8px;
    justify-content: start;
    align-content: start; 
`;

const StakeInfoTitle = styled.h3`
    color: #EFEFE4;
    font-family: Righteous;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 60px */
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        justify-content: left;
        font-size: 24px;
        line-height: 125%;
    }
`;

const StakeInfoSubTitle = styled.h4`
    text-align: left;

    /* Urbanist/Body-2/Semi */
    color: rgba(239, 239, 228, 0.50);
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        margin: auto;
        font-size: 14px;
        line-height: 125%;
        text-align: left;
    }
`;

const InputBoxWrapper = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;
`;

const InputBox = styled.div`
    display: flex;
    height: 56px;
`;
const InputSelect = styled.div`
    width: 154.1px;
    height: 56px;
    background: rgba(239, 239, 228, 0.05);

    color: #EFEFE4;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 20px */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding: 14px;
    }
`;

const Input = styled.input`
    color: #EFEFE4;
    font-family: Urbanist;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 26.25px */
    background: rgba(239, 239, 228, 0.05);
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    padding-right: 24px;
    text-align: right;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding: 18px;
    }
`;
const InputBalanceBox = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 7px;
`;

const ErrorText = styled.span`
    color: #FF1E56;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 20px */
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding: 14px;
    }
`

const InbutBalanceContent = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
`;

const MaxButton = styled.button`
    display: flex;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: rgba(239, 239, 228, 0.05);
    border: none;
    cursor: pointer;

    span{
        text-align: center;
        font-family: Urbanist;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 15px */
        background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @media screen and (max-width: ${BREAKPOINTS.md}px){
            font-size: 10px;
        }
    }
`


const BalanceLabel = styled.span`
    color: rgba(239, 239, 228, 0.50);
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
    span {
        font-family: Urbanist;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        font-size: 12px;
        span{
            font-size: 12px;
        }
    }
`;


const ButtonLabel = styled.span`
    width: 100%;
    height: 100%;
    text-align: center;
    /* Righteous/Button */
    color: #0A090F;
    font-family: Righteous;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 18px */
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RedeemCalcBox = styled.div`
    display: grid;
    margin-top: 32px;
`
const RedeemCalcDuration = styled.div`
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        display: grid;
        gap: 12px;
    }
`

const RedeemCalcDurationLeft = styled.div`
    max-width: 200px;
    h5{
        color: #EFEFE4;
        /* Urbanist/Body-2/Semi */
        font-family: Urbanist;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 25.2px */
        margin: 0;
    }
    h6{
        font-family: Urbanist;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 17.5px */
        background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
        cursor: pointer;
        &:hover{
            scale: 1.05;
            transition: ease-in-out;
            transition-delay: 20ms;
        }
    }
`

const RedeemCalcDurationRight = styled.div`
    display: grid;
    gap: 8px;
`
const RedeemCalcDurationInputWrapper = styled.div`  
    display: flex;
    background: rgba(239, 239, 228, 0.05);
    height: 40px;
`
const RedeemCalcDurationInputInDecreaseBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(239, 239, 228, 0.05);
    width: 56px;
    opacity: 0.7;
    color: #EFEFE4;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 30px */
    cursor: pointer;
    &:hover{
        scale: 1.05;
        transition: ease-in-out;
        transition-delay: 20ms;
    }
`
const RedeemCalcDurationInputBox = styled.div` 
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    width: 170px;
    span{
        color: rgba(239, 239, 228, 0.50);
        text-align: right;
        leading-trim: both;
        text-edge: cap;
        font-family: Urbanist;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 22.5px */
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            font-size: 14px;
        }
    }
`
const RedeemCalcDurationInput = styled.input`
    height: 100%;
    max-width: 80px;
    background: unset;
    border: none;
    outline: none;
    color: #EFEFE4;
    leading-trim: both;
    text-edge: cap;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 22.5px */
`

const RedeemCalcBoxSeparator = styled.div`
    height: 1px;
    width: 100%;
    background: rgba(239, 239, 228, 0.12);
    backdrop-filter: blur(4.800000190734863px);
`
const RedeemCalcEstimateBox = styled.div`
    display: grid;
    gap: 8px;
    margin-top: 16px;
`
const RedeemCalcEstimateItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
        color: #EFEFE4;
        text-align: right;
        font-family: Urbanist;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 20px */
    }
`


const EstimateBox = styled.div`
    margin-top: 16px;
    padding: 24px;
    background: rgba(239, 239, 228, 0.03);
    backdrop-filter: blur(4.800000190734863px);
    min-height: 200px;
`

const EstimateBoxTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
`
const EstimateBoxTitle = styled.h5`
    color: #EFEFE4;
    font-family: Righteous;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 30px */
    margin: 0;
`

const EstimateBoxStatus = styled.div`   
    display: flex;
    align-items: center;
    span{
        color: #EFEFE4;
        font-family: Urbanist;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 17.5px */
    }
    opacity: 0.6;
`

const EstimateBoxRouteWrapper = styled.div`
    display: grid;
    gap: 8px;
`

const EstimateBoxRoute = styled.div`    
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`
const EstimateBoxRouteBegin = styled.span`
    color: #EFEFE4;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 20px */
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        font-size: 12px;
    }
`
const EstimateBoxRouteEnd = styled(EstimateBoxRouteBegin)`
    min-width: 120px;
    text-align: right;
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        min-width: 60px;
    }
`

const EstimateBoxRouteArrow = styled.span`
    color: rgba(239, 239, 228, 0.20);
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        min-width: 40px;
    }
`
const EstimateBoxTime = styled.span` 
    color: rgba(239, 239, 228, 0.50);
    text-align: center;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 20px */
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        font-size: 12px;
    }
    @media screen and (max-width: 450px){
        width: 74px;
    }
`

const RedeemList = styled.div`
    display: grid;
    gap: 16px;
`
const RedeemItemBox = styled.div`
    padding: 24px;
    background: rgba(239, 239, 228, 0.03);
    backdrop-filter: blur(4.800000190734863px);
`
const RedeemItemBoxTop = styled.div`
    display: flex;
    justify-content: space-between;
    .btn-content{
        font-family: Righteous;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
        text-transform: uppercase;
        background:  linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14px 23px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        flex-direction: column;
        gap: 12px;
        justify-items: center;
        align-items: center;
    }
`
const RedeemItemBoxTopTitleBoxWrapper = styled.div`    
    display: flex;
    gap: 16px;
    &>img{
        max-width: 44px;
        max-height: 44px;
    }
    width: 100%;
`
const RedeemItemBoxTopTitleBox = styled.div`
    display: grid;
    gap: 8px;
    h5,h6{
        color: #EFEFE4;
        font-family: Righteous;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 30px */
        margin: 0;
    }
    h6{
        color: rgba(239, 239, 228, 0.70);
        font-family: Urbanist;
        font-size: 14px;
        line-height: 140%; /* 19.6px */
    }
`

const RedeemItemBoxBotom = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
    gap: 12px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`
const RedeemItemInforBox = styled.div`
    display: grid;
    gap: 8px;
    width: 100%;
    max-width: 195px;
    height: 84px;
    padding: 20px;
    background: rgba(239, 239, 228, 0.03);
    backdrop-filter: blur(4.800000190734863px);

    h5,h6{
        color: rgba(239, 239, 228, 0.50);
        font-family: Urbanist;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 17.5px */
        margin: 0;
    }
    h5{
        color: #EFEFE4;
        font-family: Righteous;
        font-size: 18px;
        font-weight: 400;
        line-height: 100%; /* 18px */
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        max-width: unset;
        justify-content: center;
        h5{
            font-size: 15px;
        }
        h6{
            font-size: 12px;
        }
        h5, h6{
            text-align: center;
        }
    }
`
type RedeemXMONOType = {
    id: number
    amount: number
    time: number
}
const RedeemItem = ({ redeemData, xMONOContract, loadMONOBalance }: {
    redeemData: RedeemXMONOType,
    xMONOContract: any,
    loadMONOBalance: Function
}) => {
    const [endTime, setEndTime] = useState<number>(0)
    const { days, hours, minutes, seconds } = useCountdownTimer('DAY', endTime)

    const [isHideData, setIsHideData] = useState<boolean>(false)
    const [isLt550, setIsLt550] = useState<boolean>(false)
    const [isWithdrawing, setIsWithdrawing] = useState<boolean>(false)
    const [redeemOutMONO, setRedeemOutMONO] = useState<number>(0)
    const currentTime = Date.now() / 1000;

    const withdraw = async () => {
        if (isWithdrawing) return
        try {
            setIsWithdrawing(true)
            const gasEstimate = await xMONOContract?.estimateGas.executeRedeem(redeemData.id)
            const gasLimitCalc = Math.floor(gasEstimate ? gasEstimate.toNumber() * 1.5 : 200000)

            const tx = await xMONOContract.executeRedeem(redeemData.id, {
                gasLimit: gasLimitCalc
            })
            await tx.wait()
            loadMONOBalance()
            setIsHideData(true)
            notification.success({
                message: 'Withdraw successfull'
            })
        }
        catch (err) {
            console.log('withdraw redeem failed: ', err)
            if (err?.code == 'UNPREDICTABLE_GAS_LIMIT' || (err?.data?.message && String(err?.data?.message).indexOf('insufficient funds')))
                notification.error({
                    message: 'Insufficient funds for gas'
                })
            else
                notification.error({
                    message: 'Withdraw failed'
                })
        }
        finally {
            setIsWithdrawing(false)
        }

    }

    useEffect(() => {
        if (redeemData && xMONOContract)
            setEndTime(redeemData.time)
    }, [redeemData])


    useEffect(() => {
        setIsLt550(window.screen.width <= 550)
    }, [])

    return isHideData ? <></> : <EstimateBoxRouteWrapper>
        <EstimateBoxRoute>
            <EstimateBoxRouteBegin>xMONO</EstimateBoxRouteBegin>

            <EstimateBoxRouteArrow>
                {isLt550 ? `--->` : `---------->`}
            </EstimateBoxRouteArrow>

            <div style={{ display: 'grid', justifyItems: 'center', gap: '12px' }}>
                <EstimateBoxTime>
                    {days < 0 ? 0 : days}d : {hours < 0 ? 0 : hours}h : {minutes < 0 ? 0 : minutes}m :  {seconds < 0 ? 0 : seconds}s
                </EstimateBoxTime>
                {
                    redeemData.time < currentTime &&
                    <NotchedButtonFill height='28px' width="120px" mobileMaxW="80px" mbFontSize="12px" fontSize='14px' style={{ margin: 'auto' }} onClick={withdraw}>
                        {
                            isWithdrawing ? 'WITHDRAWING' : 'WITHDRAW'
                        }
                    </NotchedButtonFill>
                }
            </div>

            <EstimateBoxRouteArrow>
                {isLt550 ? `--->` : `---------->`}
            </EstimateBoxRouteArrow>

            <EstimateBoxRouteEnd>{formatXpNumber(redeemData?.amount ?? 0, 5)} MONO</EstimateBoxRouteEnd>
        </EstimateBoxRoute>



    </EstimateBoxRouteWrapper>
}

export default function XMonoPage() {
    const chainId = SupportedChainId.BLAST
    const { account, chainId: CHAIN_ID } = useWeb3React();
    const navigate = useNavigate()
    const { dividendUserInfo, totalStakedXmono, dividendContractWithSign, loadStakeData } = useDividendContext()
    const {
        isLoadingBalance: isLoadingBalanceXMONO,
        contractWithNoSign: xMONOContractWithNoSign,
        contractWithSign: xMONOContractWithSign,
        balance: xMONOBalance,
        loadTokenBalance: loadXMONOBalance
    } = useERC20TokenBalance(XMONO_ADDRESSES_MAP[chainId])

    const {
        isLoadingBalance: isLoadingBalanceMONO,
        contractWithNoSign: MONOContractWithNoSign,
        contractWithSign: MONOContractWithSign,
        balance: MONOBalance,
        loadTokenBalance: loadMONOBalance
    } = useERC20TokenBalance(MONO_ADDRESSES_MAP[chainId])

    const toggleWalletModal = useToggleWalletModal();
    const [currentTab, setCurrentTab] = useState<'CONVERT' | 'REDEEM'>('CONVERT');
    const [isActionLoading, setIsActionLoading] = useState<boolean>(false)

    const [amount, setAmount] = useState<number>(0);
    const debounceAmount = useDebounce(amount, 300)
    const [hasTypedInput, setHasTypedInput] = useState<boolean>(false)
    const [redeemMonths, setRedeemMonths] = useState<number>(0);
    const [redeemDays, setRedeemDays] = useState<number>(16);
    const [estimateRedeemOut, setEstimateRedeemOut] = useState<number>(0);
    const [redeemList, setRedeemList] = useState<RedeemXMONOType[]>([]);

    const contractWithSign = useCustomeContract(XMONO_ADDRESSES_MAP[chainId], XMONO_ABI, true)
    const contractWithNoSign = getContract(XMONO_ADDRESSES_MAP[chainId], XMONO_ABI, new ethers.providers.JsonRpcProvider(RPC_URLS[chainId][0]))
    const [isApprovingMONO, setIsApprovingMONO] = useState<boolean>(false)
    const [monoAllowance, setMonoAllowance] = useState<number>(0)

    const hasApprovedMONOContract = useMemo(() => monoAllowance > 0 && amount <= monoAllowance && MONOBalance <= monoAllowance, [monoAllowance, amount, MONOBalance])

    const isDisableConvertBtn = useMemo(() => MONOBalance == 0 || amount == 0 || amount > MONOBalance || amount > monoAllowance, [amount, MONOBalance, monoAllowance])
    const isDisableRedeemBtn = useMemo(() => xMONOBalance == 0 || amount == 0 || amount > xMONOBalance, [amount, xMONOBalance])
    const totalRedeemingXmono = useMemo(() => {
        let totalRedeeming = 0
        for (let index = 0; index < redeemList.length; index++)
            totalRedeeming += redeemList[index].amount
        return totalRedeeming
    }, [redeemList])

    const onRedeemDayStateChanged = (days: number) => {
        if (days < 31) setRedeemMonths(0)
        else setRedeemMonths(Math.floor(days / 30))
    }
    const onRedeemMonthStateChanged = (month: number) => {
        if (month < 1) setRedeemDays(15)
        else setRedeemDays(month * 31)
    }

    const redeemRatio = useMemo(() => {
        return Number((getPercentageBasedOnRedeemDays(redeemDays) / 100).toFixed(3))
    }, [redeemDays])

    useEffect(() => {
        setEstimateRedeemOut(calculateRedeem(redeemDays, amount))
    }, [debounceAmount, redeemRatio])

    const convert = async () => {
        checkUnsupportChainId(CHAIN_ID)
        if (isActionLoading || amount <= 0) return
        try {
            setIsActionLoading(true)
            const mintAmount = ethers.utils.parseUnits(String(amount), 18)

            const gasEstimate = await contractWithSign?.estimateGas.mint(mintAmount)
            const gasLimitCalc = Math.floor(gasEstimate ? gasEstimate.toNumber() * 1.5 : 200000)

            const tx = await contractWithSign?.mint(mintAmount, {
                gasLimit: gasLimitCalc
            })
            await tx.wait()
            setAmount(0)
            loadMONOAllowance()
            loadMONOBalance()
            loadXMONOBalance()
            loadUserRedeems()
            notification.success({
                message: 'Convert successfull'
            })
        }
        catch (err) {
            notification.error({
                message: 'Convert failed'
            })
            console.log('convert err: ', err);
        }
        finally {
            setIsActionLoading(false)
        }
    }

    const redeem = async () => {
        checkUnsupportChainId(CHAIN_ID)
        if (isActionLoading || amount <= 0) return
        try {
            setIsActionLoading(true)
            const duraton = redeemDays * 86400
            const redeemAmount = ethers.utils.parseUnits(String(amount), 18)

            const gasEstimate = await contractWithSign?.estimateGas.redeem(redeemAmount, duraton)
            const gasLimitCalc = Math.floor(gasEstimate ? gasEstimate.toNumber() * 1.5 : 200000)

            const tx = await contractWithSign?.redeem(redeemAmount, duraton, {
                gasLimit: gasLimitCalc
            })
            await tx.wait()
            setAmount(0)
            setEstimateRedeemOut(0)
            loadMONOAllowance()
            loadMONOBalance()
            loadXMONOBalance()
            loadUserRedeems()
            notification.success({
                message: 'Redeem successfull'
            })
        }
        catch (err) {
            notification.error({
                message: 'Redeem failed'
            })
            console.log('Redeem err: ', err);
        }
        finally {
            setIsActionLoading(false)
        }
    }

    const approveMONOContract = async (contractAddress: string) => {
        if (isApprovingMONO) return
        try {
            setIsApprovingMONO(true)
            const tx = await MONOContractWithSign?.approve(contractAddress, ethers.constants.MaxUint256)
            await tx.wait()
            loadMONOAllowance()
            notification.success({
                message: "Approve successfull"
            })
        }
        catch (err) {
            console.log('approve failed');

            notification.error({
                message: "Approve failed"
            })
        }
        finally {
            setIsApprovingMONO(false)
        }
    }

    const loadMONOAllowance = async () => {
        if (!account) return
        try {
            const allowance = await MONOContractWithSign?.allowance(account, XMONO_ADDRESSES_MAP[chainId])
            const formmatedAllowance = Number(ethers.utils.formatUnits(allowance, 18))
            setMonoAllowance(formmatedAllowance)
        } catch (error) {
            console.log('load mono allowance failed');
            console.log('handleCheckApproveStakeContract error', error)
        }
    }

    const loadUserRedeems = async () => {
        if (account) {
            try {
                const data = await contractWithNoSign?.getRedeems(account, 0, 1000)
                const list = data.map((item: { id: { toNumber: () => any; }; amount: ethers.BigNumberish; timestamp: { toNumber: () => any; }; }) => ({
                    id: item.id.toNumber(),
                    amount: Number(ethers.utils.formatUnits(item.amount, 18)),
                    time: item.timestamp.toNumber()
                }))

                setRedeemList(list.sort((a: { time: number; }, b: { time: number; }) => a.time - b.time))
            }
            catch (err) {
                console.log('loadUserRedeems failed: ', err);
            }
        }
    }

    useEffect(() => {
        loadMONOAllowance()
        loadUserRedeems()
        loadDividendAllowance()
    }, [account])

    useEffect(() => {
    }, [hasApprovedMONOContract])

    useEffect(() => {
        setAmount(0)
        setRedeemMonths(0)
        setRedeemDays(15)
    }, [currentTab])

    const [isStakingXmono, setIsStakingXmono] = useState<boolean>(false)
    const [isApprovingDividend, setIsApprovingDividend] = useState<boolean>(false)
    const [dividendAllowance, setDividendAllowance] = useState<number>(0)
    const hasApprovedDividend = useMemo(() => xMONOBalance <= dividendAllowance && dividendAllowance > 0, [xMONOBalance, dividendAllowance])

    const depositXmono = async () => {
        checkUnsupportChainId(chainId)
        if (toFixedNum(xMONOBalance, 5) == 0) {
            notification.warning({
                message: 'xMONO is not enough'
            })
            return
        }
        if (isStakingXmono) return
        try {
            setIsStakingXmono(true)
            const amountDeposit = ethers.utils.parseUnits(String(xMONOBalance), 18)
            const gasEstimate = await dividendContractWithSign?.estimateGas.deposit(amountDeposit)
            const gasLimitCalc = Math.floor(gasEstimate ? gasEstimate.toNumber() * 1.5 : 200000)
            let tx = await dividendContractWithSign?.deposit(amountDeposit, { gasLimit: gasLimitCalc })
            await tx.wait()
            loadStakeData()
            loadXMONOBalance()
            loadDividendAllowance()
            notification.success({
                message: 'Stake successful'
            })
        } catch (err) {
            console.log(err)
            if (err?.code == 'UNPREDICTABLE_GAS_LIMIT' || (err?.data?.message && String(err?.data?.message).indexOf('insufficient funds')))
                notification.error({
                    message: 'Insufficient funds for gas'
                })
            else
                notification.error({
                    message: 'Stake failed'
                })
        }
        finally {
            setIsStakingXmono(false)
        }
    }

    const approveDividendContract = async () => {
        if (isApprovingDividend) return
        try {
            setIsApprovingDividend(true)
            const tx = await xMONOContractWithSign?.approve(MONO_DIVIDEND_ADDRESSES_MAP[chainId], ethers.constants.MaxUint256)
            await tx.wait()
            loadDividendAllowance()
            notification.success({
                message: "Approve successfull"
            })
        }
        catch (err) {
            console.log('approve failed');
            notification.error({
                message: "Approve failed"
            })
        }
        finally {
            setIsApprovingDividend(false)
        }
    }

    const loadDividendAllowance = async () => {
        if (!account) return
        try {
            const allowance = await xMONOContractWithSign?.allowance(account, MONO_DIVIDEND_ADDRESSES_MAP[chainId])
            const formmatedAllowance = Number(ethers.utils.formatUnits(allowance, 18))
            setDividendAllowance(formmatedAllowance)
        } catch (error) {
            console.log('loadDividendAllowance error', error)
        }
    }

    return <XmonoPageSectionWrapper>
        <InfoBox>
            <TitleBoxWrapper>
                <img src="https://raw.githubusercontent.com/Monoswap-Labs/monoswap-token-list/main/img/blast_sepolia/assets/0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055/logo.png" alt="monoswap" />

                <TitleBox>
                    <Title>
                        xMONO
                    </Title>
                    <SubTitle>
                        Convert your MONO, redeem your xMONO and manage your xMONO extra yield.
                    </SubTitle>
                </TitleBox>
            </TitleBoxWrapper>

            <ChestInfoBoxBorder>
                <img className="bg" src="/dividend/info-bg.png" alt="monoswap" />
                <ChestInfoStatsBox>
                    <ChestInfoStatsItemWrapper>
                        <img src="/xmono/icon1.png" alt="monoswap" />
                        <ChestInfoStatsItem>
                            <LabelText>
                                Your MONO
                            </LabelText>
                            <ContentText>
                                {
                                    isLoadingBalanceMONO ? <CustomLightSpinner src={Circle} alt="loader" size="16px" /> : formatXpNumber(MONOBalance)
                                }
                            </ContentText>
                        </ChestInfoStatsItem>
                    </ChestInfoStatsItemWrapper>

                    <ChestInfoStatsSeparator isShowOnMobile={true} />

                    <ChestInfoStatsItemWrapper>
                        <img src="/xmono/icon2.png" alt="monoswap" />
                        <ChestInfoStatsItem>
                            <LabelText>
                                Available xMONO
                            </LabelText>
                            <ContentText>
                                {
                                    isLoadingBalanceXMONO ? <CustomLightSpinner src={Circle} alt="loader" size="16px" /> : formatXpNumber(xMONOBalance)
                                }
                            </ContentText>
                        </ChestInfoStatsItem>
                    </ChestInfoStatsItemWrapper>

                    <ChestInfoStatsSeparator />

                    <ChestInfoStatsItemWrapper>
                        <img src="/xmono/icon3.png" alt="monoswap" />
                        <ChestInfoStatsItem>
                            <LabelText>
                                Staking xMONO
                            </LabelText>
                            <ContentText>
                                {
                                    totalStakedXmono == 0 ? <CustomLightSpinner src={Circle} alt="loader" size="16px" /> : formatXpNumber(dividendUserInfo?.amount ?? 0)
                                }
                            </ContentText>
                        </ChestInfoStatsItem>
                    </ChestInfoStatsItemWrapper>

                    <ChestInfoStatsSeparator isShowOnMobile={true} />

                    <ChestInfoStatsItemWrapper>
                        <img src="/xmono/icon4.png" alt="monoswap" />
                        <ChestInfoStatsItem>
                            <LabelText>
                                Redeeming xMONO
                            </LabelText>
                            <ContentText>
                                {
                                    totalStakedXmono == 0 ? <CustomLightSpinner src={Circle} alt="loader" size="16px" /> : formatXpNumber(totalRedeemingXmono)
                                }
                            </ContentText>
                        </ChestInfoStatsItem>
                    </ChestInfoStatsItemWrapper>

                </ChestInfoStatsBox>
            </ChestInfoBoxBorder>
        </InfoBox>

        <StakeInfoBoxWrapper>
            <StakeBox>
                <StakeBoxTop>
                    <TabList>
                        <TabItem isActived={currentTab == 'CONVERT'} onClick={() => { setCurrentTab('CONVERT') }}>
                            Convert
                        </TabItem>
                        <TabItem isActived={currentTab == 'REDEEM'} onClick={() => { setCurrentTab('REDEEM') }}>
                            Redeem
                        </TabItem>
                    </TabList>
                </StakeBoxTop>

                <StakeBoxBottom>
                    <StakeInfoTitleBox>
                        <StakeInfoTitle>
                            {currentTab == 'CONVERT' ? 'Get xMONO' : 'Redeem xMONO'}
                        </StakeInfoTitle>
                        <StakeInfoSubTitle>
                            {currentTab == 'CONVERT' ? 'Unseal bonus rewards and exclusive yield by converting your MONO to xMONO' : 'Redeem your xMONO back into MONO over a vesting period of 15 days (1 > 0.5 ratio) to 6 months (1 > 1 ratio).'}
                        </StakeInfoSubTitle>
                    </StakeInfoTitleBox>


                    <InputBoxWrapper>
                        <InputBox>
                            {
                                currentTab == 'CONVERT' ?
                                    <InputSelect>
                                        <img style={{
                                            cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)'
                                        }} src='https://raw.githubusercontent.com/Monoswap-Labs/monoswap-token-list/main/img/blast_sepolia/assets/0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055/logo.png' />

                                        <BalanceLabel style={{ color: '#EFEFE4', fontSize: '16px' }}>MONO</BalanceLabel>
                                    </InputSelect>
                                    : <InputSelect>
                                        <img style={{
                                            cursor: 'pointer', width: '20px', height: '20px', borderRadius: '18px', border: '1px solid rgba(255, 255, 255, 0.20)'
                                        }} src='/xmono/xmono-token.png' />

                                        <BalanceLabel style={{ color: '#EFEFE4', fontSize: '16px' }}>xMONO</BalanceLabel>
                                    </InputSelect>
                            }
                            <Input type="number" value={amount} onChange={e => {
                                const newVal = e.target.value.replace(/^0+(?!\.)/, '');
                                e.target.value = newVal;
                                setAmount(Number(newVal));
                                setHasTypedInput(true);
                            }} min={0.000001} maxLength={5} />
                        </InputBox>

                        <InputBalanceBox>
                            {
                                currentTab == 'CONVERT' ? <ErrorText>
                                    {hasTypedInput && amount > MONOBalance ? 'Insufficient balance' : ''}
                                </ErrorText>
                                    : <ErrorText>
                                        {hasTypedInput && amount > xMONOBalance ? 'Insufficient balance' : ''}
                                    </ErrorText>
                            }

                            {
                                currentTab == 'CONVERT' ?

                                    <InbutBalanceContent>
                                        <MaxButton onClick={() => setAmount(toFixedNum(MONOBalance, 5))}><span>Max</span></MaxButton>
                                        <BalanceLabel>Balance: <span style={{ color: '#EFEFE4' }}>{formatXpNumber(MONOBalance, 5)} MONO</span></BalanceLabel>
                                    </InbutBalanceContent>
                                    :
                                    <InbutBalanceContent>
                                        <MaxButton onClick={() => setAmount(toFixedNum(xMONOBalance, 5))}><span>Max</span></MaxButton>
                                        <BalanceLabel>Balance: <span style={{ color: '#EFEFE4' }}>{formatXpNumber(xMONOBalance, 5)} xMONO</span></BalanceLabel>
                                    </InbutBalanceContent>
                            }
                        </InputBalanceBox>
                        {
                            currentTab == 'REDEEM' && <RedeemCalcBox>
                                <RedeemCalcDuration>
                                    <RedeemCalcDurationLeft>
                                        <h5>Redeem duration</h5>
                                        <h6 onClick={() => { setRedeemDays(183); setRedeemMonths(6) }}>Set Max</h6>
                                    </RedeemCalcDurationLeft>

                                    <RedeemCalcDurationRight>
                                        <RedeemCalcDurationInputWrapper>
                                            <RedeemCalcDurationInputInDecreaseBtn onClick={() => {
                                                if (redeemMonths > 0) { setRedeemMonths(redeemMonths - 1); onRedeemMonthStateChanged(redeemMonths - 1) }
                                            }}>-</RedeemCalcDurationInputInDecreaseBtn>
                                            <RedeemCalcDurationInputBox>
                                                <RedeemCalcDurationInput type="number" readOnly value={redeemMonths} />
                                                <span>Month(s)</span>
                                            </RedeemCalcDurationInputBox>
                                            <RedeemCalcDurationInputInDecreaseBtn onClick={() => {
                                                if (redeemMonths < 6) { setRedeemMonths(redeemMonths + 1); onRedeemMonthStateChanged(redeemMonths + 1) }
                                            }}>+</RedeemCalcDurationInputInDecreaseBtn>
                                        </RedeemCalcDurationInputWrapper>
                                        <RedeemCalcDurationInputWrapper>
                                            <RedeemCalcDurationInputInDecreaseBtn onClick={() => {
                                                if (redeemDays > 15) { setRedeemDays(redeemDays - 1); onRedeemDayStateChanged(redeemDays - 1) }
                                            }}>-</RedeemCalcDurationInputInDecreaseBtn>
                                            <RedeemCalcDurationInputBox>
                                                <RedeemCalcDurationInput type="number" readOnly value={redeemDays} />
                                                <span>Day(s)</span>
                                            </RedeemCalcDurationInputBox>
                                            <RedeemCalcDurationInputInDecreaseBtn onClick={() => { if (redeemDays >= 183) return; setRedeemDays(redeemDays + 1); onRedeemDayStateChanged(redeemDays + 1) }}>+</RedeemCalcDurationInputInDecreaseBtn>
                                        </RedeemCalcDurationInputWrapper>
                                    </RedeemCalcDurationRight>
                                </RedeemCalcDuration>

                                <RedeemCalcBoxSeparator />

                                <RedeemCalcEstimateBox>
                                    <RedeemCalcEstimateItem>
                                        <span>Redeem ratio</span>
                                        <span>~ {redeemRatio}</span>
                                    </RedeemCalcEstimateItem>
                                    <RedeemCalcEstimateItem>
                                        <span>MONO output</span>
                                        <span>{formatXpNumber(estimateRedeemOut, 5)} MONO</span>
                                    </RedeemCalcEstimateItem>
                                </RedeemCalcEstimateBox>
                            </RedeemCalcBox>
                        }
                    </InputBoxWrapper>
                    {
                        !account ?
                            <NotchedButtonFill className="fill-btn" width='100%' >
                                <ButtonLabel onClick={toggleWalletModal}>
                                    CONNECT WALLET
                                </ButtonLabel>
                            </NotchedButtonFill>
                            :
                            (currentTab == 'CONVERT'
                                ?
                                (
                                    hasApprovedMONOContract ?
                                        <NotchedButtonFill className="fill-btn" width='100%' 
                                        // disabled={isDisableConvertBtn} 
                                        disabled={true}
                                        onClick={convert}>
                                            <ButtonLabel onClick={() => { }}>
                                                {isActionLoading ? 'CONVERTING...' : 'CONVERT'}
                                            </ButtonLabel>
                                        </NotchedButtonFill>
                                        : <NotchedButtonFill className="fill-btn" width='100%' onClick={() => approveMONOContract(XMONO_ADDRESSES_MAP[chainId])}>
                                            <ButtonLabel onClick={() => { }}>
                                                {isApprovingMONO ? 'APPROVING...' : 'APPROVE'}
                                            </ButtonLabel>
                                        </NotchedButtonFill>
                                )
                                :
                                <NotchedButtonFill className="fill-btn" width='100%' onClick={redeem} disabled={isDisableRedeemBtn} >
                                    <ButtonLabel  >
                                        {isActionLoading ? 'REDEEMING...' : 'REDEEM'}
                                    </ButtonLabel>
                                </NotchedButtonFill>
                            )
                    }
                </StakeBoxBottom>
            </StakeBox>


            {
                redeemList.length > 0 &&
                <EstimateBox>
                    <EstimateBoxTitleWrapper>
                        <EstimateBoxTitle>
                            Vesting
                        </EstimateBoxTitle>
                        <EstimateBoxStatus>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 13.25C12.6904 13.25 13.25 12.6904 13.25 12C13.25 11.3096 12.6904 10.75 12 10.75C11.3096 10.75 10.75 11.3096 10.75 12C10.75 12.6904 11.3096 13.25 12 13.25ZM19 13.25C19.6904 13.25 20.25 12.6904 20.25 12C20.25 11.3096 19.6904 10.75 19 10.75C18.3096 10.75 17.75 11.3096 17.75 12C17.75 12.6904 18.3096 13.25 19 13.25ZM5 13.25C5.69036 13.25 6.25 12.6904 6.25 12C6.25 11.3096 5.69036 10.75 5 10.75C4.30964 10.75 3.75 11.3096 3.75 12C3.75 12.6904 4.30964 13.25 5 13.25Z" fill="#EFEFE4" stroke="#EFEFE4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <span>Pending</span>
                        </EstimateBoxStatus>
                    </EstimateBoxTitleWrapper>

                    <div style={{ display: 'grid', gap: '12px' }}>
                        {
                            redeemList.map((item, index) => <RedeemItem key={index} redeemData={item}
                                xMONOContract={contractWithSign}
                                loadMONOBalance={loadMONOBalance}
                            />)
                        }
                    </div>
                </EstimateBox>
            }


            <div style={{ margin: '100px auto', background: 'black' }}>
                <img
                    style={{ margin: 'auto', display: 'block', width: '100%', maxWidth: '267px' }}
                    src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/hr-icon.png"
                />
            </div>

            <RedeemList>
                <RedeemItemBox>
                    <RedeemItemBoxTop>
                        <RedeemItemBoxTopTitleBoxWrapper>
                            <img src="/xmono/stake-item-icon.png" alt="monoswap" />
                            <RedeemItemBoxTopTitleBox>
                                <h5>Dividends</h5>
                                <h6>Earn real yield from protocol earnings by staking your xMONO here.</h6>
                            </RedeemItemBoxTopTitleBox>
                        </RedeemItemBoxTopTitleBoxWrapper>

                        <NotchedButtonBolderNew w="135px" h="48px" bg="#0a090f" borderColor="linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)" cursor="pointer">
                            <div className="btn-content" onClick={() => navigate('/dividend')}>
                                STAKE
                            </div>
                        </NotchedButtonBolderNew>

                    </RedeemItemBoxTop>

                    <RedeemItemBoxBotom>

                        <RedeemItemInforBox>
                            <h6>Your allocation</h6>
                            <h5>
                                {
                                    totalStakedXmono == 0 ? <CustomLightSpinner src={Circle} alt="loader" size="16px" /> : formatXpNumber(dividendUserInfo?.amount ?? 0) + ' xMONO'
                                }
                            </h5>
                        </RedeemItemInforBox>


                        <RedeemItemInforBox>
                            <h6>Total allocation</h6>
                            <h5>
                                {
                                    totalStakedXmono == 0 ? <CustomLightSpinner src={Circle} alt="loader" size="16px" /> : formatXpNumber(totalStakedXmono) + ' xMONO'
                                }
                            </h5>
                        </RedeemItemInforBox>

                        <RedeemItemInforBox>
                            <h6>Withdrawal fee</h6>
                            <h5>0.5%</h5>
                        </RedeemItemInforBox>

                    </RedeemItemBoxBotom>
                </RedeemItemBox>

            </RedeemList>
        </StakeInfoBoxWrapper>
    </XmonoPageSectionWrapper>
}