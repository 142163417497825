export function getPercentageBasedOnRedeemDays(redeemDays: any) {
    if (redeemDays >= 15 && redeemDays <= 183) {
        // Using the provided data directly
        const percentages = {
            15: 50.00,
            16: 50.10,
            17: 50.20,
            18: 50.30,
            19: 50.40,
            20: 50.50,
            21: 50.60,
            22: 50.70,
            23: 50.80,
            24: 50.90,
            25: 51.00,
            26: 51.10,
            27: 51.20,
            28: 51.30,
            29: 51.40,
            30: 51.55,
            31: 51.70,
            32: 51.85,
            33: 52.00,
            34: 52.15,
            35: 52.30,
            36: 52.45,
            37: 52.60,
            38: 52.75,
            39: 52.90,
            40: 53.05,
            41: 53.20,
            42: 53.35,
            43: 53.50,
            44: 53.65,
            45: 53.80,
            46: 53.95,
            47: 54.10,
            48: 54.25,
            49: 54.40,
            50: 54.55,
            51: 54.70,
            52: 54.85,
            53: 55.00,
            54: 55.15,
            55: 55.30,
            56: 55.45,
            57: 55.60,
            58: 55.75,
            59: 55.90,
            60: 56.10,
            61: 56.30,
            62: 56.50,
            63: 56.70,
            64: 56.90,
            65: 57.10,
            66: 57.30,
            67: 57.50,
            68: 57.70,
            69: 57.90,
            70: 58.10,
            71: 58.30,
            72: 58.50,
            73: 58.70,
            74: 58.90,
            75: 59.10,
            76: 59.30,
            77: 59.50,
            78: 59.70,
            79: 59.90,
            80: 60.10,
            81: 60.30,
            82: 60.50,
            83: 60.70,
            84: 60.90,
            85: 61.10,
            86: 61.30,
            87: 61.50,
            88: 61.70,
            89: 61.90,
            90: 62.15,
            91: 62.40,
            92: 62.65,
            93: 62.90,
            94: 63.15,
            95: 63.40,
            96: 63.65,
            97: 63.90,
            98: 64.15,
            99: 64.40,
            100: 64.65,
            101: 64.90,
            102: 65.15,
            103: 65.40,
            104: 65.65,
            105: 65.90,
            106: 66.15,
            107: 66.40,
            108: 66.65,
            109: 66.90,
            110: 67.15,
            111: 67.40,
            112: 67.65,
            113: 67.90,
            114: 68.15,
            115: 68.40,
            116: 68.65,
            117: 68.90,
            118: 69.15,
            119: 69.40,
            120: 69.70,
            121: 70.00,
            122: 70.30,
            123: 70.60,
            124: 70.90,
            125: 71.20,
            126: 71.50,
            127: 71.80,
            128: 72.10,
            129: 72.40,
            130: 72.70,
            131: 73.00,
            132: 73.30,
            133: 73.60,
            134: 73.90,
            135: 74.20,
            136: 74.50,
            137: 74.80,
            138: 75.10,
            139: 75.40,
            140: 75.70,
            141: 76.00,
            142: 76.30,
            143: 76.60,
            144: 76.90,
            145: 77.20,
            146: 77.50,
            147: 77.80,
            148: 78.10,
            149: 78.40,
            150: 78.90,
            151: 79.40,
            152: 79.90,
            153: 80.40,
            154: 80.90,
            155: 81.40,
            156: 81.90,
            157: 82.40,
            158: 82.90,
            159: 83.40,
            160: 83.90,
            161: 84.40,
            162: 84.90,
            163: 85.40,
            164: 85.90,
            165: 86.40,
            166: 86.90,
            167: 87.40,
            168: 87.90,
            169: 88.40,
            170: 88.90,
            171: 89.40,
            172: 89.90,
            173: 90.60,
            174: 91.30,
            175: 92.00,
            176: 92.70,
            177: 93.40,
            178: 94.10,
            179: 94.80,
            180: 96.10,
            181: 97.40,
            182: 98.70,
            183: 100,
        };

        // Return the percentage for the given redeemDays
        // @ts-ignore
        return percentages[redeemDays];
    } else {
        // Default case if redeemDays is outside the provided range
        return 0;
    }
}


const minRedeemTime = 15
const timeThresholds: number[] = []
const minRedeemPercent = 5000;
const redeemPercentIncrease: number[] = []
const PERCENT_DENOMINATOR: number = 10000

timeThresholds.push(16);
timeThresholds.push(30);
timeThresholds.push(60);
timeThresholds.push(90);
timeThresholds.push(120);
timeThresholds.push(150);
timeThresholds.push(173);
timeThresholds.push(180);
timeThresholds.push(184);

redeemPercentIncrease[16] = 10; // 0.1%
redeemPercentIncrease[30] = 15; // 0.15%
redeemPercentIncrease[60] = 20; // 0.2%
redeemPercentIncrease[90] = 25; // 0.25%
redeemPercentIncrease[120] = 30; // 0.3%
redeemPercentIncrease[150] = 50; // 0.5%
redeemPercentIncrease[173] = 70; // 0.7%
redeemPercentIncrease[180] = 130; // 1.3%
redeemPercentIncrease[184] = 0; // 0%

export function calculateRedeem(
    duration: number,
    amount: number
) {
    if (duration < minRedeemTime) {
        return 0;
    }
    if (duration >= timeThresholds[timeThresholds.length - 1]) {
        return amount;
    }
    let percent = minRedeemPercent;
    if (duration == minRedeemTime) {
        return (amount * percent) / PERCENT_DENOMINATOR;
    }
    for (let i = 1; i < timeThresholds.length; i++) {
        if (timeThresholds[i] < duration) {
            percent +=
                redeemPercentIncrease[timeThresholds[i - 1]] *
                ((timeThresholds[i] - timeThresholds[i - 1]) / 1);
        } else {
            percent +=
                redeemPercentIncrease[timeThresholds[i - 1]] *
                ((duration - timeThresholds[i - 1]) / 1 + 1);
            break;
        }
    }
    return (amount * percent) / PERCENT_DENOMINATOR;
}

